/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios from 'axios';
import Provider from './store/Provider';
import App from './App';
import { isLiveEnvironment, isSentryEnabled } from './helpers/globalScriptHelper';
import Spinner from './components/Spinner/Spinner';
import CriticalError from './components/CriticalError/CriticalError';

const history = createBrowserHistory();

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
isSentryEnabled() &&
	Sentry.init({
		dsn: 'https://609ec575f4dd4e0ab101f1b74a404e17@o1071378.ingest.sentry.io/6330497',
		integrations: [new Integrations.BrowserTracing()],
		environment: isLiveEnvironment() ? 'production' : 'pp',

		// this is workaround to fix Timeout errors which happens because of recaptcha
		// https://github.com/getsentry/sentry-javascript/issues/2514
		beforeSend(event, hint) {
			const timeoutPattern = /^Timeout( \([A-Za-z]\))?$/;
			if (
				typeof hint?.originalException === 'string' &&
				timeoutPattern.test(hint?.originalException)
			) {
				return null;
			}

			return event;
		},
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});

const Content = () => {
	return (
		<Router history={history}>
			<HelmetProvider>
				<Provider>
					<App />
				</Provider>
			</HelmetProvider>
		</Router>
	);
};

const Root = () => {
	const [view, setView] = useState<'content' | 'error' | 'loading'>('loading');

	useEffect(() => {
		axios
			.get(`${process.env.PUBLIC_URL}/gen-cfg.js`, {
				headers: {
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
				},
			})
			.then(({ data }) => {
				const script = document.createElement('script');
				script.innerHTML = data;
				document.head.appendChild(script);
				setView('content');
			})
			.catch((e) => {
				console.error(e);
				setView('error');
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [1]);

	switch (view) {
		case 'loading':
			return <Spinner isFullScreen />;
		case 'error':
			return <CriticalError />;
		case 'content':
			return <Content />;
		default:
			return null;
	}
};

ReactDOM.render(
	<React.StrictMode>
		<Root />
	</React.StrictMode>,
	document.getElementById('root')
);
