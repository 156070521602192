import { useContext, useState, useEffect } from 'react';
import { SET_CAPTCHA_TYPE } from '../store/actionTypes';
import { Context } from '../store/Provider';
import config from '../config';
import * as endpoints from '../endpoints';
import useAxios from './useAxios';

const LAST_REDIRECT_TIME = 'dynamic-login-page-last-redirect-time';
const { MIN_TIME_BETWEEN_REDIRECTS_MS } = config;

const getCanRedirectAgain = () => {
	const lastRedirectTime = parseInt(window.localStorage.getItem(LAST_REDIRECT_TIME) || '0', 10);
	if (lastRedirectTime === 0 || Date.now() - lastRedirectTime > MIN_TIME_BETWEEN_REDIRECTS_MS) {
		return true;
	}
	return false;
};

const useSessionCheck = ({ disabled = false, flowParam = '' }) => {
	const { dispatch } = useContext(Context);
	const [isSessionCheckPending, setIsSessionCheckPending] = useState(true);
	const [sessionCheckError, setSessionCheckError] = useState<string | null>(null);
	const isRedirectCountCheckEnabled =
		typeof MIN_TIME_BETWEEN_REDIRECTS_MS === 'number' && MIN_TIME_BETWEEN_REDIRECTS_MS > 0;
	const axios = useAxios();
	const setLastRedirectTime = (time: number) => {
		window.localStorage.setItem(LAST_REDIRECT_TIME, time.toString());
	};

	useEffect(() => {
		if (disabled) return;
		axios
			.get(endpoints.sessionCheck())
			.then(({ data }) => {
				dispatch({
					type: SET_CAPTCHA_TYPE,
					payload: data.captchaType,
				});
				if (data.sessionExists === true && data.loggedIn === true) {
					throw new Error('User already logged in');
				}
				if (data.sessionExists === true) {
					setIsSessionCheckPending(false);
					return;
				}

				throw new Error('Session not valid or endpoint failed');
			})
			.catch((e) => {
				if (e === undefined) return; // Request cancelled
				if (isRedirectCountCheckEnabled && !getCanRedirectAgain()) {
					setSessionCheckError(
						'Session invalid. Please try clearing your cookies. Contact support if the problem persists.'
					);
					setIsSessionCheckPending(false);
					return;
				}

				// Redirect to a page where user can get session
				setLastRedirectTime(Date.now());
				window.location.assign(
					`${config.NO_SESSION_REDIRECT_URI}${flowParam ? `?flow=${flowParam}` : ''}`
				);
			});
	}, [axios, isRedirectCountCheckEnabled, disabled]);

	return {
		isSessionCheckPending: disabled ? false : isSessionCheckPending,
		sessionCheckError,
	};
	// return {
	// 	isSessionCheckPending: disabled ? false : false,
	// 	sessionCheckError
	// };
};

export default useSessionCheck;
