import { formatInTimeZone } from 'date-fns-tz';

const getCurrentTimeZone = () => {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const dateFixer = (date: string) => (/z/i.test(date) ? date : `${date}Z`);

const convertToLocalTime = (date: string, formatDate: string) => {
	return formatInTimeZone(dateFixer(date), getCurrentTimeZone(), formatDate);
};

export default convertToLocalTime;
