import React, { ReactNode } from 'react';
import classNames from 'classnames';
import FieldError from '../FieldError/FieldError';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
	checked: boolean;
	children: ReactNode;
	className?: string;
	labelClassName?: string;
	error: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	name?: string;
}

const Checkbox = ({
	checked,
	children,
	className,
	labelClassName,
	onChange,
	error,
	...props
}: CheckboxProps) => (
	<label className={classNames(styles.container, className, { [styles.error]: !!error })}>
		<input
			checked={checked}
			className={styles.input}
			onChange={onChange}
			type="checkbox"
			{...props}
		/>
		<span className={styles.checkmark} />
		<span className={classNames(styles.label, labelClassName)}>{children}</span>
		<FieldError className={styles.error} error={error} />
	</label>
);

export default Checkbox;
