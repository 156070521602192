import Cookies from 'js-cookie';
// eslint-disable-next-line import/no-cycle
import { OverlayType } from '../components/Overlay/Overlay';
import {
	HIDE_OVERLAY,
	SET_IS_MOBILE_CLIENT,
	SHOW_OVERLAY,
	SET_LANG,
	SET_CLIENT_CONFIG,
	SET_IS_RECAPTCHA_ENABLED,
	SET_CAPTCHA_TYPE,
} from './actionTypes';
import StorageKey from '../enums/StorageKey';
import CaptchaType from '../enums/CaptchaType';
import { Client } from '../interfaces/Theme';
import { RTLLanguages } from '../components/LanguageSwitcher/LanguageSwitcher';
import { isLiveEnvironment } from '../helpers/globalScriptHelper';
import { getMappedLanguageForSpecificCode } from '../helpers/languageMapHelper';

type ProceedToAppParameters = {
	email: string;
	redirectUrl: string;
};

export interface State {
	isMobileClient: boolean;
	isRecaptchaEnabled: boolean;
	captchaType: CaptchaType | null;
	lang: string;
	overlay: {
		parameters: ProceedToAppParameters | null;
		type: OverlayType | null;
	};
	client_config: Client;
}

export interface Action {
	type: string;
	payload?: any;
}

const resolveLanguageCookieDomain = (state: State) => {
	if (process.env.NODE_ENV !== 'production') return window.location.hostname;
	if (isLiveEnvironment()) return `.${state.client_config.domainName}`;

	const result = /^((((?<env>\w+)-)?\w+)\.)?(?<host>\w+\.\w+)$/.exec(window.location.hostname);
	if (!result?.groups) {
		return window.location.hostname;
	}

	const { host } = result.groups;
	if (!host) {
		return window.location.hostname;
	}

	return `.${host}`;
};

const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case SET_LANG:
			Cookies.set(StorageKey.USER_LANG, action.payload as string, {
				domain: resolveLanguageCookieDomain(state),
			});

			if (RTLLanguages.includes(action.payload.toLowerCase())) {
				document.body.setAttribute('dir', 'rtl');
			} else {
				document.body.setAttribute('dir', 'ltr');
			}

			return { ...state, lang: getMappedLanguageForSpecificCode(action.payload) };
		case SET_IS_MOBILE_CLIENT:
			return { ...state, isMobileClient: action.payload };
		case SHOW_OVERLAY:
			return {
				...state,
				overlay: action.payload,
			};
		case HIDE_OVERLAY:
			return {
				...state,
				overlay: {
					parameters: null,
					type: null,
				},
			};
		case SET_CLIENT_CONFIG:
			return {
				...state,
				client_config: action.payload,
			};
		case SET_IS_RECAPTCHA_ENABLED:
			return { ...state, isRecaptchaEnabled: action.payload };
		case SET_CAPTCHA_TYPE:
			return { ...state, captchaType: action.payload };
		default:
			return state;
	}
};

export default reducer;
