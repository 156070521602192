/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { isMobile } from 'react-device-detect';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button, { ButtonStyle } from '../../../components/Button/Button';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import config from '../../../config';
import { makeDummyRequest } from '../../../helpers/functionHelpers';
import { useClientConfig } from '../../../helpers/themeHelpers';
import useAxios from '../../../hooks/useAxios';
import styles from './Success.module.scss';

const messages = defineMessages({
	heading: {
		id: 'recoverPasswordSuccess.heading',
		defaultMessage: 'Password changed successfully',
	},
	body: {
		id: 'recoverPasswordSuccess.body',
		defaultMessage: `Your password recovery has been successfully completed`,
	},
	signUp: {
		id: 'base.login',
		defaultMessage: 'Log in',
	},
});

const ConfirmationNotReceived = () => {
	const { formatMessage } = useIntl();
	const history = useHistory();
	const axios = useAxios();
	const { passwordSuccessImage } = useClientConfig();

	return (
		<UserAccessContainer formImage={passwordSuccessImage} title={messages.heading}>
			<div className={styles.paragraphContainer}>
				<p className={styles.paragraph}>
					<FormattedMessage {...messages.body} />
				</p>
			</div>
			<Button
				className={styles.button}
				buttonStyle={ButtonStyle.PRIMARY}
				text={formatMessage(messages.signUp)}
				onClick={async () => {
					if (isMobile) await makeDummyRequest(axios);
					history.push(config.BASE_URL_LOGIN);
				}}
			/>
		</UserAccessContainer>
	);
};

export default ConfirmationNotReceived;
