import React, { BaseSyntheticEvent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import styles from './Toggle.module.scss';

interface ToggleProps {
	checked: boolean;
	children?: ReactNode;
	className?: string;
	disabled?: boolean;
	id?: string;
	labelClassName?: string;
	name?: string;
	onBlur?: (e: BaseSyntheticEvent) => {};
	onChange?: (event: BaseSyntheticEvent) => void;
	isTextualSlider?: boolean;
}

export const Toggle = ({
	checked,
	children,
	className,
	disabled,
	labelClassName,
	name,
	onBlur,
	onChange,
	isTextualSlider,
	...props
}: ToggleProps) => (
	<label className={classNames(styles.container, className)}>
		<span className={labelClassName}>{children}</span>
		<div className={classNames(styles.toggle, { [styles.disabled]: disabled })}>
			<input
				data-cy="toggle"
				{...props}
				type="checkbox"
				className={styles.input}
				name={name}
				checked={checked}
				disabled={disabled}
				onChange={(e) => {
					if (onChange) {
						onChange(e);
					}
				}}
				onBlur={(e) => {
					if (onBlur) {
						onBlur(e);
					}
				}}
			/>

			{!isTextualSlider ? (
				<span
					className={classNames(styles.slider, {
						[styles.disabled]: disabled,
						[styles.textual]: isTextualSlider,
					})}
				/>
			) : (
				<span className={styles.textSlider}>
					{checked ? (
						<FormattedMessage id="toggle.on" defaultMessage="On" />
					) : (
						<FormattedMessage id="toggle.off" defaultMessage="Off" />
					)}
				</span>
			)}
		</div>
	</label>
);

export default Toggle;
