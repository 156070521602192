import { useEffect, useRef } from 'react';

type SwipeHandlers = {
	onSwipeLeft?: () => void;
	onSwipeRight?: () => void;
	onSwipeUp?: () => void;
	onSwipeDown?: () => void;
};

const useSwipe = (
	element: HTMLElement | null,
	{ onSwipeLeft, onSwipeRight, onSwipeUp, onSwipeDown }: SwipeHandlers
) => {
	const startX = useRef<number | null>(null);
	const startY = useRef<number | null>(null);

	const handleTouchStart = (event: TouchEvent) => {
		startX.current = event.touches[0].clientX;
		startY.current = event.touches[0].clientY;
	};

	const handleTouchEnd = (event: TouchEvent) => {
		if (!startX.current || !startY.current) {
			return;
		}

		const endX = event.changedTouches[0].clientX;
		const endY = event.changedTouches[0].clientY;

		const deltaX = endX - startX.current;
		const deltaY = endY - startY.current;

		// Determine the direction of the swipe
		if (Math.abs(deltaX) > Math.abs(deltaY)) {
			if (deltaX > 0 && onSwipeRight) {
				onSwipeRight();
			} else if (deltaX < 0 && onSwipeLeft) {
				onSwipeLeft();
			}
		} else if (deltaY > 0 && onSwipeDown) {
			onSwipeDown();
		} else if (deltaY < 0 && onSwipeUp) {
			onSwipeUp();
		}

		startX.current = null;
		startY.current = null;
	};

	useEffect(() => {
		if (!element) {
			return;
		}

		element.addEventListener('touchstart', handleTouchStart);
		element.addEventListener('touchend', handleTouchEnd);

		// eslint-disable-next-line consistent-return
		return () => {
			element.removeEventListener('touchstart', handleTouchStart);
			element.removeEventListener('touchend', handleTouchEnd);
		};
	}, [element, onSwipeLeft, onSwipeRight, onSwipeUp, onSwipeDown]);
};

export default useSwipe;
