/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-template-curly-in-string */
import React, { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import useAxios from '../../../../hooks/useAxios';
import useRecaptcha from '../../../../hooks/useRecaptcha';
import useAuthErrorMessage from '../../../../hooks/useAuthErrorMessage';
import { getMappedLanguage } from '../../../../helpers/languageMapHelper';
import RetryType from '../../../../enums/RetryType';
import Response from '../../../../interfaces/Response';
import Error from '../../../../interfaces/Error';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import UserAccessContainer from '../../../../components/UserAccessContainer/UserAccessContainer';
import FormMessage, { FormMessageType } from '../../../../components/FormMessage/FormMessage';
import CountdownInput from '../../../../components/CountdownInput/CountdownInput';
import useCountdown from '../../../../hooks/useCountdown';
import TwoFAAttemptsUsed from '../../../TwoFAAttemptsUsed/TwoFAAttemptsUsed';
import endpoints from '../../../../endpoints';
import config from '../../../../config';
import styles from './Confirm.module.scss';
import StorageKey from '../../../../enums/StorageKey';
import { ErrorCode } from '../../../../enums/ErrorCode';
import CaptchaType from '../../../../enums/CaptchaType';
import { Context } from '../../../../store/Provider';
import { useClientConfig } from '../../../../helpers/themeHelpers';

const DEFAULT_ALLOWED_RETRY_COUNT = 3;
const DEFAULT_ALLOWED_RETRY_COUNT_WITH_EXPIRED_TIME = 2;

const messages = defineMessages({
	title: {
		id: 'verifyPhoneConfirm.title',
		defaultMessage: 'Enter the confirmation code',
	},
	body: {
		id: 'verifyPhoneConfirm.body',
		defaultMessage:
			'You should receive your authentication code via text message. Enter the code to proceed further.',
	},
	agreement: {
		id: 'verifyPhoneConfirm.agreement',
		defaultMessage:
			'By entering the confirmation code, you agree to use this phone number to receive your authentication codes to log into {client} platform and agree that all your actions made on the platform after current and future authentications shall be deemed duly signed by you and legally binding.',
	},
	haveNotReceivedSMS: {
		id: 'verifyPhoneConfirm.haveNotReceivedSMS',
		defaultMessage: 'Have not received an SMS?',
	},
	confirm: {
		id: 'base.confirm',
		defaultMessage: 'Confirm',
	},
	resend: {
		id: 'base.resend',
		defaultMessage: 'Resend',
	},
	incorrectCode: {
		id: 'base.incorrectCode',
		defaultMessage: 'Incorrect code',
	},
	askForCall: {
		id: 'verifyPhoneConfirm.askForCall',
		defaultMessage: 'Ask for a call',
	},
	skip: {
		id: 'base.skip',
		defaultMessage: 'Skip',
	},
});

const getFutureDate = (ms: number) => new Date(Date.now() + Number(ms)).toISOString();

const getNewExpirationTime = (key: string) => {
	const newExpirationTime = getFutureDate(config.OTP_EXPIRATION_MS);
	localStorage.setItem(key, newExpirationTime);
	return newExpirationTime;
};

const getExpirationTime = (key: string) => {
	const expirationTime = localStorage.getItem(key);
	if (expirationTime) return expirationTime;

	return getNewExpirationTime(key);
};

const Confirm = () => {
	const axios = useAxios();
	const { phone, token, txId } = queryString.parse(window.location.search);
	const { formatMessage, locale } = useIntl();
	const history = useHistory();
	const { isRecaptchaEnabled, captchaType } = useContext(Context).state;
	const { title } = useClientConfig();
	const [expirationDate, setExpirationDate] = useState<string>(
		getExpirationTime(StorageKey.OTP_EXPIRATION_TIME)
	);
	const { isExpired, formattedTimeLeft } = useCountdown(expirationDate);
	const [formError, setFormError] = useState<Response<Error> | string | null>();
	const {
		recaptchaResponse,
		executeAsync: executeRecaptchaAsync,
		isLoading: isRecaptchaLoading,
	} = useRecaptcha({
		size: 'normal',
		isRendered: isRecaptchaEnabled,
	});
	const errorMessage = useAuthErrorMessage(formError);
	const [OTPCode, setOTPCode] = useState('');
	const [txIdValue, setTxIdValue] = useState(txId);
	const [_, setIsRequestPending] = useState(false);
	const [isResubmitRequired, setIsResubmitRequired] = useState(false);
	const [retryCount] = useState(DEFAULT_ALLOWED_RETRY_COUNT);
	const [retryCountWithExpiredTime] = useState(DEFAULT_ALLOWED_RETRY_COUNT_WITH_EXPIRED_TIME);
	const retryCountUsed = useRef(0);

	const checkRetryCount = () => {
		if (retryCountUsed.current >= retryCount) {
			setIsResubmitRequired(true);
		} else if (retryCountUsed.current === retryCountWithExpiredTime && isExpired) {
			setIsResubmitRequired(true);
		}
	};

	const handleSubmit = async () => {
		if (isRecaptchaEnabled && !recaptchaResponse) return;

		setIsRequestPending(true);
		void axios
			.post(
				endpoints.signupOTPSCheck(OTPCode),
				{ txId: txIdValue },
				{
					headers: {
						recaptcha: recaptchaResponse || null,
					},
				}
			)
			.then(() => {
				localStorage.removeItem(StorageKey.OTP_EXPIRATION_TIME);
				history.push(
					`${config.BASE_URL_SIGNUP}/confirm?token=${encodeURIComponent(token as string)}`
				);
			})
			.catch((response: Response<Error>) => {
				if (response.data.key === 'entity_not_found') {
					setFormError(formatMessage(messages.incorrectCode));
					retryCountUsed.current += 1;
					checkRetryCount();
				} else if (response.data.error === 'unauthorized') {
					setFormError(formatMessage(messages.incorrectCode));
					retryCountUsed.current += 1;
					checkRetryCount();
				} else {
					setFormError(response);
				}
			})
			.then(() => {
				setIsRequestPending(false);
			});
	};

	const handleRetryButtonClick = async (type: RetryType) => {
		if (isRecaptchaEnabled && !recaptchaResponse) return;
		setFormError(null);
		setOTPCode('');
		retryCountUsed.current += 1;

		setIsRequestPending(true);
		void axios
			.post(
				endpoints.signupOTPSInit((token as string) || ''),
				{
					phoneNumber: phone || '',
					notificationType: type,
					language: getMappedLanguage(locale),
				},
				{
					headers: {
						recaptcha: recaptchaResponse || null,
					},
				}
			)
			.then(({ data }) => {
				const { txId } = data;
				setTxIdValue(txId as string);
				setExpirationDate(getNewExpirationTime(StorageKey.OTP_EXPIRATION_TIME));
			})
			.catch((response: Response<Error>) => {
				if (
					response.data.errorCode === ErrorCode.GEN_8 ||
					response.data.errorCode === ErrorCode.MAX_OTP_REACHED
				) {
					history.push(`${config.BASE_URL_SIGNUP}/confirmation-link-expired`);
				}
				setFormError(response);
				checkRetryCount();
			})
			.then(() => {
				setIsRequestPending(false);
			});
	};

	const handleSkip = () => {
		history.push(
			`${config.BASE_URL_SIGNUP}/confirm?token=${encodeURIComponent(token as string)}`
		);
	};

	useEffect(() => {
		// we need to check if token is same as in localStorage to prevent
		// user to be locked with expired time if he comes with a new token
		const tokenFromLocalStorage = localStorage.getItem(StorageKey.SIGNUP_TOKEN);
		const txIdFromLocalStorage = localStorage.getItem(StorageKey.SIGNUP_TX_ID);

		// token might be the same but txId might be different if user submitted verify-phone form again
		if (
			(tokenFromLocalStorage && tokenFromLocalStorage !== token) ||
			(txIdFromLocalStorage && txIdFromLocalStorage !== txId)
		) {
			setExpirationDate(getNewExpirationTime(StorageKey.OTP_EXPIRATION_TIME));
		}

		if (txId) {
			localStorage.setItem(StorageKey.SIGNUP_TX_ID, txId as string);
		}

		if (token) {
			localStorage.setItem(StorageKey.SIGNUP_TOKEN, token as string);
		}
	}, [token]);

	useEffect(() => {
		checkRetryCount();
		/* eslint-disable-next-line */
	}, [isExpired]);

	useEffect(() => {
		if (captchaType === CaptchaType.CLOUDFLARE && !isRecaptchaLoading) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			executeRecaptchaAsync();
		}
		/* eslint-disable-next-line */
	}, [isRecaptchaLoading]);

	if (isResubmitRequired) {
		return <TwoFAAttemptsUsed backLink={config.BASE_URL_LOGIN} />;
	}

	return (
		<UserAccessContainer title={messages.title}>
			<div className={styles.content}>
				{errorMessage && (
					<FormMessage className={styles.message} type={FormMessageType.ERROR}>
						{errorMessage}
					</FormMessage>
				)}
				<div className={styles.bodyContainer}>
					<p className={styles.paragraph}>
						<FormattedMessage {...messages.body} />
					</p>
				</div>
				<form
					onSubmit={(event: FormEvent<HTMLFormElement>) => {
						event.preventDefault();
						void handleSubmit();
					}}
				>
					<CountdownInput
						className={styles.input}
						isCountdownHidden={false}
						isExpired={isExpired}
						pattern="\d*"
						formattedTimeLeft={formattedTimeLeft}
						type="text"
						value={OTPCode}
						retryText={formatMessage(messages.resend)}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							setOTPCode(event.target.value?.trim())
						}
						handleRetryButtonClick={() => {
							void handleRetryButtonClick(RetryType.SMS);
						}}
						isLoading={!recaptchaResponse}
					/>

					{/* {isExpired && ( */}
					{/*	<div className={styles.askForCallButtonContainer}> */}
					{/*		<span> */}
					{/*			<FormattedMessage {...messages.haveNotReceivedSMS} /> */}
					{/*		</span> */}
					{/*		<Button */}
					{/*			className={styles.askForCallButton} */}
					{/*			type={ButtonType.BUTTON} */}
					{/*			buttonStyle={ButtonStyle.LINK} */}
					{/*			text={formatMessage(messages.askForCall)} */}
					{/*			onClick={() => handleRetryButtonClick(RetryType.CALL)} */}
					{/*		/> */}
					{/*	</div> */}
					{/* )} */}

					<p className={styles.paragraph}>
						<FormattedMessage
							{...messages.agreement}
							values={{
								// eslint-disable-next-line react/jsx-no-useless-fragment
								client: <>{title}</>,
							}}
						/>
					</p>
					{isRecaptchaEnabled && <div className={styles.recaptcha} id="recaptcha" />}
					<div className={styles.buttonContainer}>
						<Button
							withCaptcha
							disabled={!recaptchaResponse || isExpired}
							type={ButtonType.SUBMIT}
							className={styles.button}
							buttonStyle={ButtonStyle.PRIMARY}
							text={formatMessage(messages.confirm)}
						/>
						{retryCount >= config.OTP_RETRY_COUNT_BEFORE_SKIP_ALLOWED && (
							<Button
								className={styles.button}
								buttonStyle={ButtonStyle.SECONDARY}
								text={formatMessage(messages.skip)}
								type={ButtonType.BUTTON}
								onClick={handleSkip}
							/>
						)}
					</div>
				</form>
			</div>
		</UserAccessContainer>
	);
};

export default Confirm;
