/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-template-curly-in-string */
import classNames from 'classnames';
import React, { ChangeEvent, FC, FormEvent, ReactNode } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import FormMessage, { FormMessageType } from '../../../../components/FormMessage/FormMessage';
import Input from '../../../../components/Input/Input';
import Select, { SelectChangeEvent } from '../../../../components/Select/Select';
import UserAccessContainer from '../../../../components/UserAccessContainer/UserAccessContainer';
import config from '../../../../config';
import { useClientConfig } from '../../../../helpers/themeHelpers';
import Error from '../../../../interfaces/Error';
import Response from '../../../../interfaces/Response';
import SelectOption from '../../../../interfaces/SelectOption';
import { LoginSubmitValues } from '../../../Login/Login';
import SocialLogin from '../../../SocialLogin/SocialLogin';
import styles from './DefaultSignUpForm.module.scss';
import usePersistedSearch from '../../../../hooks/usePersistedSearch';

const messages = defineMessages({
	signUp: {
		id: 'base.signup',
		defaultMessage: 'Sign up',
	},
	or: {
		id: 'base.or',
		defaultMessage: 'or',
	},
	emailInputLabel: {
		id: 'base.emailInputLabel',
		defaultMessage: 'Email address',
	},
	alreadyHaveAccount: {
		id: 'signup.alreadyHaveAccount',
		defaultMessage: 'Already have an account? <link>Login here</link>',
	},
	country: {
		id: 'base.country',
		defaultMessage: 'Country',
	},
	checkboxLabel: {
		id: 'signup.checkboxLabel',
		defaultMessage:
			'I agree to the and accept <termsAndConditionsLink>Terms & Conditions</termsAndConditionsLink> <privacyPolicyLink>Privacy Policy</privacyPolicyLink> . <servicesProvider>{br}(services provided by {provider})</servicesProvider>',
	},
	openBusinessAccount: {
		id: 'signup.openBusinessAccount',
		defaultMessage: 'Open Business Account',
	},
	requiredField: {
		id: 'base.requiredField',
		defaultMessage: 'This field is required',
	},
	emailInputPlaceholder: {
		id: 'login.emailInputPlaceholder',
		defaultMessage: 'Enter your email address',
	},
	socialButton: {
		id: 'socialLogin.continueWith',
		defaultMessage: 'Signup with {provider}',
	},
});

enum FieldNames {
	username = 'username',
	country = 'country',
	acceptPrivacyPolicyAndTerms = 'acceptPrivacyPolicyAndTerms',
}

interface FormState {
	[FieldNames.username]: string;
	[FieldNames.country]: string;
	[FieldNames.acceptPrivacyPolicyAndTerms]: boolean;
}

interface Errors {
	[FieldNames.username]: string;
	[FieldNames.country]: string;
	[FieldNames.acceptPrivacyPolicyAndTerms]: string;
}

type SignupProps = {
	handleSubmit: (values: FormState) => void;
	formState: FormState;
	errorMessage?: ReactNode;
	errors: Errors;
	handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
	countryOptions: SelectOption[];
	handleSelectChange: (event: SelectChangeEvent) => void;
	privacyPolicyURL: string;
	linkProps: { target: string; rel: string } | {};
	termsAndConditionsURL: string;
	provider: string | undefined;
	isRecaptchaLoading: boolean;
	isRequestPending: boolean;
	executeRecaptchaAsync: (onClose?: () => void) => any;
	handleTwoFA: (isVisible: boolean, values: LoginSubmitValues) => void;
	setFormError: (error: Response<Error> | string | null) => void;
};

const SignUp: FC<SignupProps> = ({
	handleSubmit,
	formState,
	errorMessage,
	errors,
	handleInputChange,
	countryOptions,
	handleSelectChange,
	privacyPolicyURL,
	linkProps,
	termsAndConditionsURL,
	provider,
	isRecaptchaLoading,
	isRequestPending,
	executeRecaptchaAsync,
	handleTwoFA,
	setFormError,
}) => {
	const { formatMessage } = useIntl();
	const { businessSignupEnabled, socialLoginEnabled, hasCountrySelectOnSignup } =
		useClientConfig();
	const persistedSearch = usePersistedSearch();

	return (
		<UserAccessContainer title={messages.signUp}>
			<form
				noValidate
				onSubmit={(event: FormEvent<HTMLFormElement>) => {
					event.preventDefault();
					handleSubmit({
						username: formState.username,
						country: formState.country,
						acceptPrivacyPolicyAndTerms: formState.acceptPrivacyPolicyAndTerms,
					});
				}}
			>
				{errorMessage && (
					<FormMessage className={styles.message} type={FormMessageType.ERROR}>
						{errorMessage}
					</FormMessage>
				)}
				<Input
					className={styles.input}
					error={errors.username}
					id="username"
					label={formatMessage(messages.emailInputLabel)}
					name={FieldNames.username}
					onChange={handleInputChange}
					placeholder={formatMessage(messages.emailInputPlaceholder)}
					type="text"
					value={formState.username}
					inputMode="email"
				/>
				{hasCountrySelectOnSignup && (
					<Select
						className={styles.select}
						disabled={countryOptions.length === 0}
						error={errors.country}
						id="country"
						isSearchable
						label={formatMessage(messages.country)}
						name="country"
						onChangeEvent={handleSelectChange}
						options={countryOptions}
						value={formState.country}
					/>
				)}
				<Checkbox
					checked={formState.acceptPrivacyPolicyAndTerms}
					error={errors.acceptPrivacyPolicyAndTerms}
					className={styles.checkbox}
					labelClassName={styles.checkboxLabel}
					onChange={handleInputChange}
					name={FieldNames.acceptPrivacyPolicyAndTerms}
				>
					<FormattedMessage
						{...messages.checkboxLabel}
						values={{
							br: <br />,
							span: (text: string) => <span className={styles.span}>{text}</span>,
							privacyPolicyLink: (text: string) => (
								<a
									className={styles.policyLink}
									href={privacyPolicyURL}
									{...linkProps}
								>
									{text}
								</a>
							),
							termsAndConditionsLink: (text: string) =>
								termsAndConditionsURL ? (
									<a
										className={styles.policyLink}
										href={termsAndConditionsURL}
										{...linkProps}
									>
										{`${text},`}
									</a>
								) : null,
							servicesProvider: (text: string) =>
								provider ? <span>{text}</span> : null,
							provider,
						}}
					/>
				</Checkbox>
				<Button
					withCaptcha
					className={styles.button}
					disabled={isRecaptchaLoading || !formState.acceptPrivacyPolicyAndTerms}
					isLoading={isRequestPending}
					type={ButtonType.SUBMIT}
					buttonStyle={ButtonStyle.PRIMARY}
					text={formatMessage(messages.signUp)}
				/>
				{businessSignupEnabled && (
					<p className={styles.signUpLinkWrapper}>
						<a
							className={styles.link}
							href={`${config.OPEN_BUSINESS_ACCOUNT_URL}${persistedSearch}`}
						>
							<FormattedMessage {...messages.openBusinessAccount} />
						</a>
					</p>
				)}
			</form>
			<>
				{socialLoginEnabled && (
					<>
						<div className={styles.separator}>
							<FormattedMessage {...messages.or} />
						</div>
						<div className={styles.socialLoginContainer}>
							<SocialLogin
								buttonMessage={messages.socialButton}
								disabled={isRequestPending}
								executeRecaptchaAsync={executeRecaptchaAsync}
								handleTwoFA={handleTwoFA}
								onError={(error?: Response<Error> | string | null) =>
									setFormError(error || '')
								}
							/>
						</div>
					</>
				)}
			</>
			<p className={styles.loginLinkWrapper}>
				<FormattedMessage
					{...messages.alreadyHaveAccount}
					values={{
						link: (text: string) => (
							<Link
								to={{ pathname: 'login', search: persistedSearch }}
								className={classNames(styles.link, styles.block)}
								href="login"
							>
								{text}
							</Link>
						),
					}}
				/>
			</p>
		</UserAccessContainer>
	);
};

export default SignUp;
