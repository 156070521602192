/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-no-useless-fragment */
import classNames from 'classnames';
import React, { ChangeEvent, FormEvent, ReactNode } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import FormMessage, { FormMessageType } from '../../../../components/FormMessage/FormMessage';
import Input from '../../../../components/Input/Input';
import PasswordInput from '../../../../components/PasswordInput/PasswordInput';
import UserAccessContainer from '../../../../components/UserAccessContainer/UserAccessContainer';
import config from '../../../../config';
import SearchParamError from '../../../../enums/SearchParamError';
import { useClientConfig } from '../../../../helpers/themeHelpers';
import Error from '../../../../interfaces/Error';
import Response from '../../../../interfaces/Response';
import errorMessages from '../../../../messages/errorMessages';
import SocialLogin from '../../../SocialLogin/SocialLogin';
import { LoginSubmitValues } from '../../../Login/Login';
import styles from './DefaultLoginForm.module.scss';
import usePersistedSearch from '../../../../hooks/usePersistedSearch';

const messages = defineMessages({
	welcomeToClient: {
		id: 'body.welcomeToClient',
		defaultMessage: 'Welcome to {client}',
	},
	or: {
		id: 'base.or',
		defaultMessage: 'or',
	},
	logIn: {
		id: 'base.login',
		defaultMessage: 'Log in',
	},
	forgotYourPassword: {
		id: 'base.forgotYourPassword',
		defaultMessage: 'Forgot your password?',
	},
	signUp: {
		id: 'base.signup',
		defaultMessage: 'Sign up',
	},
	dontHaveAccountYet: {
		id: 'login.dontHaveAccountYet',
		defaultMessage: `Don't have account yet? <link>Sign up</link>`,
	},
	email: {
		id: 'base.emailInputLabel',
		defaultMessage: 'Email adress',
	},
	password: {
		id: 'base.password',
		defaultMessage: 'Password',
	},
	emailInputPlaceholder: {
		id: 'login.emailInputPlaceholder',
		defaultMessage: 'Enter your email address',
	},
	passwordInputPlaceholder: {
		id: 'login.passwordInputPlaceholder',
		defaultMessage: 'Enter your password',
	},
	socialButton: {
		id: 'socialLogin.continueWith',
		defaultMessage: 'Continue with {provider}',
	},
});

interface Errors {
	[key: string]: string;

	username: string;
	password: string;
}

interface FormState {
	username: string;
	password: string;
}

interface LocationState {
	note: string;
}

interface SocialSignInInitialData {
	apple_id_oauth_code?: string;
	apple_id_state?: string;
	password?: string;
	provider?: string;
	token?: string;
	username?: string;
}

interface LoginFormProps {
	disable?: boolean;
	handleTwoFA: (isVisible: boolean, values: LoginSubmitValues) => void;
	executeRecaptchaAsync: (onClose?: () => void) => any;
	handleLoginFormSubmit: (values: { username: string; password: string }) => void;
	formState: FormState;
	errorMessage?: ReactNode;
	error: string | string[] | null;
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	errors: Errors;
	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;
	setFormError: (error: Response<Error> | string | null) => void;
	setSelectCountryView: React.Dispatch<React.SetStateAction<boolean>>;
	setSocialSignInInitialData: React.Dispatch<React.SetStateAction<SocialSignInInitialData>>;
}

const searchParamErrorMap = {
	[SearchParamError.authenticationCancelled]: errorMessages.twoFAcancelled,
	[SearchParamError.loginCancelled]: errorMessages.loginCancelled,
	[SearchParamError.unexpectedError]: errorMessages.unexpectedError,
};

const LoginForm = ({
	handleTwoFA,
	executeRecaptchaAsync,
	disable = false,
	handleLoginFormSubmit,
	formState,
	errorMessage,
	error,
	handleChange,
	errors,
	isLoading,
	setIsLoading,
	setFormError,
	setSelectCountryView,
	setSocialSignInInitialData,
}: LoginFormProps) => {
	const history = useHistory();
	const { formatMessage } = useIntl();
	const {
		businessSignupEnabled,
		signupEnabled,
		socialLoginEnabled,
		passwordRecoveryEnabled,
		showAppLinksOnLogin,
	} = useClientConfig();
	const location = useLocation<LocationState>();
	const persistedSearch = usePersistedSearch();
	const formSuccess = location?.state?.note;

	return (
		<UserAccessContainer
			title={messages.welcomeToClient}
			showAppLinks={showAppLinksOnLogin}
			hasImageColumn
		>
			<form
				onSubmit={(event: FormEvent<HTMLFormElement>) => {
					event.preventDefault();
					handleLoginFormSubmit({
						username: formState.username,
						password: formState.password,
					});
				}}
			>
				{!errorMessage && error && searchParamErrorMap[error as SearchParamError] && (
					<FormMessage className={styles.message} type={FormMessageType.ERROR}>
						<FormattedMessage {...searchParamErrorMap[error as SearchParamError]} />
					</FormMessage>
				)}
				{errorMessage ? (
					<FormMessage className={styles.message} type={FormMessageType.ERROR}>
						{errorMessage}
					</FormMessage>
				) : (
					formSuccess && (
						<FormMessage className={styles.message} type={FormMessageType.SUCCESS}>
							{formSuccess}
						</FormMessage>
					)
				)}
				<Input
					className={styles.emailInput}
					error={errors.username}
					id="username"
					label={formatMessage(messages.email)}
					name="username"
					onChange={handleChange}
					placeholder={formatMessage(messages.emailInputPlaceholder)}
					type="text"
					value={formState.username}
					inputMode="email"
				/>
				<PasswordInput
					className={styles.passwordInput}
					error={errors.password}
					id="password"
					label={formatMessage(messages.password)}
					name="password"
					onChange={handleChange}
					placeholder={formatMessage(messages.passwordInputPlaceholder)}
					value={formState.password}
				/>
				<Button
					withCaptcha
					isLoading={isLoading}
					disabled={disable}
					className={styles.button}
					type={ButtonType.SUBMIT}
					buttonStyle={ButtonStyle.PRIMARY}
					text={formatMessage(messages.logIn)}
				/>

				{(businessSignupEnabled || signupEnabled) && (
					<Button
						className={styles.button}
						type={ButtonType.BUTTON}
						buttonStyle={ButtonStyle.SECONDARY}
						text={formatMessage(messages.signUp)}
						disabled={disable}
						onClick={() =>
							history.push({
								pathname:
									businessSignupEnabled && !signupEnabled
										? 'open-business-account'
										: config.BASE_URL_SIGNUP,
								search: persistedSearch,
							})
						}
					/>
				)}
				{passwordRecoveryEnabled && (
					<Link
						to={{
							pathname: `${config.BASE_URL_LOGIN}/recover`,
							search: persistedSearch,
						}}
						className={classNames(styles.link, styles.forgotPasswordLink)}
					>
						{formatMessage(messages.forgotYourPassword)}
					</Link>
				)}
			</form>
			<>
				{socialLoginEnabled && (
					<>
						<div className={styles.separator}>
							<FormattedMessage {...messages.or} />
						</div>
						<div className={styles.socialButtons}>
							<SocialLogin
								buttonMessage={messages.socialButton}
								disabled={isLoading || disable}
								executeRecaptchaAsync={executeRecaptchaAsync}
								handleTwoFA={handleTwoFA}
								onRequestPending={(isPending: boolean) => setIsLoading(isPending)}
								onError={(error?: Response<Error> | string | null) =>
									setFormError(error || '')
								}
								setSelectCountryView={setSelectCountryView}
								setSocialSignInInitialData={setSocialSignInInitialData}
							/>
						</div>
					</>
				)}
			</>
		</UserAccessContainer>
	);
};

export default LoginForm;
