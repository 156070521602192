/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-template-curly-in-string */
import Cookie from 'js-cookie';
import queryString from 'query-string';
import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import validator from 'validator';
import MobileAppSuggestion from '../../components/MobileAppSuggestion/MobileAppSuggestion';
import { SelectChangeEvent } from '../../components/Select/Select';
import config from '../../config';
import endpoints, { restrictedCountries } from '../../endpoints';
import { Clients } from '../../enums/Clients';
import StorageKey from '../../enums/StorageKey';
import countries from '../../helpers/countryHelper';
import { getAllSubdomains } from '../../helpers/functionHelpers';
import { getMappedLanguage } from '../../helpers/languageMapHelper';
import {
	getPrivacyPolicyURLByClient,
	getTermsAndConditionsURLByClient,
	useClientConfig,
} from '../../helpers/themeHelpers';
import validation from '../../helpers/validationHelpers';
import useAuthErrorMessage from '../../hooks/useAuthErrorMessage';
import useUnexpectedErrorMessage from '../../hooks/useUnexpectedErrorMessage';
import useAxios from '../../hooks/useAxios';
import useRecaptcha from '../../hooks/useRecaptcha';
import useReferral from '../../hooks/useReferral';
import useScrollToTop from '../../hooks/useScrollToTop';
import Error from '../../interfaces/Error';
import Response from '../../interfaces/Response';
import SelectOption from '../../interfaces/SelectOption';
import errorMessages from '../../messages/errorMessages';
import { Context } from '../../store/Provider';
import DefaultSignupForm from '../ClientComponents/Default/SignupForm/DefaultSignUpForm';
import SCSignupForm from '../ClientComponents/Spectrocoin/SignupForm/SCSignUpForm';
import { LoginSubmitValues } from '../Login/Login';
import LoginTwoFA from '../Login/LoginTwoFA/LoginTwoFA';
import WaitingListDialog from './WaitingListDialog/WaitingListDialog';
import SessionRequired from '../../components/SessionRequired/SessionRequired';
import Protected from '../../components/Protected/Protected';
import Disclaimer from '../../components/Disclaimer/Disclaimer';

enum FieldNames {
	username = 'username',
	country = 'country',
	acceptPrivacyPolicyAndTerms = 'acceptPrivacyPolicyAndTerms',
}

interface FormState {
	[FieldNames.username]: string;
	[FieldNames.country]: string;
	[FieldNames.acceptPrivacyPolicyAndTerms]: boolean;
}

interface Errors {
	[FieldNames.username]: string;
	[FieldNames.country]: string;
	[FieldNames.acceptPrivacyPolicyAndTerms]: string;
}

interface RestrictedCountries {
	restrictedCountries: string[];
	userCountry: string;
}

interface CountryOptions {
	selectCountries: SelectOption[];
	userCountry: string;
}

interface Contract {
	type: string;
	urlPathFormat: string;
	providedBy: string | null;
}

const WithRecaptcha: FC = ({ children }) => {
	return (
		<>
			{children}
			<div id="recaptcha" />
		</>
	);
};

const Content = () => {
	const { formatMessage, locale } = useIntl();
	const axios = useAxios();
	const history = useHistory();
	const { isMobileClient, lang, isRecaptchaEnabled } = useContext(Context).state;
	const { referralID, refTrackID } = useReferral();
	const location = useLocation();
	const { email } = queryString.parse(location.search);
	const { title, privacyPolicy, termsAndConditions, hasCountrySelectOnSignup, domainName } =
		useClientConfig();

	const [countryOptions, setCountryOptions] = useState<SelectOption[]>([]);
	const [isRestrictedCountry, setIsRestrictedCountry] = useState(false);
	const [formError, setFormError] = useState<Response<Error> | string | null>();
	const errorMessage = useAuthErrorMessage(formError);
	const { getUnexpectedErrorMessage } = useUnexpectedErrorMessage();
	const [errors, setErrors] = useState<Errors>({
		username: '',
		country: '',
		acceptPrivacyPolicyAndTerms: '',
	});
	const [formState, setFormState] = useState<FormState>({
		username: (email as string) || '',
		country: '',
		acceptPrivacyPolicyAndTerms: false,
	});
	const [twoFARequired, setTwoFARequired] = useState(false);
	const [submitValues, setSubmitValues] = useState<LoginSubmitValues | null>(null);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [termsAndConditionsPath, setTermsAndConditionsPath] = useState();
	const [privacyPolicyPath, setPrivacyPolicyPath] = useState();
	const [provider, setProvider] = useState();

	const {
		executeAsync: executeRecaptchaAsync,
		isLoading: isRecaptchaLoading,
		reloadCaptcha,
	} = useRecaptcha({});

	const schemeAttribute = isMobileClient ? title.toLowerCase() : 'https';
	const linkProps = !isMobileClient ? { rel: 'noreferrer noopener', target: '_blank' } : {};

	// if there is a country selection, path is coming from the endpoint
	// see useEffect down below
	const privacyPolicyURL =
		hasCountrySelectOnSignup && privacyPolicyPath
			? `${schemeAttribute}://${domainName}/${privacyPolicyPath}`
			: getPrivacyPolicyURLByClient(privacyPolicy, lang);

	const termsAndConditionsURL =
		hasCountrySelectOnSignup && termsAndConditionsPath
			? `${schemeAttribute}://${domainName}/${termsAndConditionsPath}`
			: getTermsAndConditionsURLByClient(termsAndConditions, lang);

	useScrollToTop([twoFARequired, isRestrictedCountry]);

	const handleTwoFA = (isVisible: boolean, values: LoginSubmitValues | null) => {
		setTwoFARequired(isVisible);
		setSubmitValues(values);
		if (isVisible && values) {
			reloadCaptcha();
		}
	};

	const handleTwoFACancel = () => {
		setTwoFARequired(false);
		setSubmitValues(null);
		history.push(`${config.BASE_URL_SIGNUP}`);
	};

	const handleValidation = () => {
		const { username, acceptPrivacyPolicyAndTerms } = formState;
		let isValid = true;

		const formErrors: Errors = {
			username: '',
			country: '',
			acceptPrivacyPolicyAndTerms: '',
		};
		if (validation.isEmpty(username)) {
			formErrors.username = formatMessage(errorMessages.requiredField);
			isValid = false;
		}
		if (!validator.isEmail(username)) {
			formErrors.username = formatMessage(errorMessages.invalidEmail);
			isValid = false;
		}
		if (!acceptPrivacyPolicyAndTerms) {
			formErrors.acceptPrivacyPolicyAndTerms = formatMessage(errorMessages.acceptTerms);
			isValid = false;
		}

		setErrors(formErrors);
		return isValid;
	};

	const handleSubmit = async (values: FormState) => {
		if (!handleValidation()) return;
		setFormError(null);

		const { username, acceptPrivacyPolicyAndTerms, country } = values;
		const recaptcha = isRecaptchaEnabled ? await executeRecaptchaAsync() : null;
		const utmTrackingId = Cookie.get(StorageKey.UTM_UUID);

		setIsRequestPending(true);
		axios
			.post(
				endpoints.signup(),
				{
					username,
					country,
					userMetaData: [
						{ key: 'contractCountry', value: country },
						{ key: 'acceptPrivacyPolicy', value: acceptPrivacyPolicyAndTerms },
						{ key: 'acceptTermsAndConditions', value: acceptPrivacyPolicyAndTerms },
						...(typeof refTrackID !== 'undefined' && refTrackID !== null
							? [{ key: 'parentUserTrackId', value: refTrackID }]
							: []),
						...(typeof referralID !== 'undefined' && referralID !== null
							? [{ key: 'parentUserRefId', value: referralID }]
							: []),
					],
					language: getMappedLanguage(locale),
					refId: referralID || undefined,
					refTraceId: refTrackID || undefined,
					utmTrackingId: utmTrackingId || undefined,
				},
				{
					headers: {
						recaptcha,
					},
				}
			)
			.then(() => {
				setIsRequestPending(false);

				// Remove ref and utm tracking cookies from all possible domains after we used them.
				// We can not be sure on which parent domain it will be set (.bankera.com? .auth.bankera.com?),
				// only that it will be set on some parent domain.
				const potentialCookieDomains = getAllSubdomains(window.location.origin);
				potentialCookieDomains.forEach((domain: string) => {
					Cookie.remove(StorageKey.REF_ID, { domain });
					Cookie.remove(StorageKey.REF_ID, { domain: `.${domain}` });
				});
				potentialCookieDomains.forEach((domain: string) => {
					Cookie.remove(StorageKey.UTM_UUID, { domain });
					Cookie.remove(StorageKey.UTM_UUID, { domain: `.${domain}` });
				});
				history.push(
					`${config.BASE_URL_SIGNUP}/confirmation-required?email=${encodeURIComponent(
						username
					)}`
				);
			})
			.catch((response: Response<Error>) => {
				if (response?.data.key === 'country_is_restricted') {
					setIsRestrictedCountry(true);
					reloadCaptcha();
				} else {
					setFormError(response);
				}
				setIsRequestPending(false);
			});
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setErrors((p) => ({ ...p, [event.target.name]: '' }));
		setFormState((prevState: FormState) => ({
			...prevState,
			[event.target.name]:
				event.target.getAttribute('type') === 'checkbox'
					? !prevState[event.target.name as FieldNames]
					: event.target.value,
		}));
	};

	const handleSelectChange = (event: SelectChangeEvent) => {
		setFormState((prevState: FormState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	};

	const getCountryOptions = useCallback(() => {
		return new Promise<CountryOptions>((resolve, reject) => {
			axios
				.get(restrictedCountries())
				.then(({ data }: Response<RestrictedCountries>) => {
					const { userCountry } = data;
					const options = Object.keys(countries.getAlpha3Codes())
						.filter((code) => code !== 'RUS')
						.map((code) => ({
							value: code,
							label: countries.getName(code, 'en'),
						}))
						.sort((a, b) => {
							if (a.label < b.label) return -1;
							if (a.label > b.label) return 1;
							return 0;
						});

					resolve({ selectCountries: options, userCountry });
				})
				.catch((error) => {
					reject(error);
				});
		});
	}, [axios]);

	useEffect(() => {
		if (!hasCountrySelectOnSignup) return;

		getCountryOptions()
			.then(({ selectCountries, userCountry }: CountryOptions) => {
				setCountryOptions(selectCountries);
				const selectedCountry =
					selectCountries.find((option: SelectOption) => option.value === userCountry)
						?.value || selectCountries[0].value;

				setFormState((prevState: FormState) => ({
					...prevState,
					country: selectedCountry,
				}));
			})
			.catch((e: Response<Error>) => {
				const traceId = e?.data?.traceId;
				setFormError(getUnexpectedErrorMessage(traceId));
			});
	}, [getCountryOptions]);

	useEffect(() => {
		// Only used for signups with contracts and countries selection
		void (async () => {
			if (validation.isEmpty(formState.country) || !hasCountrySelectOnSignup) return;
			await axios
				.get(endpoints.getSignUpContracts(formState.country))
				.then((response) => {
					const responseByContractType = response.data.reduce(
						(accumulator: { [type: string]: Contract }, contract: Contract) => {
							accumulator[contract.type] = contract;
							return accumulator;
						},
						{}
					);
					setTermsAndConditionsPath(
						responseByContractType.GENERAL_TERMS_AND_CONDITIONS.urlPathFormat.replace(
							'/${lng}',
							locale
						)
					);
					setPrivacyPolicyPath(
						responseByContractType.PRIVACY_POLICY.urlPathFormat.replace(
							'/${lng}',
							locale
						)
					);
					setProvider(responseByContractType.GENERAL_TERMS_AND_CONDITIONS.providedBy!);
				})
				.catch((e: Response<Error>) => {
					const traceId = e?.data?.traceId;
					setFormError(getUnexpectedErrorMessage(traceId));
				});
		})();
	}, [axios, formState.country, formatMessage, locale]);

	if (twoFARequired && submitValues) {
		return (
			<WithRecaptcha>
				<LoginTwoFA
					executeRecaptchaAsync={executeRecaptchaAsync}
					reloadCaptcha={reloadCaptcha}
					onCancel={handleTwoFACancel}
					submitValues={submitValues}
				/>
			</WithRecaptcha>
		);
	}
	if (isRestrictedCountry) {
		return (
			<WithRecaptcha>
				<WaitingListDialog
					executeRecaptchaAsync={executeRecaptchaAsync}
					requestData={{
						country: formState.country,
						language: locale,
						email: formState.username,
						waitingListMetaData: [
							{
								key: 'contractCountry',
								value: formState.country,
							},
							{
								key: 'acceptPrivacyPolicy',
								value: formState.acceptPrivacyPolicyAndTerms,
							},
							{
								key: 'acceptTermsAndConditions',
								value: formState.acceptPrivacyPolicyAndTerms,
							},
						],
					}}
				/>
			</WithRecaptcha>
		);
	}

	const clientForm = () => {
		switch (config.CLIENT_NAME) {
			case Clients.SPECTROCOIN:
				return (
					<SCSignupForm
						handleSubmit={handleSubmit}
						formState={formState}
						errorMessage={errorMessage}
						errors={errors}
						handleInputChange={handleInputChange}
						countryOptions={countryOptions}
						handleSelectChange={handleSelectChange}
						privacyPolicyURL={privacyPolicyURL}
						linkProps={linkProps}
						termsAndConditionsURL={termsAndConditionsURL}
						provider={provider}
						isRecaptchaLoading={isRecaptchaLoading}
						isRequestPending={isRequestPending}
						executeRecaptchaAsync={executeRecaptchaAsync}
						handleTwoFA={handleTwoFA}
						setFormError={setFormError}
					/>
				);
			default:
				return (
					<DefaultSignupForm
						handleSubmit={handleSubmit}
						formState={formState}
						errorMessage={errorMessage}
						errors={errors}
						handleInputChange={handleInputChange}
						countryOptions={countryOptions}
						handleSelectChange={handleSelectChange}
						privacyPolicyURL={privacyPolicyURL}
						linkProps={linkProps}
						termsAndConditionsURL={termsAndConditionsURL}
						provider={provider}
						isRecaptchaLoading={isRecaptchaLoading}
						isRequestPending={isRequestPending}
						executeRecaptchaAsync={executeRecaptchaAsync}
						handleTwoFA={handleTwoFA}
						setFormError={setFormError}
					/>
				);
		}
	};

	return (
		<>
			<WithRecaptcha>{clientForm()}</WithRecaptcha>
			<MobileAppSuggestion />
			<Disclaimer />
		</>
	);
};

const SignUp = () => {
	return (
		<SessionRequired flow="signup">
			<Protected accessor="signupEnabled">
				<Content />
			</Protected>
		</SessionRequired>
	);
};

export default SignUp;
