import classNames from 'classnames';
import React from 'react';
import styles from './CriticalError.module.scss';

const CriticalError = () => {
	return (
		<div className={classNames(styles.error)}>
			<div className={styles.message}>Could not load app</div>
		</div>
	);
};

export default CriticalError;
