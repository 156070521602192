import React, { useEffect, useRef, useState } from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import FieldError from '../FieldError/FieldError';
import config from '../../config';
import './PhoneInput.scss';
import { getGeoLocationCountry } from '../../helpers/countryHelper';

interface Props {
	setInstance?: (instance: intlTelInput.Plugin) => void;
	className?: string;
	error?: string;
	label?: string;
	onChange: (value: string) => void;
}

const PhoneInput = ({ setInstance, className, error, label, onChange }: Props) => {
	const [inputShouldChange, setInputShouldChange] = useState('');
	const inputEl = useRef(null);
	const instance = useRef<intlTelInput.Plugin | null>(null);

	useEffect(() => {
		const getCountryAndInitTelInput = async () => {
			const country = await getGeoLocationCountry();
			instance.current = intlTelInput(inputEl.current!, {
				utilsScript:
					process.env.NODE_ENV === 'development'
						? `/phone-utils.js`
						: `${config.PUBLIC_URL}/phone-utils.js`,
				separateDialCode: true,
				initialCountry: 'auto',
				geoIpLookup: (success) => {
					success(country);
				},
			});
			if (setInstance) {
				setInstance(instance.current);
			}
		};
		void getCountryAndInitTelInput();

		if (inputEl.current !== null) {
			(inputEl.current! as HTMLInputElement).addEventListener('click', () => {
				onChange(instance.current?.getNumber() || '');
				setInputShouldChange(instance.current?.getNumber() || String(Math.random()));
			});
			(inputEl.current! as HTMLInputElement).addEventListener('keypress', () => {
				onChange(instance.current?.getNumber() || '');
				setInputShouldChange(instance.current?.getNumber() || String(Math.random()));
			});
			(inputEl.current! as HTMLInputElement).addEventListener('keyup', () => {
				onChange(instance.current?.getNumber() || '');
				setInputShouldChange(instance.current?.getNumber() || String(Math.random()));
			});
			(inputEl.current! as HTMLInputElement).addEventListener('countrychange', () => {
				onChange(instance.current?.getNumber() || '');
				setInputShouldChange(instance.current?.getNumber() || String(Math.random()));
			});
		}
	}, []);

	useEffect(() => {
		if (inputEl.current !== null) {
			onChange(instance.current?.getNumber() || '');
		}
	}, [inputShouldChange]);

	return (
		<div className={className}>
			<label className="iti--label" htmlFor="iti-input">
				{label}
			</label>
			<input ref={inputEl} name="iti-input" />
			<FieldError error={error} />
		</div>
	);
};

export default PhoneInput;
