import React from 'react';
import classNames from 'classnames';
import styles from './FormMessage.module.scss';

export enum FormMessageType {
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

interface FormMessageProps {
	children: React.ReactNode | string;
	className?: string;
	type: FormMessageType;
}

export default ({ children, className, type }: FormMessageProps) => (
	<div className={classNames(styles.message, styles[type], className)}>{children}</div>
);
