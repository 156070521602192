import React, { AllHTMLAttributes } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import styles from './Button.module.scss';

export enum ButtonStyle {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	SECONDARY_WITHOUT_BORDER = 'secondaryWithoutBorder',
	SECONDARY_FILLED = 'secondaryFilled',
	LINK = 'link',
}

export enum ButtonType {
	SUBMIT = 'submit',
	BUTTON = 'button',
	LINK = 'link',
	ANCHOR_LINK = 'anchor_link',
}

interface ButtonProps extends AllHTMLAttributes<HTMLElement> {
	buttonStyle?: ButtonStyle;
	children?: React.ReactNode;
	isLoading?: boolean;
	link?: string | Location;
	text?: string;
	type?: ButtonType;
	withCaptcha?: boolean;
}

const Button = ({
	buttonStyle = ButtonStyle.PRIMARY,
	className,
	isLoading = false,
	disabled,
	link,
	text,
	type,
	withCaptcha,
	children,
	...attributes
}: ButtonProps) => {
	const buttonClassName = classNames(styles.button, styles[buttonStyle], className);
	switch (type) {
		case ButtonType.LINK:
			return (
				<Link className={buttonClassName} to={link!} {...attributes}>
					{children || text}
				</Link>
			);
		case ButtonType.ANCHOR_LINK:
			return (
				<a
					className={buttonClassName}
					href={typeof link === 'string' ? link : link?.href}
					{...attributes}
				>
					{children || text}
				</a>
			);
		case ButtonType.BUTTON:
		case ButtonType.SUBMIT:
		default:
			if (withCaptcha) {
				return (
					<>
						<div id="cf-captcha" className={styles.cfCaptcha} />
						<button
							className={buttonClassName}
							// eslint-disable-next-line react/button-has-type
							type={type}
							{...attributes}
							disabled={isLoading || disabled}
						>
							{isLoading ? (
								<FontAwesomeIcon
									icon={faSpinner as IconDefinition}
									className={styles.animation}
								/>
							) : (
								children || text
							)}
						</button>
					</>
				);
			}

			return (
				<button
					className={buttonClassName}
					// eslint-disable-next-line react/button-has-type
					type={type}
					{...attributes}
					disabled={isLoading || disabled}
				>
					{isLoading ? (
						<FontAwesomeIcon
							icon={faSpinner as IconDefinition}
							className={styles.animation}
						/>
					) : (
						children || text
					)}
				</button>
			);
	}
};

export default Button;
