/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-template-curly-in-string */
import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import validator from 'validator';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import config from '../../config';
import endpoints from '../../endpoints';
import { Context } from '../../store/Provider';
import useRecaptcha from '../../hooks/useRecaptcha';
import useAxios from '../../hooks/useAxios';
import useAuthErrorMessage from '../../hooks/useAuthErrorMessage';
import Response from '../../interfaces/Response';
import Error from '../../interfaces/Error';
import Input from '../../components/Input/Input';
import Button, { ButtonStyle, ButtonType } from '../../components/Button/Button';
import UserAccessContainer from '../../components/UserAccessContainer/UserAccessContainer';
import Checkbox from '../../components/Checkbox/Checkbox';
import FormMessage, { FormMessageType } from '../../components/FormMessage/FormMessage';
import styles from './OpenBusinessAccount.module.scss';
import ConfirmationRequired from './ConfirmationRequired/ConfirmationRequired';
import { getPrivacyPolicyURLByClient, useClientConfig } from '../../helpers/themeHelpers';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import errorMessages from '../../messages/errorMessages';
import MobileAppSuggestion from '../../components/MobileAppSuggestion/MobileAppSuggestion';
import usePersistedSearch from '../../hooks/usePersistedSearch';
import CaptchaType from '../../enums/CaptchaType';

const messages = defineMessages({
	signUp: {
		id: 'base.signup',
		defaultMessage: 'Sign up',
	},
	alreadyHaveAccount: {
		id: 'signup.alreadyHaveAccount',
		defaultMessage: 'Already have an account? <link>Login here</link>',
	},
	emailInputLabel: {
		id: 'base.emailInputLabel',
		defaultMessage: 'Email address',
	},
	companyNameInputLabel: {
		id: 'base.companyNameInputLabel',
		defaultMessage: 'Company name',
	},
	firstNameInputLabel: {
		id: 'base.firstNameInputLabel',
		defaultMessage: 'First name',
	},
	lastNameInputLabel: {
		id: 'base.lastNameInputLabel',
		defaultMessage: 'Last name',
	},
	country: {
		id: 'base.country',
		defaultMessage: 'Country',
	},
	checkboxLabel: {
		id: 'signup.checkboxLabelForBusinessAccount',
		defaultMessage:
			'I agree to the <privacyPolicyLink>Privacy Policy</privacyPolicyLink> and the processing of my personal data',
	},
	openPersonalAccount: {
		id: 'signup.openPersonalAccount',
		defaultMessage: 'Open Personal Account',
	},
	signUpForBusinessAccount: {
		id: 'signup.signUpForBusinessAccount',
		defaultMessage: '<p>Sign up for {br} <strong>Business Account</strong></p>',
	},
	requiredField: {
		id: 'base.requiredField',
		defaultMessage: 'This field is required',
	},
	emailInputPlaceholder: {
		id: 'login.emailInputPlaceholder',
		defaultMessage: 'Enter your email address',
	},
	companyInputPlaceholder: {
		id: 'login.companyInputPlaceholder',
		defaultMessage: 'Enter your company name',
	},
	firstNameInputPlaceholder: {
		id: 'login.firstNameInputPlaceholder',
		defaultMessage: 'Enter your first name',
	},
	lastNameInputPlaceholder: {
		id: 'login.lastNameInputPlaceholder',
		defaultMessage: 'Enter your last name',
	},
});

enum FieldNames {
	companyName = 'companyName',
	firstName = 'firstName',
	lastName = 'lastName',
	emailAddress = 'emailAddress',
	acceptPrivacyPolicyAndTerms = 'acceptPrivacyPolicyAndTerms',
}

interface FormState {
	[FieldNames.companyName]: string;
	[FieldNames.firstName]: string;
	[FieldNames.lastName]: string;
	[FieldNames.emailAddress]: string;
	[FieldNames.acceptPrivacyPolicyAndTerms]: boolean;
}

interface Errors {
	[FieldNames.companyName]: string;
	[FieldNames.firstName]: string;
	[FieldNames.lastName]: string;
	[FieldNames.emailAddress]: string;
	[FieldNames.acceptPrivacyPolicyAndTerms]: string;
}

const OpenBusinessAccount: React.FC = () => {
	const { formatMessage } = useIntl();
	const persistedSearch = usePersistedSearch();

	const axios = useAxios();
	const { isMobileClient, lang, isRecaptchaEnabled, captchaType } = useContext(Context).state;
	const { signupEnabled, privacyPolicy, businessAccountEnv, rightColImage_2 } = useClientConfig();
	const [formError, setFormError] = useState<Response<Error> | string | null>();
	const errorMessage = useAuthErrorMessage(formError);
	const [errors, setErrors] = useState<Errors>({
		companyName: '',
		firstName: '',
		lastName: '',
		emailAddress: '',
		acceptPrivacyPolicyAndTerms: '',
	});
	const [formState, setFormState] = useState<FormState>({
		companyName: '',
		firstName: '',
		lastName: '',
		emailAddress: '',
		acceptPrivacyPolicyAndTerms: false,
	});
	const [isConfirmationWindowVisible, setIsConfirmationWindowVisible] = useState(false);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const { executeAsync: executeRecaptchaAsync, isLoading: isRecaptchaLoading } = useRecaptcha({
		siteKeyValue:
			captchaType === CaptchaType.RECAPTCHA
				? config.OPEN_BUSINESS_ACCOUNT_SEND_EMAIL_RECAPTCHA_SITEKEY
				: undefined,
	});
	const privacyPolicyURL = getPrivacyPolicyURLByClient(privacyPolicy, lang);
	const linkProps = !isMobileClient ? { rel: 'noreferrer noopener', target: '_blank' } : {};

	useEffect(() => {
		setIsButtonDisabled(
			!validator.isEmail(formState.emailAddress) ||
				validator.isEmpty(formState.firstName, { ignore_whitespace: true }) ||
				validator.isEmpty(formState.lastName, { ignore_whitespace: true }) ||
				validator.isEmpty(formState.companyName, { ignore_whitespace: true }) ||
				!formState.acceptPrivacyPolicyAndTerms
		);
	}, [formState]);

	const handleValidation = () => {
		const { companyName, firstName, lastName, emailAddress, acceptPrivacyPolicyAndTerms } =
			formState;
		let isValid = true;

		const formErrors: Errors = {
			companyName: '',
			firstName: '',
			lastName: '',
			emailAddress: '',
			acceptPrivacyPolicyAndTerms: '',
		};

		if (!validator.isEmail(emailAddress)) {
			formErrors.emailAddress = formatMessage(errorMessages.invalidEmail);
			isValid = false;
		}
		if (validator.isEmpty(firstName, { ignore_whitespace: true })) {
			formErrors.firstName = formatMessage(messages.requiredField);
			isValid = false;
		}
		if (validator.isEmpty(lastName, { ignore_whitespace: true })) {
			formErrors.lastName = formatMessage(messages.requiredField);
			isValid = false;
		}
		if (validator.isEmpty(companyName, { ignore_whitespace: true })) {
			formErrors.companyName = formatMessage(messages.requiredField);
			isValid = false;
		}
		if (!acceptPrivacyPolicyAndTerms) {
			formErrors.acceptPrivacyPolicyAndTerms = formatMessage(errorMessages.acceptTerms);
			isValid = false;
		}

		setErrors(formErrors);
		return isValid;
	};

	const handleSubmit = async (values: FormState) => {
		if (!handleValidation()) return;
		setFormError(null);

		const { companyName, firstName, lastName, emailAddress } = values;
		const recaptcha = isRecaptchaEnabled ? await executeRecaptchaAsync() : null;
		setIsRequestPending(true);
		void axios
			.post(
				endpoints.openBusinessAccountSendEmail(),
				{
					env: businessAccountEnv,
					token: recaptcha,
					company: companyName,
					email: emailAddress,
					firstName,
					lastName,
				},
				{
					headers: {
						CaptchaType: captchaType,
					},
				}
			)
			.then(() => {
				setIsConfirmationWindowVisible(true);
			})
			.catch((response: Response<Error>) => {
				setFormError(response);
			})
			.then(() => {
				setIsRequestPending(false);
			});
	};

	function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		event.persist();
		setErrors((p) => ({ ...p, [event.target.name]: '' }));
		setFormState((prevState: FormState) => ({
			...prevState,
			[event.target.name]:
				event.target.getAttribute('type') === 'checkbox'
					? !prevState[event.target.name as FieldNames]
					: event.target.value,
		}));
	}

	const renderOpenBusinessAccountForm = () => {
		if (isConfirmationWindowVisible) {
			return <ConfirmationRequired />;
		}
		return (
			<UserAccessContainer
				title={messages.signUpForBusinessAccount}
				rightColumnImage={rightColImage_2}
			>
				<form
					noValidate
					onSubmit={(event: FormEvent<HTMLFormElement>) => {
						event.preventDefault();
						void handleSubmit({
							companyName: formState.companyName,
							firstName: formState.firstName,
							lastName: formState.lastName,
							emailAddress: formState.emailAddress,
							acceptPrivacyPolicyAndTerms: formState.acceptPrivacyPolicyAndTerms,
						});
					}}
				>
					{errorMessage && (
						<FormMessage className={styles.message} type={FormMessageType.ERROR}>
							{errorMessage}
						</FormMessage>
					)}
					<Input
						className={styles.input}
						error={errors.companyName}
						id="companyName"
						label={formatMessage(messages.companyNameInputLabel)}
						name={FieldNames.companyName}
						onChange={handleInputChange}
						placeholder={formatMessage(messages.companyInputPlaceholder)}
						type="text"
						value={formState.companyName}
					/>
					<Input
						className={styles.input}
						error={errors.firstName}
						id="firstName"
						label={formatMessage(messages.firstNameInputLabel)}
						name={FieldNames.firstName}
						onChange={handleInputChange}
						placeholder={formatMessage(messages.firstNameInputPlaceholder)}
						type="text"
						value={formState.firstName}
					/>
					<Input
						className={styles.input}
						error={errors.lastName}
						id="lastName"
						label={formatMessage(messages.lastNameInputLabel)}
						name={FieldNames.lastName}
						onChange={handleInputChange}
						placeholder={formatMessage(messages.lastNameInputPlaceholder)}
						type="text"
						value={formState.lastName}
					/>
					<Input
						className={styles.input}
						error={
							formState.emailAddress && !validator.isEmail(formState.emailAddress)
								? formatMessage(errorMessages.invalidEmail)
								: errors.emailAddress
						}
						id="emailAddress"
						label={formatMessage(messages.emailInputLabel)}
						name={FieldNames.emailAddress}
						onChange={handleInputChange}
						placeholder={formatMessage(messages.emailInputPlaceholder)}
						type="text"
						value={formState.emailAddress}
						inputMode="email"
					/>
					<Checkbox
						checked={formState.acceptPrivacyPolicyAndTerms}
						error={errors.acceptPrivacyPolicyAndTerms}
						className={styles.checkbox}
						labelClassName={styles.checkboxLabel}
						onChange={handleInputChange}
						name={FieldNames.acceptPrivacyPolicyAndTerms}
					>
						<FormattedMessage
							{...messages.checkboxLabel}
							values={{
								br: <br />,
								span: (text: string) => <span className={styles.span}>{text}</span>,
								privacyPolicyLink: (text: string) => (
									<a
										className={styles.policyLink}
										href={privacyPolicyURL}
										{...linkProps}
									>
										{text}
									</a>
								),
							}}
						/>
					</Checkbox>
					<Button
						withCaptcha
						className={styles.button}
						disabled={isRecaptchaLoading || isButtonDisabled}
						isLoading={isRequestPending}
						type={ButtonType.SUBMIT}
						buttonStyle={ButtonStyle.PRIMARY}
						text={formatMessage(messages.signUp)}
					/>
					{signupEnabled && (
						<p className={styles.signUpLinkWrapper}>
							<a
								className={styles.link}
								href={`${config.OPEN_PERSONAL_ACCOUNT_URL}${persistedSearch}`}
							>
								<FormattedMessage {...messages.openPersonalAccount} />
							</a>
						</p>
					)}
				</form>
				<p className={styles.loginLinkWrapper}>
					<FormattedMessage
						{...messages.alreadyHaveAccount}
						values={{
							link: (text: string) => (
								<Link
									to={{ pathname: 'login', search: persistedSearch }}
									className={classNames(styles.link, styles.block)}
									href="login"
								>
									{text}
								</Link>
							),
						}}
					/>
				</p>
			</UserAccessContainer>
		);
	};
	return (
		<>
			{renderOpenBusinessAccountForm()}
			<MobileAppSuggestion />
			<Disclaimer />
			<div id="recaptcha" />
		</>
	);
};

export default OpenBusinessAccount;
