/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-template-curly-in-string */
import React, { useState, FormEvent, ChangeEvent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import queryString from 'query-string';
import { UserInfo } from '../Login';
import useAxios from '../../../hooks/useAxios';
import useAuthErrorMessage from '../../../hooks/useAuthErrorMessage';
import validation from '../../../helpers/validationHelpers';
import Response from '../../../interfaces/Response';
import Error from '../../../interfaces/Error';
import Button, { ButtonStyle, ButtonType } from '../../../components/Button/Button';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import FormMessage, { FormMessageType } from '../../../components/FormMessage/FormMessage';
import endpoints from '../../../endpoints';
import styles from './ChangePassword.module.scss';
import PasswordChangeEnforcement from '../../../enums/PasswordChangeEnforcement';
import PasswordInput from '../../../components/PasswordInput/PasswordInput';
import useEnforcedRecaptcha from '../../../hooks/useEnforcedRecaptcha';

const messages = defineMessages({
	heading: {
		id: 'changePassword.heading',
		defaultMessage: 'Create your password',
	},
	updatePassword: {
		id: 'changePassword.updatePassword',
		defaultMessage: 'Update your password',
	},
	changePassword: {
		id: 'changePassword.changePassword',
		defaultMessage: 'Change password',
	},
	passwordIncorrect: {
		id: 'changePassword.passwordIncorrect',
		defaultMessage: 'Incorrect password',
	},
	notificationBody: {
		id: 'changePassword.notificationBody',
		defaultMessage: 'Your password needs to be changed. Please update it now.',
	},
	body: {
		id: 'createPassword.body',
		defaultMessage:
			'Your password must contain at least:<ul><li>10 characters</li><li>1 uppercase letter</li><li>1 lowercase letter</li><li>1 digit</li><li>1 special character</li></ul>',
	},
	passwordInvalid: {
		id: 'createPassword.passwordInvalid',
		defaultMessage: 'Password does not meet our requirements',
	},
	passwordsDoNotMatch: {
		id: 'createPassword.passwordsDoNotMatch',
		defaultMessage: 'Passwords must match',
	},
	currentPassword: {
		id: 'base.currentPassword',
		defaultMessage: 'Current password',
	},
	newPassword: {
		id: 'changePassword.newPassowrd',
		defaultMessage: 'New password',
	},
	confirmNewPassword: {
		id: 'changePassword.confirmNewPassword',
		defaultMessage: 'Repeat your new password',
	},
	next: {
		id: 'base.next',
		defaultMessage: 'Next',
	},
	cancel: {
		id: 'base.cancel',
		defaultMessage: 'Cancel',
	},
	requiredField: {
		id: 'base.requiredField',
		defaultMessage: 'This field is required',
	},
});

interface ChangePasswordProps {
	userInfo: UserInfo | null;
	enforcement: PasswordChangeEnforcement | string;
	isRecaptchaLoading: boolean;
	executeRecaptchaAsync: (onClose?: () => void) => any;
}

interface Errors {
	[key: string]: string;
	currentPassword: string;
	confirmNewPassword: string;
}

interface FormState {
	currentPassword: string;
	confirmNewPassword: string;
	newPassword: string;
}

const ChangePassword = ({
	userInfo,
	enforcement,
	executeRecaptchaAsync,
	isRecaptchaLoading,
}: ChangePasswordProps) => {
	const axios = useAxios();
	const { formatMessage } = useIntl();
	const [formError, setFormError] = useState<Response<Error> | string | null>();
	const [formState, setFormState] = useState<FormState>({
		currentPassword: '',
		newPassword: '',
		confirmNewPassword: '',
	});
	const [errors, setErrors] = useState<Errors>({
		currentPassword: '',
		confirmNewPassword: '',
		newPassword: '',
	});

	const errorMessage = useAuthErrorMessage(formError);
	const handleRecaptcha = useEnforcedRecaptcha(executeRecaptchaAsync);

	const handleValidation = () => {
		const { currentPassword, newPassword, confirmNewPassword } = formState;
		const formErrors: Errors = {
			currentPassword: '',
			confirmNewPassword: '',
			newPassword: '',
		};
		let isValid = true;

		if (
			!(
				newPassword.length >= 10 &&
				/[a-z]/g.test(newPassword) &&
				/[A-Z]+/.test(newPassword) &&
				/[0-9]+/.test(newPassword) &&
				// eslint-disable-next-line no-useless-escape
				/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword)
			)
		) {
			formErrors.newPassword = formatMessage(messages.passwordInvalid);
			isValid = false;
		}

		if (newPassword !== confirmNewPassword) {
			formErrors.confirmNewPassword = formatMessage(messages.passwordsDoNotMatch);
			isValid = false;
		}

		if (validation.isEmpty(currentPassword)) {
			formErrors.currentPassword = formatMessage(messages.requiredField);
			isValid = false;
		}

		if (validation.isEmpty(confirmNewPassword)) {
			formErrors.confirmNewPassword = formatMessage(messages.requiredField);
			isValid = false;
		}

		if (validation.isEmpty(newPassword)) {
			formErrors.newPassword = formatMessage(messages.requiredField);
			isValid = false;
		}

		setFormError(null);
		setErrors(formErrors);
		return isValid;
	};

	const handleSubmit = async ({ currentPassword, confirmNewPassword }: FormState) => {
		if (!handleValidation() || isRecaptchaLoading) return;
		setFormError(null);

		handleRecaptcha((recaptcha) =>
			axios.post(
				endpoints.login(),
				queryString.stringify({
					username: userInfo?.username,
					password: currentPassword,
					new_password: confirmNewPassword,
				}),
				{
					headers: {
						recaptcha,
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			)
		)
			.then((response) => {
				// Backend will return 303 redirect
				// Follow that redirect
				window.location.href = response.request.responseURL;
			})
			.catch((response: Response<Error>) => {
				if (response.data.errorCode === 'A_4') {
					const formErrors: Errors = {
						currentPassword: '',
						confirmNewPassword: '',
					};
					formErrors.currentPassword = formatMessage(messages.passwordIncorrect);
					setErrors(formErrors);
				} else {
					setFormError(response);
				}
			});
	};

	const handleCancel = async () => {
		if (isRecaptchaLoading) return;
		setFormError(null);
		handleRecaptcha((recaptcha) =>
			axios.post(
				endpoints.login(),
				queryString.stringify({
					username: userInfo?.username,
					password: userInfo?.password,
				}),
				{
					headers: {
						recaptcha,
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			)
		)
			.then((response) => {
				// Backend will return 303 redirect
				// Follow that redirect
				window.location.href = response.request.responseURL;
			})
			.catch((response: Response<Error>) => {
				setFormError(response);
			});
	};

	function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		event.persist();
		setFormState((prevState: FormState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	}

	return (
		<UserAccessContainer title={messages.updatePassword}>
			<div className={styles.notificationContainer}>
				<div className={styles.notificationTextContainer}>
					<p>
						<FormattedMessage {...messages.notificationBody} />
					</p>
				</div>
			</div>
			<div className={styles.paragraphContainer}>
				<p className={styles.paragraph}>
					<FormattedMessage
						{...messages.body}
						values={{
							ul: (chunks: any) => <ul>{chunks}</ul>,
							li: (chunks: any) => <li>{chunks}</li>,
						}}
					/>
				</p>
			</div>
			<form
				onSubmit={(event: FormEvent<HTMLFormElement>) => {
					event.preventDefault();
					void handleSubmit({
						currentPassword: formState.currentPassword,
						newPassword: formState.newPassword,
						confirmNewPassword: formState.confirmNewPassword,
					});
				}}
			>
				{errorMessage && (
					<FormMessage className={styles.message} type={FormMessageType.ERROR}>
						{errorMessage}
					</FormMessage>
				)}
				<PasswordInput
					className={styles.input}
					error={errors.currentPassword}
					id="currentPassword"
					label={formatMessage(messages.currentPassword)}
					name="currentPassword"
					onChange={handleInputChange}
					placeholder={formatMessage(messages.currentPassword)}
					value={formState.currentPassword}
				/>
				<PasswordInput
					className={styles.input}
					error={errors.newPassword}
					id="newPassword"
					label={formatMessage(messages.newPassword)}
					name="newPassword"
					onChange={handleInputChange}
					placeholder={formatMessage(messages.newPassword)}
					value={formState.newPassword}
				/>
				<PasswordInput
					className={styles.input}
					error={errors.confirmNewPassword}
					id="confirmNewPassword"
					label={formatMessage(messages.confirmNewPassword)}
					name="confirmNewPassword"
					onChange={handleInputChange}
					placeholder={formatMessage(messages.confirmNewPassword)}
					value={formState.confirmNewPassword}
				/>
				<Button
					withCaptcha
					disabled={isRecaptchaLoading}
					className={styles.button}
					buttonStyle={ButtonStyle.PRIMARY}
					type={ButtonType.SUBMIT}
					text={formatMessage(messages.changePassword)}
				/>
				{enforcement === PasswordChangeEnforcement.SUGGEST && (
					<Button
						text={formatMessage(messages.cancel)}
						className={styles.button}
						buttonStyle={ButtonStyle.SECONDARY}
						type={ButtonType.BUTTON}
						onClick={handleCancel}
					/>
				)}
			</form>
		</UserAccessContainer>
	);
};

export default ChangePassword;
