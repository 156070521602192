export const getMappedLanguage = (lang: string) => {
	switch (lang) {
		case 'en':
			return 'en';
		case 'lt-LT':
			return 'lt';
		case 'ru-RU':
			return 'ru';
		case 'fr-FR':
			return 'fr';
		case 'it-IT':
			return 'it';
		case 'et-EE':
			return 'et';
		case 'ar':
			return 'ar';
		case 'es-ES':
			return 'es-ES';
		case 'uk-UA':
			return 'uk-UA';
		default:
			return 'en';
	}
};

export const getMappedLanguageForSpecificCode = (lang: string) => {
	// rerutn specific language code if language code has multiple versions
	if (lang === 'el' || lang === 'el-GR') return 'el-GR'; // greek
	if (lang === 'de' || lang === 'de-DE') return 'de-DE'; // german
	if (lang === 'et' || lang === 'et-EE') return 'et-EE'; // estonian
	if (lang === 'fr' || lang === 'fr-FR') return 'fr-FR'; // french
	if (lang === 'it' || lang === 'it-IT') return 'it-IT'; // italian
	if (lang === 'ko' || lang === 'ko-KR') return 'ko-KR'; // korean
	if (lang === 'lt' || lang === 'lt-LT') return 'lt-LT'; // lithuanian
	if (lang === 'lv' || lang === 'lv-LV') return 'lv-LV'; // latvian
	if (lang === 'nl' || lang === 'nl-NL') return 'nl-NL'; // dutch
	if (lang === 'pl' || lang === 'pl-PL') return 'pl-PL'; // polish
	if (lang === 'ru' || lang === 'ru-RU') return 'ru-RU'; // russian
	if (lang === 'tr' || lang === 'tr-TR') return 'tr-TR'; // turkish
	if (lang === 'uk' || lang === 'uk-UA') return 'uk-UA'; // ukrainian

	// return default language code
	return lang;
};
