/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Button, { ButtonStyle } from '../../../components/Button/Button';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import styles from './LinkExpired.module.scss';
import { useClientConfig } from '../../../helpers/themeHelpers';
import config from '../../../config';

const messages = defineMessages({
	heading: {
		id: 'linkExpired.heading',
		defaultMessage: 'This link has expired',
	},
	body: {
		id: 'linkExpired.body',
		defaultMessage: 'Please sign up again to receive a new email link.',
	},
	signUp: {
		id: 'base.signup',
		defaultMessage: 'Sign up',
	},
});

const LinkExpired = () => {
	const { formatMessage } = useIntl();
	const history = useHistory();
	const { expiredImage } = useClientConfig();

	return (
		<>
			<UserAccessContainer formImage={expiredImage} title={messages.heading}>
				<div className={styles.paragraphContainer}>
					<p className={styles.paragraph}>
						<FormattedMessage {...messages.body} />
					</p>
				</div>
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.PRIMARY}
					text={formatMessage(messages.signUp)}
					onClick={() => {
						history.push(config.BASE_URL_SIGNUP);
					}}
				/>
			</UserAccessContainer>
			<div id="recaptcha" />
		</>
	);
};

export default LinkExpired;
