/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import classNames from 'classnames';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Context } from '../../store/Provider';
import { SET_LANG } from '../../store/actionTypes';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import styles from './UserAccessContainer.module.scss';
import { useClientConfig } from '../../helpers/themeHelpers';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import appStoreImage from '../../images/appStore.svg';
import playStoreImage from '../../images/playStore.svg';
import config from '../../config';

interface UserAccessContainerType {
	className?: string;
	children: JSX.Element | JSX.Element[];
	hasImageColumn?: boolean;
	showAppLinks?: boolean;
	rightColumnImage?: {
		src: string;
		alt?: string;
		isLottieAnimation?: boolean;
		rightColImageRedirectURL: string | null;
	};
	title?: MessageDescriptor | string;
	formImage?: { src: string; alt?: string };
	formImageClassName?: string;
}

const UserAccessContainer = ({
	children,
	className,
	hasImageColumn = true,
	showAppLinks = false,
	rightColumnImage,
	formImage,
	formImageClassName,
	title,
}: UserAccessContainerType) => {
	const { isMobileClient } = useContext(Context).state;
	const { state, dispatch } = useContext(Context);
	const {
		clientHomeURL,
		title: clientTitle,
		logo,
		rightColImage_1,
		hasLanguagesSwitch,
		supportedLanguages,
		hasAppleStore,
		hasGooglePlayStore,
	} = useClientConfig();

	const colImage = rightColumnImage || rightColImage_1;

	return (
		<div className={classNames(styles.container, className)}>
			{isMobileClient ? (
				<div>
					<img src={logo.src} className={styles.logo} alt={logo.alt} />
				</div>
			) : (
				<div className={classNames(styles.headerContainer, styles[logo.position])}>
					<a className={styles.logoContainer} href={clientHomeURL}>
						<img src={logo.src} className={styles.logo} alt={logo.alt} />
					</a>
				</div>
			)}
			{hasLanguagesSwitch && (
				<LanguageSwitcher
					className={styles.languageSwithcer}
					value={state.lang}
					onLanguageChange={(lang) => dispatch({ type: SET_LANG, payload: lang })}
					supportedLanguages={supportedLanguages}
				/>
			)}

			<div className={classNames(styles.body, { [styles.singleColumn]: !hasImageColumn })}>
				<div className={styles.formContainer}>
					{formImage && (
						<img
							className={classNames(styles.formImage, formImageClassName)}
							src={formImage.src}
							alt={formImage.alt}
						/>
					)}
					{title && (
						<h2 className={styles.title}>
							{typeof title === 'string' ? (
								title
							) : (
								<FormattedMessage
									{...title}
									values={{
										br: <br />,
										strong: (text: string) => (
											<strong className={styles.zeroMargin}>{text}</strong>
										),
										p: (text: string) => (
											<p className={styles.textLeft}>{text}</p>
										),
										// eslint-disable-next-line react/jsx-no-useless-fragment
										client: <>{clientTitle}</>,
									}}
								/>
							)}
						</h2>
					)}
					{children}
				</div>
				{hasImageColumn && colImage && colImage.src !== '' && !isMobileClient && (
					<>
						{colImage.rightColImageRedirectURL ? (
							<div className={styles.imageContainer}>
								<a
									href={colImage.rightColImageRedirectURL}
									target="_blank"
									rel="noreferrer"
								>
									<img
										className={styles.image}
										src={colImage.src}
										alt={colImage.alt}
									/>
								</a>
							</div>
						) : (
							<div className={styles.imageContainer}>
								{colImage.isLottieAnimation ? ( // if development fetch doesn't happen, do not show lottie
									<LottieAnimation animationURL={colImage.src} />
								) : (
									<>
										<img
											className={styles.image}
											src={colImage.src}
											alt={colImage.alt}
										/>
										{showAppLinks && (hasGooglePlayStore || hasAppleStore) && (
											<div className={styles.appStoreLinkContainer}>
												{hasAppleStore && (
													<a
														href={config.APP_STORE_URI}
														className={styles.appStoreLink}
													>
														<img
															className={styles.appStoreImage}
															src={appStoreImage}
															alt={`${clientTitle} on App store`}
														/>
													</a>
												)}
												{hasGooglePlayStore && (
													<a
														href={config.PLAY_STORE_URI}
														className={styles.appStoreLink}
													>
														<img
															className={styles.appStoreImage}
															src={playStoreImage}
															alt={`${clientTitle} on Play store store`}
														/>
													</a>
												)}
											</div>
										)}
									</>
								)}
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default UserAccessContainer;
