import classNames from 'classnames';
import React, { FC, InputHTMLAttributes, useState } from 'react';
import { ReactComponent as EyeIcon } from '../../images/eye.svg';
import FieldError from '../FieldError/FieldError';
import styles from './PasswordInput.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	autoComplete?: string;
	className?: string;
	error?: string;
	id?: string;
	label?: string;
	name?: string;
	pattern?: string;
	placeholder?: string;
	value: string;
}

const PasswordInput: FC<Props> = ({
	autoComplete = 'off',
	className,
	error,
	label,
	name,
	...restProps
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div className={classNames(styles.container, className, { [styles.error]: !!error })}>
			<label className={styles.label} htmlFor={name}>
				{label}
			</label>
			<div className={styles.inputContainer}>
				<input
					type={showPassword ? 'text' : 'password'}
					className={styles.input}
					name={name}
					autoComplete={autoComplete}
					{...restProps}
				/>
				<EyeIcon
					className={classNames(
						styles.passwordIcon,
						{ [styles.active]: showPassword },
						className
					)}
					onClick={toggleShowPassword}
				/>
			</div>
			<FieldError error={error} />
		</div>
	);
};

export default PasswordInput;
