import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import UserAccessContainer from '../../../../components/UserAccessContainer/UserAccessContainer';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import styles from './TwoFAAttemptsUsed.module.scss';

const messages = defineMessages({
	title: {
		id: 'SSOTwoFAAttemptsUsed.title',
		defaultMessage: 'You have used up all authentication attempts',
	},
	description: {
		id: 'SSOTwoFAAttemptsUsed.description',
		defaultMessage: 'You will need to login again. ',
	},
	back: {
		id: 'base.back',
		defaultMessage: 'Back',
	},
});

interface TwoFAAttemptsUsedProps {
	backLink: string;
}

const TwoFAAttemptsUsed = ({ backLink }: TwoFAAttemptsUsedProps) => {
	const { formatMessage } = useIntl();
	return (
		<UserAccessContainer title={messages.title} hasImageColumn={false}>
			<p className={styles.description}>
				<FormattedMessage {...messages.description} />
			</p>
			<Button
				buttonStyle={ButtonStyle.PRIMARY}
				className={styles.button}
				link={backLink}
				text={formatMessage(messages.back)}
				type={ButtonType.ANCHOR_LINK}
			/>
		</UserAccessContainer>
	);
};

export default TwoFAAttemptsUsed;
