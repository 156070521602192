import React, { useCallback } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import classNames from 'classnames';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import endpoints from '../../endpoints';
import config from '../../config';
import Response from '../../interfaces/Response';
import { loadScript } from '../../helpers/functionHelpers';
import useAxios from '../../hooks/useAxios';
import SocialButton from '../../components/SocialButton/SocialButton';
import socialButtonStyles from '../../components/SocialButton/SocialButton.module.scss';

const APPLE_SDK_URL =
	'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

declare global {
	interface Window {
		AppleID: any;
	}
}

interface AppleLoginProps {
	className?: string;
	disabled?: boolean;
	onError: (error?: string) => void;
	buttonMessage: MessageDescriptor;
}

interface AppleIdState {
	state: string;
}

const AppleLogin = ({ className, disabled, onError, buttonMessage }: AppleLoginProps) => {
	const { formatMessage } = useIntl();
	const axios = useAxios();

	const getAppleIdState = useCallback(async () => {
		const response = (await axios.get(endpoints.getAppleIdState())) as Response<AppleIdState>;
		return response.data.state;
	}, [axios]);

	const getTokenAndLogin = async () => {
		if (disabled) return;

		const state = await getAppleIdState();

		loadScript(APPLE_SDK_URL, () => {
			if (window.AppleID) {
				const params = {
					clientId: config.APPLE_CLIENT_ID,
					redirectURI: `${window.location.origin}${config.APPLE_REDIRECT_URI}`,
					responseType: 'code id_token',
					scope: 'email',
					state,
					usePopup: false,
				};

				try {
					window.AppleID.auth.init(params);
					window.AppleID.auth.signIn();
				} catch {
					onError();
				}
			} else {
				onError();
			}
		});
	};

	return (
		<SocialButton
			className={classNames(socialButtonStyles.apple, className)}
			faIcon={faApple}
			onClick={getTokenAndLogin}
			text={formatMessage(buttonMessage, { provider: 'Apple' })}
		/>
	);
};

export default AppleLogin;
