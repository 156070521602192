/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';
import { useClientConfig } from '../../helpers/themeHelpers';
import config from '../../config';

const Protected: FC<PropsWithChildren<{ accessor: keyof ReturnType<typeof useClientConfig> }>> = ({
	accessor,
	children,
}) => {
	const canAccess = !!useClientConfig()[accessor];
	if (!canAccess) return <Redirect to={`${config.BASE_URL_LOGIN}`} />;

	return <>{children}</>;
};

export default Protected;
