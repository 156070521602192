/* eslint-disable import/prefer-default-export */
import { LanguageNames } from './interfaces/Theme';

export const languageNames: LanguageNames = {
	// supported languages inside entire project,
	// if you need to specify languages inside the config public/client-theme-config.json
	// add just first part like 'lt-LT', 'ru-RU' to supportedLanguages.
	// You will find an example inside the config of some client
	en: 'English (EN)',
	'lt-LT': 'Lietuvių (LT)',
	'ru-RU': 'Русский (RU)',
	'fr-FR': 'Français (FR)',
	'it-IT': 'Italiano (IT)',
	'et-EE': 'Eesti (EE)',
	'ko-KR': '한국어 (KR)',
	'zh-CN': '简体中文 (CN)',
	'zh-TW': '繁體中文 (TW)',
	ja: '日本語 (JA)',
	'nl-NL': 'Nederlands (NL)',
	'pt-BR': 'Português (BR)',
	'pt-PT': 'Português (PT)',
	ar: 'العربية (AR)',
	'pl-PL': 'Polski (PL)',
	'lv-LV': 'Latviešu (LV)',
	'es-ES': 'Español (ES)',
	'uk-UA': 'Українська (UA)',
	'el-GR': 'Ελληνικά (GR)',
};
