import { defineMessages } from 'react-intl';

const errorMessages = defineMessages({
	unexpectedError: {
		id: 'error.unexpectedError',
		defaultMessage: 'Unexpected error. Please contact support. {traceId}',
	},
	traceId: {
		id: 'error.traceId',
		defaultMessage: 'Trace ID: {traceId}',
	},
	invalidEmail: {
		id: 'error.invalidEmail',
		defaultMessage: 'Enter a valid email address',
	},
	requiredField: {
		id: 'base.requiredField',
		defaultMessage: 'This field is required',
	},
	acceptTerms: {
		id: 'error.acceptTerms',
		defaultMessage: 'Accept terms to continue',
	},
	twoFAcancelled: {
		id: 'error.2faCancelled',
		defaultMessage: 'In-app authentication cancelled',
	},
	loginCancelled: {
		id: 'error.loginCancelled',
		defaultMessage: 'Login cancelled. Please relogin.',
	},
	A_3: {
		id: 'base.invalid_request',
		defaultMessage: 'Invalid request.',
	},
	A_4: {
		id: 'error.invalid_credentials',
		defaultMessage: 'Incorrect username or password',
	},
	A_8: {
		id: 'error.password_invalid_pattern',
		defaultMessage:
			'Password must contain upper and lower case letters, digits and should be between 8 and 256 characters in length',
	},
	A_9: {
		id: 'error.password_similar_to_name',
		defaultMessage: 'Password is too similar to user name',
	},
	A_10: {
		id: 'error.long_sequences_not_allowed',
		defaultMessage:
			'Password cannot contain 3 or more characters that resemble a sequence or are repeated.',
	},
	A_11: {
		id: 'error.password_recovery_count_limit',
		defaultMessage: 'Too many inquiries to recover password. Please try again later.',
	},
	A_34: {
		id: 'error.password_in_use',
		defaultMessage: 'You are already using this password',
	},
	A_35: {
		id: 'error.email_domain_banned',
		defaultMessage: 'This email domain is blacklisted',
	},
	A_36: {
		id: 'error.password_used_before',
		defaultMessage: 'You have already used this password',
	},
	A_38: {
		id: 'error.user_password_already_set',
		defaultMessage: 'User password already set',
	},
	A_39: {
		id: 'error.user_social_login_disabled',
		defaultMessage: 'Social login is disabled',
	},
	A_40: {
		id: 'error.social_login_disabled',
		defaultMessage: 'Social login is disabled',
	},
	A_71: {
		id: 'error.signup_disabled',
		defaultMessage: 'Signup is disabled',
	},
	A_73: {
		id: 'error.password_change_suggested',
		defaultMessage: 'Password change suggested',
	},
	A_74: {
		id: 'error.password_change_enforced',
		defaultMessage: 'Password change enforced',
	},
	A_75: {
		id: 'error.recaptcha_required',
		defaultMessage: 'Recaptcha is required',
	},
	SS_6: {
		id: 'error.country_is_restricted',
		defaultMessage: 'User country is restricted',
	},
	SS_18: {
		id: 'error.ip_country_is_restricted',
		defaultMessage: 'IP country is restricted',
	},
	incorrectTwoFA: {
		id: 'error.incorrect_two_fa',
		defaultMessage: 'Incorrect 2FA code. Please try again',
	},
	acceptDuoPush: {
		id: 'error.accept_duo_push',
		defaultMessage: 'Please accept duo push',
	},
	acceptPushMe: {
		id: 'error.accept_push_me',
		defaultMessage: 'Approve the request in {client} app',
	},
	userBanned: {
		id: 'error.user_account_banned',
		defaultMessage: 'User account is locked. Please contact administration',
	},
	userTemporarilyBanned: {
		id: 'error.user_acount_temporarily_banned',
		defaultMessage: 'User account is locked until {date}',
	},
	maxOTPReached: {
		id: 'error.maxOTPReached',
		defaultMessage: 'Max OTP has been reached. Please signup again.',
	},
	activeOTPExists: {
		id: 'error.activeOTPExists',
		defaultMessage: 'Active OTP exists. Please wait and try again.',
	},
	PVS_1: {
		id: 'error.phone_verification_expired',
		defaultMessage: 'Phone verification is expired, please contact support.',
	},
	PVS_2: {
		id: 'error.phone_verification_verified',
		defaultMessage: 'Phone verification is already verified',
	},
	PVS_3: {
		id: 'error.phone_verification_failed',
		defaultMessage: 'Phone verification is failed. Please contact support.',
	},
	PVS_4: {
		id: 'error.activeOTPExists',
		defaultMessage: 'Active OTP exists. Please wait and try again.',
	},
	PVS_5: {
		id: 'error.otp_limit_exceeded',
		defaultMessage: 'Maximum limit OTP for confirmation code exceeded',
	},
	PVS_7: {
		id: 'error.otp_incorrect',
		defaultMessage: 'Incorrect otp provided',
	},
	SOPT_6: {
		id: 'error.otp_limit_exceeded',
		defaultMessage: 'Maximum limit OTP for confirmation code exceeded',
	},
	A_67: {
		id: 'error.twofa_limit_reached',
		defaultMessage: '2FA submission limit is reached. You can try again in {Countdown} minutes',
	},
});

export const ErrorMessageCodes = Object.fromEntries(
	Object.keys(errorMessages).map((x: string) => [x, x])
) as Record<keyof typeof errorMessages, keyof typeof errorMessages>;

export const getErrorMessage = (code: keyof typeof errorMessages | string) =>
	errorMessages[code as keyof typeof ErrorMessageCodes] || null;

export default errorMessages;
