enum StorageKey {
	USER_LANG = 'userLang',
	REF_ID = 'ref_id',
	UTM_UUID = 'utm_uuid',
	ARE_COOKIES_ALLOWED = 'isCookiesAllowed_1912',
	OTP_EXPIRATION_TIME = 'otpExpirationTime',
	SIGNUP_TOKEN = 'signupToken',
	SIGNUP_TX_ID = 'signupTxId',
}

export default StorageKey;
