import { useEffect } from 'react';
import config from '../../../config';
import { postForm } from '../../../helpers/functionHelpers';

const LoginResume = () => {
	useEffect(() => {
		postForm(config.LOGIN_RESUME_URL);
	}, []);

	return null;
};

export default LoginResume;
