import React, { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './LabeledField.module.scss';

export enum LabeledFieldStyle {
	Row = 'row',
	Column = 'column',
}

type LabeledFieldProps = {
	label: string | ReactNode;
	className?: string;
	labelClassName?: string;
	contentClassName?: string;
	style?: LabeledFieldStyle;
};

const LabeledField: FC<PropsWithChildren<LabeledFieldProps>> = ({
	label,
	className,
	labelClassName,
	contentClassName,
	children,
	style = LabeledFieldStyle.Column,
	...rest
}) => {
	return (
		<div className={classNames(styles.labeled, styles[style], className)}>
			<label className={labelClassName}>{label}</label>
			<span {...rest} className={contentClassName}>
				{children}
			</span>
		</div>
	);
};

export default LabeledField;
