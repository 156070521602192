import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import FieldError from '../FieldError/FieldError';
import styles from './Input.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	autoComplete?: string;
	className?: string;
	error?: string;
	id?: string;
	label?: string;
	name?: string;
	pattern?: string;
	placeholder?: string;
	type: string;
	value: string;
}

const Input = ({
	autoComplete = 'off',
	className,
	error,
	label,
	name,
	...restProps
}: InputProps) => (
	<div className={classNames(styles.container, className, { [styles.error]: !!error })}>
		<label className={styles.label} htmlFor={name}>
			{label}
		</label>
		<input className={styles.input} name={name} autoComplete={autoComplete} {...restProps} />
		<FieldError error={error} />
	</div>
);

export default Input;
