import { createContext } from 'react';

export enum PhoneVerificatonState {
	Start = 1,
	Complete = 2,
	Success = 3,
}

type CommonProps = {
	state: PhoneVerificatonState;
};

export type StartPhoneVerificationProps = CommonProps & {
	token: string;
	state: PhoneVerificatonState.Start;
};

export type CompletePhoneVerificationProps = CommonProps & {
	token: string;
	txId: string;
	expirationDate: string;
	state: PhoneVerificatonState.Complete;
};

export type PhoneVerificationContextProps =
	| StartPhoneVerificationProps
	| CompletePhoneVerificationProps;

export type PhoneVerificationContext = {
	params: PhoneVerificationContextProps;
	setParams: (value: PhoneVerificationContextProps) => void;
};

export default createContext<PhoneVerificationContext>({
	params: { state: PhoneVerificatonState.Start, token: '' },
	setParams: () => 0,
});
