/* eslint-disable import/no-named-as-default */
import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import axios, { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import PhoneVerificationContext, {
	CompletePhoneVerificationProps,
	PhoneVerificatonState,
} from '../Context/PhoneVerificationContext';
import styles from './CompletePhoneVerification.module.scss';
import CountdownInput from '../../../../components/CountdownInput/CountdownInput';
import useCountdown from '../../../../hooks/useCountdown';
import LabeledField from '../../../../components/LabeledField/LabeledField';
import { RemoteData, RemoteError, RemoteStatus } from '../../../../interfaces/Remote';
import endpoints from '../../../../endpoints';
import Button from '../../../../components/Button/Button';
import useAuthErrorMessage from '../../../../hooks/useAuthErrorMessage';
import FormMessage, { FormMessageType } from '../../../../components/FormMessage/FormMessage';
import config from '../../../../config';
import { Context } from '../../../../store/Provider';
import useRecaptcha from '../../../../hooks/useRecaptcha';

const messages = defineMessages({
	activityId: {
		id: 'phoneVerification.activityId',
		defaultMessage: 'Activity ID',
	},
	enterCode: {
		id: 'phoneVerification.enterCode',
		defaultMessage: 'Enter the code received to your phone to complete verification.',
	},
	code: {
		id: 'phoneVerification.code',
		defaultMessage: 'Authentication code',
	},
	verify: {
		id: 'base.verify',
		defaultMessage: 'Verify',
	},
	enterCodePlaceholder: {
		id: 'phoneVerification.enterCode_placeholder',
		defaultMessage: 'Enter code',
	},
});

export default function () {
	const { formatMessage } = useIntl();
	const { push } = useHistory();
	const { params, setParams } = useContext(PhoneVerificationContext);
	const { state, expirationDate, token, txId } = params as CompletePhoneVerificationProps;
	const { isExpired, formattedTimeLeft } = useCountdown(expirationDate);
	const { isRecaptchaEnabled } = useContext(Context).state;
	const { executeAsync: executeRecaptchaAsync, isLoading: isRecaptchaLoading } = useRecaptcha({});

	const [retryAction, setRetryAction] = useState<
		RemoteData<Omit<CompletePhoneVerificationProps, 'state' | 'token'>>
	>({
		status: RemoteStatus.None,
	});
	const [action, setAction] = useState<RemoteData<void>>({
		status: RemoteStatus.None,
	});
	const retryError = useAuthErrorMessage({ data: retryAction.error } as any)!;
	const actionError = useAuthErrorMessage({ data: action.error } as any)!;

	const [code, setCode] = useState('');

	const onRetry = useCallback(async () => {
		const recaptcha = isRecaptchaEnabled ? await executeRecaptchaAsync() : null;

		setRetryAction({ status: RemoteStatus.InProgress });
		setAction({ status: RemoteStatus.None });

		axios
			.post<{ txId: string; expirationDate: string }>(
				endpoints.startPhoneVerification(),
				{
					confirmationCode: token,
				},
				{
					headers: {
						recaptcha,
					},
				}
			)
			.then(({ data }) => {
				setCode('');
				setRetryAction({ status: RemoteStatus.Done, data });
				setParams({
					state: PhoneVerificatonState.Complete,
					token,
					txId: data.txId,
					expirationDate: /z/i.test(data.expirationDate)
						? data.expirationDate
						: `${data.expirationDate}Z`,
				});
			})
			.catch((e: AxiosError<RemoteError>) => {
				setRetryAction({ status: RemoteStatus.Error, error: e.response?.data });
			});
	}, []);

	const verify = useCallback(async () => {
		const recaptcha = isRecaptchaEnabled ? await executeRecaptchaAsync() : null;

		setAction({ status: RemoteStatus.InProgress });
		axios
			.post(
				endpoints.completePhoneVerification(),
				{
					otp: code,
					txId,
				},
				{
					headers: {
						recaptcha,
					},
				}
			)
			.then(({ data }) => {
				setAction({ status: RemoteStatus.Done, data });
				push(`${config.BASE_URL_VERIFY}/phone/success`);
			})
			.catch((e: AxiosError<RemoteError>) => {
				if (e.response?.data.errorCode === 'PVS_3')
					push(
						`${config.BASE_URL_VERIFY}/phone/error/${e.response?.data.errorCode || ''}`
					);
				setAction({ status: RemoteStatus.Error, error: e.response?.data });
			});
	}, [code, txId]);

	if (state !== PhoneVerificatonState.Complete) return null;

	return (
		<>
			<div className={styles.container}>
				<LabeledField label={formatMessage(messages.enterCode)} />
				<LabeledField label={formatMessage(messages.activityId)}>{txId}</LabeledField>
				<CountdownInput
					label={formatMessage(messages.code)}
					placeholder={formatMessage(messages.enterCodePlaceholder)}
					className={styles.input}
					isCountdownHidden={false}
					isExpired={isExpired}
					pattern="\d*"
					formattedTimeLeft={formattedTimeLeft}
					type="text"
					inputMode="numeric"
					value={code}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						setCode(event.target.value?.trim());
					}}
					handleRetryButtonClick={onRetry}
					isLoading={retryAction.status === RemoteStatus.InProgress || isRecaptchaLoading}
				/>
				{retryAction.status === RemoteStatus.Error && (
					<FormMessage className={styles.message} type={FormMessageType.ERROR}>
						{retryError}
					</FormMessage>
				)}
				{action.status === RemoteStatus.Error && (
					<FormMessage className={styles.message} type={FormMessageType.ERROR}>
						{actionError}
					</FormMessage>
				)}
				<Button
					withCaptcha
					className={styles.button}
					onClick={verify}
					disabled={
						action.status === RemoteStatus.InProgress || !code || isRecaptchaLoading
					}
				>
					{formatMessage(messages.verify)}
				</Button>
			</div>
			<div id="recaptcha" />
		</>
	);
}
