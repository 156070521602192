interface Config {
	AUTH_SERVER_LOGIN_ENDPOINT?: string;
	APPLE_REDIRECT_URI?: string;
	NO_SESSION_REDIRECT_URI?: string;
	MIN_TIME_BETWEEN_REDIRECTS_MS?: number;
	LOGIN_SESSION_CHECK_INTERVAL_MS?: number;
	APP_STORE_URI?: string;
	PLAY_STORE_URI?: string;
	OTP_EXPIRATION_MS?: number;
	OTP_RETRY_COUNT_BEFORE_CALL_ALLOWED?: number;
	OTP_RETRY_COUNT_BEFORE_SKIP_ALLOWED?: number;
	OTP_INCORRECT_CODE_COUNT_ALLOWED?: number;
	PUBLIC_URL?: string;
	BASE_URL?: string;
	BASE_URL_LOGIN?: string;
	BASE_URL_SIGNUP?: string;
	BASE_URL_VERIFY?: string;
	SC_BASE_URL?: string;
	CLIENT_LOGIN_URL?: string;
	OPEN_BUSINESS_ACCOUNT_URL?: string;
	OPEN_BUSINESS_ACCOUNT_SEND_EMAIL_URL?: string;
	CLIENT_NAME?: string;
	COOKIE_REVOKE_URL?: string;
	COOKIE_CONSENT_URL?: string;
	RECAPTCHA_SITEKEY?: string;
	OPEN_BUSINESS_ACCOUNT_SEND_EMAIL_RECAPTCHA_SITEKEY?: string;
	TURNSTILE_SITEKEY?: string;
	HCAPTCHA_SITEKEY?: string;
	GOOGLE_CLIENT_ID?: string;
	FACEBOOK_APP_ID?: string;
	APPLE_CLIENT_ID?: string;
	LOGIN_RESUME_URL?: string;
	MOBILE_SUGGEST_ENABLED?: boolean;
	DEEPLINK_REQUEST_ENABLED?: boolean;
	DEEPLINK_REQUEST_URL?: string;
}

declare global {
	interface Window {
		uiConfig: Config;
		debugMode: boolean;
		ga: any;
		fbq: any;
	}
}

const config = {
	// AUTH_SERVER_LOGIN_ENDPOINT: 'http://auth.local:9999/login',
	AUTH_SERVER_LOGIN_ENDPOINT: '/proxy-login',
	APPLE_REDIRECT_URI: '/callbacks/apple-id',
	NO_SESSION_REDIRECT_URI: '/proxy-session',
	MIN_TIME_BETWEEN_REDIRECTS_MS: 2000,
	LOGIN_SESSION_CHECK_INTERVAL_MS: 60000,
	OTP_EXPIRATION_MS: 1 * 1000 * 60,
	OTP_RETRY_COUNT_BEFORE_CALL_ALLOWED: 3,
	OTP_RETRY_COUNT_BEFORE_SKIP_ALLOWED: 5,
	OTP_INCORRECT_CODE_COUNT_ALLOWED: 3,
	PUBLIC_URL: '/login',
	BASE_URL: 'http://localhost:3002',
	BASE_URL_LOGIN: '/login',
	BASE_URL_SIGNUP: '/signup',
	BASE_URL_VERIFY: '/verify',
	APP_STORE_URI: 'https://apps.apple.com/lt/app/bankera/id1555256201',
	PLAY_STORE_URI: 'https://play.google.com/store/apps/details?id=com.bankera.wallet',
	SC_BASE_URL: 'https://dev.spectrocoin.com',
	OPEN_BUSINESS_ACCOUNT_URL: '/open-business-account',
	OPEN_PERSONAL_ACCOUNT_URL: '/signup',
	OPEN_BUSINESS_ACCOUNT_SEND_EMAIL_URL: 'https://auth-test.spectrofinance.lt/sendemail',
	LOGIN_RECAPTCHA_ENABLED: true,
	MOBILE_RECAPTCHA_ENABLED: true,
	RECAPTCHA_DISABLED_CLIENT_LIST: '',
	REFERRAL_COOKIE_NAME: 'REFERRAL_DETAILS',
	REFERRAL_COOKIE_EXPIRATION_PERIOD_IN_DAYS: 14,
	CLIENT_NAME: 'SPECTROCOIN', // change this to change theme locally
	CLIENT_LOGIN_URL: 'https://dev.spectrocoin.com/login',
	COOKIE_REVOKE_URL: 'https://spectrocoin.com/consents/cookie',
	COOKIE_CONSENT_URL: 'https://spectrocoin.com/consents/cookie/revoke',
	DEEPLINK_REQUEST_URL: '/deeplink?page=login',
	DEEPLINK_REQUEST_ENABLED: false,
	RECAPTCHA_SITEKEY: '6LdR_cMZAAAAAF8v_niyvKjRDhlEHw4dJaQ9TIpE',
	OPEN_BUSINESS_ACCOUNT_SEND_EMAIL_RECAPTCHA_SITEKEY: '6LeiHL8UAAAAAF84__kZu6okRd1bM0HUgX7wKMCZ',
	TURNSTILE_SITEKEY: '0x4AAAAAAAzb1Qm5eWZIqe_V',
	HCAPTCHA_SITEKEY: '783801d8-c834-4ab2-8892-5b845260b804',
	GOOGLE_CLIENT_ID: '233506644531-lpd1k3jhp3vf33osfmbk7o81c3p6gej1.apps.googleusercontent.com',
	FACEBOOK_APP_ID: '1106902829449316',
	APPLE_CLIENT_ID: 'bnk-auth-test.spectrofinance.lt',
	LOGIN_RESUME_URL: 'dev.spectrocoin.com',
	MOBILE_SUGGEST_ENABLED: true,
	MOBILE_SIGNUP_ALMOST_THERE_DEEP_LINK: 'https://dev.spectrocoin.com/app-login-plain-redirect',
};

const reactiveConfig = new Proxy(config, {
	get(target, name) {
		if (window.uiConfig && name in window.uiConfig)
			return window.uiConfig[name as keyof typeof window.uiConfig];
		return target[name as keyof typeof target];
	},
});

export default reactiveConfig;
