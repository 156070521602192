import { useIntl } from 'react-intl';
import React, { FC } from 'react';
import TwoFAType from '../enums/TwoFAType';
import Response from '../interfaces/Response';
import useUnexpectedErrorMessage from './useUnexpectedErrorMessage';
import errorMessages, { ErrorMessageCodes, getErrorMessage } from '../messages/errorMessages';
import { useClientConfig } from '../helpers/themeHelpers';
import { RemoteError } from '../interfaces/Remote';
import { useCountdown } from './useCountdown';

enum MessageParameterKey {
	Username = 'username',
	ConfirmationToken = 'confirmationToken',
	Email = 'email',
}

const Countdown: FC<{ endDate: string }> = ({ endDate }) => {
	const { formattedTimeLeft } = useCountdown(endDate);
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{formattedTimeLeft}</>;
};

const useAuthErrorMessage = (response?: Response<RemoteError | undefined> | string | null) => {
	const { formatMessage } = useIntl();
	const { getUnexpectedErrorMessage } = useUnexpectedErrorMessage();
	const { title } = useClientConfig();

	if (typeof response === 'undefined' || response === null) return undefined;
	if (typeof response === 'string') return response;

	if (response?.data) {
		const { data } = response;
		const { errorCode, error, key, messageParameters, errorParameters, traceId } = data;

		if (error === '2fa_required' && data['2fa_type'] === TwoFAType.DUO_PUSH) {
			return formatMessage(errorMessages.acceptDuoPush);
		}

		if (error === '2fa_required' && data['2fa_type'] === TwoFAType.PUSH_ME) {
			return formatMessage(errorMessages.acceptPushMe, { client: title });
		}

		if (error === '2fa_incorrect') {
			return formatMessage(errorMessages.incorrectTwoFA);
		}

		if (error === 'user_banned' || key === 'account_is_locked') {
			return formatMessage(errorMessages.userBanned);
		}

		if (errorCode === 'GEN_3') {
			const errorKey = errorParameters ? errorParameters[0].key : null;
			switch (errorKey) {
				case MessageParameterKey.Email:
				case MessageParameterKey.Username:
					return formatMessage(errorMessages.invalidEmail);
				default:
					return formatMessage(errorMessages.A_3);
			}
		}

		if (errorCode === 'A_3') {
			const messageKey = messageParameters ? messageParameters[0].key : null;
			switch (messageKey) {
				case MessageParameterKey.Email:
					return formatMessage(errorMessages.invalidEmail);
				default:
					return formatMessage(errorMessages.A_3);
			}
		}

		if (errorCode === 'SS_2') {
			return formatMessage(errorMessages.A_35);
		}

		if (errorCode === 'SS_3') {
			return formatMessage(errorMessages.A_8);
		}

		if (errorCode === 'SS_4') {
			return formatMessage(errorMessages.A_9);
		}

		if (errorCode === 'SS_5') {
			return formatMessage(errorMessages.A_10);
		}

		if (error === 'user_temporarily_banned') {
			const bannedUntil = data.bannedUntil!;
			const bannedUntilUtcString =
				bannedUntil.split('')[bannedUntil.split('').length - 1] === 'Z' // Currently backend misses the Z which is required by ISO 8601 if time is in UTC.
					? bannedUntil
					: `${bannedUntil}Z`; // Add it if it's missing so date is parsed as UTC
			const localizedBannedUntil = new Date(bannedUntilUtcString).toLocaleString(
				navigator.language || 'lt-LT'
			);
			return formatMessage(errorMessages.userTemporarilyBanned, {
				date: localizedBannedUntil,
			});
		}

		const endDate = messageParameters?.find((x) => x.key === 'rejected_until')?.value;
		if (errorCode === ErrorMessageCodes.A_67 && endDate) {
			return formatMessage(errorMessages.A_67, {
				Countdown: <Countdown endDate={`${endDate}z`} />,
			});
		}

		const errorMessage = getErrorMessage(errorCode!);
		return errorMessage ? formatMessage(errorMessage) : getUnexpectedErrorMessage(traceId);
	}
	return getUnexpectedErrorMessage();
};

export default useAuthErrorMessage;
