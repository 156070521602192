import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import classNames from 'classnames';
import styles from './SocialButton.module.scss';

interface SocialButtonProps {
	className?: string;
	disabled?: boolean;
	icon?: string;
	faIcon?: IconDefinition;
	faIconColor?: string;
	onClick: () => void;
	text: string | null;
}

const SocialButton = ({
	className,
	icon,
	faIcon,
	onClick,
	text,
	faIconColor = '#000000',
	...attributes
}: SocialButtonProps) => (
	<button
		className={classNames(styles.button, className)}
		onClick={onClick}
		type="button"
		{...attributes}
	>
		{faIcon ? (
			<FontAwesomeIcon
				icon={faIcon as IconDefinition}
				size="1x"
				className={styles.icon}
				color={faIconColor}
			/>
		) : (
			<img src={icon} alt="" className={styles.iconImage} />
		)}
		<span className={styles.text}>{text}</span>
	</button>
);

export default SocialButton;
