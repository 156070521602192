/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styles from './SuccessPhoneVerification.module.scss';
import UserAccessContainer from '../../../../components/UserAccessContainer/UserAccessContainer';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import { useClientConfig } from '../../../../helpers/themeHelpers';
import successImg from './success.svg';

const messages = defineMessages({
	body: {
		id: 'verifyPhone.success.body',
		defaultMessage: `Your phone number has been verified!`,
	},
	close: {
		id: 'base.close',
		defaultMessage: 'Close',
	},
});

const CompletePhoneVerification = () => {
	const { formatMessage, locale } = useIntl();
	const { verificationsUrl } = useClientConfig();

	return (
		<UserAccessContainer
			formImage={{ src: successImg, alt: 'Success' }}
			formImageClassName={styles.image}
			hasImageColumn={false}
		>
			<>
				<div className={styles.paragraphContainer}>
					<p className={styles.paragraph}>
						<FormattedMessage {...messages.body} />
					</p>
				</div>
				{verificationsUrl && (
					<Button
						type={ButtonType.ANCHOR_LINK}
						className={styles.button}
						buttonStyle={ButtonStyle.PRIMARY}
						text={formatMessage(messages.close)}
						link={verificationsUrl.replace('${lang}', locale)}
					/>
				)}
			</>
		</UserAccessContainer>
	);
};

export default CompletePhoneVerification;
