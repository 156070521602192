import React from 'react';
import classNames from 'classnames';
import styles from './FieldError.module.scss';

interface FieldErrorProps {
	error?: string;
	className?: string;
}

const FieldError = ({ error, className }: FieldErrorProps) =>
	error ? <span className={classNames(styles.error, className)}>{error}</span> : null;

export default FieldError;
