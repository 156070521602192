const isEmpty = (value: string) => typeof value === 'undefined' || !String(value).length;

const isNotValid2FACodeFormat = (code: string) => {
	const trimmedCode = code.trim();
	return Number.isNaN(parseInt(trimmedCode, 10)) || trimmedCode.length !== 6;
};

export default {
	isEmpty,
	isNotValid2FACodeFormat,
};
