import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SET_CAPTCHA_TYPE } from '../store/actionTypes';
import { Context } from '../store/Provider';
import * as endpoints from '../endpoints';
import useAxios from './useAxios';
import config from '../config';

// Usage example:
// if (!(await manualSessionCheck())) return;

const useSessionCheck = () => {
	const axios = useAxios();
	const history = useHistory();
	const { dispatch } = useContext(Context);

	const check = () => {
		return axios
			.get(endpoints.sessionCheck())
			.then(({ data }) => {
				dispatch({
					type: SET_CAPTCHA_TYPE,
					payload: data.captchaType,
				});
				if (data.sessionExists === true && data.loggedIn === true) {
					window.location.assign(config.NO_SESSION_REDIRECT_URI);
					return false;
				}
				if (data.sessionExists === true) {
					return true;
				}
				throw new Error('Session not valid or endpoint failed');
			})
			.catch((e) => {
				console.log('Failed at session check', e);
				console.error(e);
				history.push(`${config.BASE_URL_LOGIN}/session-expired`);
				return false;
			});
	};
	return {
		check,
	};
};

export default useSessionCheck;
