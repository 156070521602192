import React, { useEffect, useState, useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Button from '../../components/Button/Button';
import UserAccessContainer from '../../components/UserAccessContainer/UserAccessContainer';
import FormMessage, { FormMessageType } from '../../components/FormMessage/FormMessage';
import config from '../../config';
import styles from './SessionExpired.module.scss';

const messages = defineMessages({
	heading: {
		id: 'sessionExpired.heading',
		defaultMessage: 'Session Expired',
	},
	body: {
		id: 'sessionExpired.body',
		defaultMessage: 'Due to security reasons you will need to try again.',
	},
	retry: {
		id: 'sessionExpired.retry',
		defaultMessage: `Go to login ({secondsUntilAutomaticReload}s)`,
	},
});

const SessionExpired = () => {
	const [secondsLeft, setSecondsLeft] = useState(10);
	const interval = useRef<ReturnType<typeof setInterval>>();

	const handleReload = () => {
		window.location.assign(config.NO_SESSION_REDIRECT_URI);
	};

	useEffect(() => {
		interval.current = setInterval(() => {
			setSecondsLeft((t) => (t === 0 ? 0 : t - 1));
		}, 1000);
		return () => {
			window.clearInterval(interval.current!);
		};
	}, []);

	useEffect(() => {
		if (secondsLeft <= 0) {
			window.location.assign(config.NO_SESSION_REDIRECT_URI);
		}
	}, [secondsLeft]);

	return (
		<UserAccessContainer title={messages.heading} hasImageColumn={false}>
			<>
				<FormMessage type={FormMessageType.ERROR}>
					<FormattedMessage {...messages.body} />
				</FormMessage>
				<Button onClick={handleReload} className={styles.button}>
					<FormattedMessage
						{...messages.retry}
						values={{ secondsUntilAutomaticReload: secondsLeft }}
					/>
				</Button>
			</>
		</UserAccessContainer>
	);
};

export default SessionExpired;
