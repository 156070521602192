/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-template-curly-in-string */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Context } from '../../store/Provider';
import useAxios from '../../hooks/useAxios';
import useUnexpectedErrorMessage from '../../hooks/useUnexpectedErrorMessage';
import endpoints from '../../endpoints';
import UserAccessContainer from '../UserAccessContainer/UserAccessContainer';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import Select from '../Select/Select';
import SelectOption from '../../interfaces/SelectOption';
import Error from '../../interfaces/Error';
import Response from '../../interfaces/Response';
import countries from '../../helpers/countryHelper';
import Checkbox from '../Checkbox/Checkbox';
import styles from './CountrySelect.module.scss';
import {
	getPrivacyPolicyURLByClient,
	getTermsAndConditionsURLByClient,
	useClientConfig,
} from '../../helpers/themeHelpers';

const messages = defineMessages({
	selectCountry: {
		id: 'base.selectCountry',
		defaultMessage: 'Select Country',
	},
	next: {
		id: 'base.next',
		defaultMessage: 'Next',
	},
	checkboxLabel: {
		id: 'signup.checkboxLabel',
		defaultMessage:
			'I have read and accept <termsAndConditionsLink>Terms & Conditions</termsAndConditionsLink>, <privacyPolicyLink>Privacy Policy</privacyPolicyLink>. {br}<span>(services provided by {provider})</span>',
	},
});

interface RestrictedCountries {
	restrictedCountries: string[];
	userCountry: string;
}

interface CountryOptions {
	selectCountries: SelectOption[];
	userCountry: string;
}

interface Contract {
	type: string;
	urlPathFormat: string;
	providedBy: string | null;
}

interface FormState {
	username: string;
	password: string;
}

interface CountrySelectProps {
	selectedCountry: string;
	setSelectedCountry: React.Dispatch<React.SetStateAction<string>>;
	acceptPrivacyPolicyAndTerms: boolean;
	setAcceptPrivacyPolicyAndTerms: React.Dispatch<React.SetStateAction<boolean>>;
	handleLogin: ({ username, password }: FormState, isSocialSignIn?: boolean) => Promise<void>;
	setFormError: React.Dispatch<React.SetStateAction<string | Response<Error> | null | undefined>>;
}

const CountrySelect = ({
	selectedCountry,
	setSelectedCountry,
	acceptPrivacyPolicyAndTerms,
	setAcceptPrivacyPolicyAndTerms,
	handleLogin,
	setFormError,
}: CountrySelectProps) => {
	const axios = useAxios();
	const { formatMessage, locale } = useIntl();
	const { getUnexpectedErrorMessage } = useUnexpectedErrorMessage();
	const { isMobileClient, lang } = useContext(Context).state;
	const { title, domainName, termsAndConditions, privacyPolicy, hasCountrySelectOnSignup } =
		useClientConfig();

	const [countryOptions, setCountryOptions] = useState<SelectOption[]>([]);
	const [provider, setProvider] = useState();
	const [termsAndConditionsPath, setTermsAndConditionsPath] = useState(termsAndConditions);
	const [privacyPolicyPath, setPrivacyPolicyPath] = useState(privacyPolicy);

	const schemeAttribute = isMobileClient ? title.toLowerCase() : 'https';
	const linkProps = !isMobileClient ? { rel: 'noreferrer noopener', target: '_blank' } : {};

	// if there is a country selection, path is coming from the endpoint
	// see useEffect down below
	const privacyPolicyURL = hasCountrySelectOnSignup
		? `${schemeAttribute}://${domainName}/${privacyPolicyPath}`
		: getPrivacyPolicyURLByClient(privacyPolicy, lang);

	const termsAndConditionsURL = hasCountrySelectOnSignup
		? `${schemeAttribute}://${domainName}/${termsAndConditionsPath}`
		: getTermsAndConditionsURLByClient(termsAndConditions, lang);

	const getCountryOptions = useCallback(() => {
		return new Promise<CountryOptions>((resolve, reject) => {
			axios
				.get(endpoints.restrictedCountries())
				.then(({ data }: Response<RestrictedCountries>) => {
					const { userCountry } = data;
					const options = Object.keys(countries.getAlpha3Codes())
						.map((code) => ({
							value: code,
							label: countries.getName(code, 'en'),
						}))
						.sort((a, b) => {
							if (a.label < b.label) return -1;
							if (a.label > b.label) return 1;
							return 0;
						});

					resolve({ selectCountries: options, userCountry });
				})
				.catch((error) => {
					reject(error);
				});
		});
	}, [axios]);

	// useEffect(() => {}, [selectedCountry]);

	useEffect(() => {
		if (!hasCountrySelectOnSignup) return;

		getCountryOptions()
			.then(({ selectCountries, userCountry }: any) => {
				setCountryOptions(selectCountries);
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const selectedCountry =
					selectCountries.find((option: SelectOption) => option.value === userCountry)
						?.value || selectCountries[0].value;
				setSelectedCountry(selectedCountry);
			})
			.catch((e: Response<Error>) => {
				const traceId = e?.data?.traceId;
				setFormError(getUnexpectedErrorMessage(traceId));
			});
	}, [getCountryOptions, setSelectedCountry]);

	useEffect(() => {
		void (async () => {
			if (!selectedCountry && !hasCountrySelectOnSignup) return;
			await axios
				.get(endpoints.getSignUpContracts(selectedCountry))
				.then((response) => {
					const responseByContractType = response.data.reduce(
						(accumulator: { [type: string]: Contract }, contract: Contract) => {
							accumulator[contract.type] = contract;
							return accumulator;
						},
						{}
					);
					setTermsAndConditionsPath(
						responseByContractType.GENERAL_TERMS_AND_CONDITIONS.urlPathFormat.replace(
							'/${lng}',
							locale
						)
					);
					setPrivacyPolicyPath(
						responseByContractType.PRIVACY_POLICY.urlPathFormat.replace(
							'/${lng}',
							locale
						)
					);
					setProvider(responseByContractType.GENERAL_TERMS_AND_CONDITIONS.providedBy!);
				})
				.catch((e: Response<Error>) => {
					const traceId = e?.data?.traceId;
					setFormError(getUnexpectedErrorMessage(traceId));
				});
		})();
	}, [axios, selectedCountry, formatMessage, locale, setFormError]);

	return (
		<UserAccessContainer title={messages.selectCountry} hasImageColumn>
			<Select
				className={styles.select}
				disabled={countryOptions.length === 0}
				id="country"
				isSearchable
				name="country"
				onChangeEvent={(e) => setSelectedCountry(e.target.value)}
				options={countryOptions}
				value={selectedCountry}
			/>
			<Checkbox
				checked={acceptPrivacyPolicyAndTerms}
				className={styles.checkbox}
				labelClassName={styles.checkboxLabel}
				onChange={({ target: { checked } }) => setAcceptPrivacyPolicyAndTerms(checked)}
				error=""
			>
				<FormattedMessage
					{...messages.checkboxLabel}
					values={{
						br: <br />,
						span: (text: string) => <span className={styles.span}>{text}</span>,
						privacyPolicyLink: (text: string) => (
							<a className={styles.policyLink} href={privacyPolicyURL} {...linkProps}>
								{text}
							</a>
						),
						termsAndConditionsLink: (text: string) => (
							<a
								className={styles.policyLink}
								href={termsAndConditionsURL}
								{...linkProps}
							>
								{text}
							</a>
						),
						provider,
					}}
				/>
			</Checkbox>
			<Button
				className={styles.button}
				type={ButtonType.SUBMIT}
				buttonStyle={ButtonStyle.PRIMARY}
				text={formatMessage(messages.next)}
				disabled={!acceptPrivacyPolicyAndTerms}
				onClick={() => {
					void handleLogin(
						{
							username: '',
							password: '',
						},
						true
					);
				}}
			/>
		</UserAccessContainer>
	);
};

export default CountrySelect;
