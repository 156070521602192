import Cookies from 'js-cookie';

export function isLiveEnvironment() {
	if (process.env.NODE_ENV === 'production') {
		const environments = ['local', 'test', 'dev', 'pp', 'tst', 'uat'];
		return !environments.some((value) => window.location.host.includes(value));
	}
	return false;
}

export function isSentryEnabled() {
	if (process.env.NODE_ENV === 'production') {
		const environments = ['local', 'test', 'dev', 'tst', 'uat'];
		return !environments.some((value) => window.location.host.includes(value));
	}
	return false;
}

/**
 * @param {string} userEmail
 * @param {string} userName
 * @param {string} userSurname
 * @param {string} livechatKey
 */

/* eslint-disable */

/**
 * @param {string} userEmail
 * @param {string} userName
 * @param {string} userSurname
 * @param {boolean} includeGA
 * @param {boolean} isHotjarTrackingEnabled
 * @param {string} hotjarId
 * @param {string} twitterPixelId
 * @param {string} facebookPixelId
 * @param {string} livechatKey
 */

export default (
	userEmail,
	userName,
	userSurname,
	includeGA,
	isHotjarTrackingEnabled,
	hotjarId,
	twitterPixelId,
	facebookPixelId,
	livechatKey
) => {
	loadPreferenceCookieScripts(userEmail, userName, userSurname, livechatKey);
	loadMarketingCookieScripts(includeGA, facebookPixelId, twitterPixelId);
	loadStatisticalCookieScripts(isHotjarTrackingEnabled, hotjarId);
};

/**
 * @param {string} userReferralCode
 * @param {string} utmTracking
 * @param {string} googleAnalyticsId
 * @param {function} callback
 */
export function getGoogleAnalyticsScript(
	userReferralCode,
	utmTracking,
	googleAnalyticsId,
	callback
) {
	/* eslint-disable */
	(function (i, s, o, g, r, a, m) {
		i.GoogleAnalyticsObject = r;
		(i[r] =
			i[r] ||
			function () {
				(i[r].q = i[r].q || []).push(arguments);
			}),
			(i[r].l = 1 * new Date());
		(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
		a.async = 1;
		a.src = g;
		m.parentNode.insertBefore(a, m);
	})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
	/* eslint-enable */
	if (typeof callback === 'function') callback();
}

/**
 * @param {boolean} includeGA
 * @param {string} facebookPixelId
 */
export function getFacebookPixelScript(includeFBPixel, facebookPixelId) {
	if (includeFBPixel) {
		/* eslint-disable */
		!(function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
		/* eslint-enable */
		window.fbq('consent', 'grant');
		window.fbq('init', facebookPixelId);
		window.fbq('track', 'PageView');
	}
}

/**
 * @param {object} userObject
 * @param {string} siftScienceKey
 */
export function getSiftScienceScript(userEmail, siftScienceKey) {
	const userId = userEmail || '';
	const sessionId = Cookies.get('uaid');

	// eslint-disable-next-line no-underscore-dangle
	const sift = window._sift || [];
	sift.push(['_setAccount', siftScienceKey]);
	sift.push(['_setUserId', userId]);
	sift.push(['_setSessionId', sessionId]);
	sift.push(['_trackPageview']);
	(function () {
		function ls() {
			const e = document.createElement('script');
			e.type = 'text/javascript';
			e.async = true;
			e.src = `${
				document.location.protocol === 'https:' ? 'https://' : 'http://'
			}cdn.siftscience.com/s.js`;
			const s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(e, s);
		}

		ls();
	})();
}

/**
 * @param {boolean} isHotjarTrackingEnabled
 * @param {string} hotjarId
 */
export function getHotjarScript(isHotjarTrackingEnabled, hotjarId) {
	if (isHotjarTrackingEnabled) {
		/* eslint-disable */
		(function (h, o, t, j, a, r) {
			h.hj =
				h.hj ||
				function () {
					(h.hj.q = h.hj.q || []).push(arguments);
				};
			h._hjSettings = {
				hjid: hotjarId,
				hjsv: 6,
			};
			a = o.getElementsByTagName('head')[0];
			r = o.createElement('script');
			r.async = 1;
			r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
			a.appendChild(r);
		})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
		/* eslint-enable */
	}
}

/**
 * @param {string} twitterPixelId
 */
export function getTwitterScript(twitterPixelId) {
	/* eslint-disable */
	!(function (e, t, n, s, u, a) {
		e.twq ||
			((s = e.twq =
				function () {
					s.exe ? s.exe(...arguments) : s.queue.push(arguments);
				}),
			(s.version = '1.1'),
			(s.queue = []),
			(u = t.createElement(n)),
			(u.async = !0),
			(u.src = '//static.ads-twitter.com/uwt.js'),
			(a = t.getElementsByTagName(n)[0]),
			a.parentNode.insertBefore(u, a));
	})(window, document, 'script');
	/* eslint-enable */
	// Insert Twitter Pixel ID and Standard Event data below
	window.twq('init', twitterPixelId);
	window.twq('track', 'PageView');
}

export function getAdformScript() {
	// Click tracker script
	const { head } = document;
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://a1.adform.net/serving/scripts/trackpoint/';
	head.appendChild(script);

	// Page tracker script
	const s = document.createElement('script');
	s.type = 'text/javascript';
	s.async = true;
	s.src = 'https://a1.adform.net/serving/scripts/trackpoint/async/';
	const x = document.getElementsByTagName('script')[0];
	x.parentNode.insertBefore(s, x);
}

/**
 * @param {string} userEmail
 * @param {string} userName
 * @param {string} userSurname
 * @param {string} livechatKey
 */

export function getLivechatScript(userEmail, userName, userSurname, livechatKey) {
	const isSmallScreen = window.innerHeight < 560 || window.innerWidth < 560;
	if (livechatKey && !isSmallScreen) {
		setTimeout(function () {
			// eslint-disable-next-line no-underscore-dangle
			window.__lc = window.__lc || {};
			// eslint-disable-next-line no-underscore-dangle
			window.__lc.license = livechatKey;

			if (userEmail) {
				// eslint-disable-next-line no-underscore-dangle
				window.__lc.visitor = {
					name: `${userName} ${userSurname}`,
					email: userEmail,
				};
			}
			(function () {
				const lc = document.createElement('script');
				lc.type = 'text/javascript';
				lc.async = true;
				lc.src = `${
					document.location.protocol === 'https:' ? 'https://' : 'http://'
				}cdn.livechatinc.com/tracking.js`;
				const s = document.getElementsByTagName('script')[0];
				s.parentNode.insertBefore(lc, s);
			})();
		}, 2000);
	}
}

/**
 * @param {string} userEmail
 * @param {string} siftScienceKey
 */

export function loadNecessaryCookieScripts(userEmail, siftScienceKey) {
	// eslint-disable-next-line
	if (window.debugMode) console.log('Loading necessary cookies');
	if (isLiveEnvironment()) {
		getSiftScienceScript(userEmail, siftScienceKey);
	}
}

/**
 * @param {string} userEmail
 * @param {string} userName
 * @param {string} userSurname
 * @param {string} livechatKey
 */

export function loadPreferenceCookieScripts(
	userEmail,
	userName,
	userSurname,
	livechatKey,
	isMobileClient
) {
	// eslint-disable-next-line
	if (window.debugMode) console.log('Loading preference cookies');
	getLivechatScript(userEmail, userName, userSurname, livechatKey, isMobileClient);
}

/**
 * @param {string} userReferralCode
 * @param {string} utmTracking
 * @param {boolean} isHotjarTrackingEnabled
 * @param {string} hotjarId
 * @param {string} googleAnalyticsId
 */

export function loadStatisticalCookieScripts(isHotjarTrackingEnabled, hotjarId) {
	// eslint-disable-next-line
	if (window.debugMode) console.log('Loading statistical cookies');
	if (isLiveEnvironment()) {
		getHotjarScript(isHotjarTrackingEnabled, hotjarId);
	}
}

/**
 * @param {boolean} includeGA
 * @param {boolean} includeFBPixel
 * @param {string} facebookPixelId
 * @param {string} twitterPixelId
 */

export function loadMarketingCookieScripts(
	includeGA,
	includeFBPixel,
	facebookPixelId,
	twitterPixelId
) {
	// eslint-disable-next-line
	if (window.debugMode) console.log('Loading marketing cookies');
	if (isLiveEnvironment()) {
		getFacebookPixelScript(includeFBPixel, facebookPixelId);
		getTwitterScript(twitterPixelId);
		getAdformScript();
	}
}

/* eslint-enable */
