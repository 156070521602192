enum TwoFAType {
	DUO_PUSH = 'DUO_PUSH',
	EMAIL = 'EMAIL',
	GOOGLE = 'GOOGLE_AUTH',
	PUSH_ME = 'PUSH_ME',
	SMS = 'SMS',
	TELEGRAM = 'TELEGRAM',
}

export default TwoFAType;
