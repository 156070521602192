import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import axios from 'axios';

export const getGeoLocationCountry = () => {
	return axios
		.get('/my-ip-country')
		.then((resp) => (resp.data.country ? resp.data.country : 'us'))
		.catch(() => 'us');
};

countries.registerLocale(enLocale);

export default countries;
