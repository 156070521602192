/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, PropsWithChildren } from 'react';
import queryString from 'query-string';
import useSessionCheck from '../../hooks/useSessionCheck';
import FormMessage, { FormMessageType } from '../FormMessage/FormMessage';
import Spinner from '../Spinner/Spinner';

const SessionRequired: FC<PropsWithChildren<{ flow: string; disabled?: boolean }>> = ({
	flow,
	disabled,
	children,
}) => {
	const { flow: queryFlow } = queryString.parse(window.location.search);
	const { isSessionCheckPending, sessionCheckError } = useSessionCheck({
		// in some cases user can come without a session into an url like /login?flow=signup
		// to preserve original destination override route flow param
		flowParam: queryFlow ? `${queryFlow}` : flow,
		disabled,
	});

	if (sessionCheckError) {
		return (
			<div className="globalErrorContainer">
				<FormMessage type={FormMessageType.ERROR}>
					{sessionCheckError as string}
				</FormMessage>
			</div>
		);
	}

	if (isSessionCheckPending) return <Spinner isFullScreen />;

	return <>{children}</>;
};

export default SessionRequired;
