import React, { useState } from 'react';
import hCaptcha from '../../images/hCaptcha.svg';
import styles from './HCaptchaBadge.module.scss';

const HCaptchaBadge = () => {
	const [isPopupVisible, setIsPopupVisible] = useState(false);

	const showPopup = () => {
		setIsPopupVisible(true);
	};

	const hidePopup = () => {
		setIsPopupVisible(false);
	};

	return (
		<div onMouseEnter={showPopup} onMouseLeave={hidePopup} onTouchStart={showPopup}>
			<div className={styles.hcaptchaOverlayBadge}>
				<img className={styles.logo} src={hCaptcha} alt="Protected by hCaptcha" />
			</div>
			{isPopupVisible && (
				<div className={styles.hcaptchaOverlayPopup}>
					<span>This site is protected by hCaptcha and its </span>
					<span>
						<a href="https://www.hcaptcha.com/privacy">Privacy Policy</a> and{' '}
					</span>
					<span>
						<a href="https://www.hcaptcha.com/terms">Terms of Service</a> apply.
					</span>
				</div>
			)}
		</div>
	);
};

export default HCaptchaBadge;
