/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Button, { ButtonStyle } from '../../../components/Button/Button';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import styles from './ConfirmationNotReceived.module.scss';
import { useClientConfig } from '../../../helpers/themeHelpers';
import config from '../../../config';

const messages = defineMessages({
	heading: {
		id: 'confirmationNotReceived.heading',
		defaultMessage: 'I have not received the email',
	},
	body: {
		id: 'confirmationNotReceived.body',
		defaultMessage: `<p>We have sent an email to {email}. Check if this email address is spelled correctly. If not, re-enter your email address using the button below.</p>
         <p>If your email has still not arrived:</p>
         <p>1. Wait 10 more minutes. It may take some time for the email to reach your inbox, depending on the service provider.</p>
         <p>3. Check your Spam and Promotions folders.</p>
         <p>4. If none of that works, contact us at {supportEmail} from your registered email address and we will solve the issue.</p>`,
	},
	retry: {
		id: 'base.retry',
		defaultMessage: 'Retry',
	},
});

const ConfirmationNotReceived = () => {
	const { supportEmail, emailConfirmationImage } = useClientConfig();
	const { formatMessage } = useIntl();
	const history = useHistory();
	const { email } = queryString.parse(window.location.search);

	return (
		<UserAccessContainer title={messages.heading} formImage={emailConfirmationImage}>
			<div className={styles.paragraphContainer}>
				<div className={styles.paragraph}>
					<FormattedMessage
						{...messages.body}
						values={{
							p: (...text: string[]) => <p>{text}</p>,
							email: <span className={styles.highlight}>{email}</span>,
							supportEmail: (
								<a className={styles.link} href={`mailto: ${supportEmail}`}>
									{supportEmail}
								</a>
							),
						}}
					/>
				</div>
			</div>
			<Button
				className={styles.button}
				buttonStyle={ButtonStyle.PRIMARY}
				text={formatMessage(messages.retry)}
				onClick={() => {
					history.push(`${config.BASE_URL_LOGIN}/recover`);
				}}
			/>
		</UserAccessContainer>
	);
};

export default ConfirmationNotReceived;
