/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ProviderType from '../../enums/ProviderType';
import TwoFAType from '../../enums/TwoFAType';
import useRecaptcha from '../../hooks/useRecaptcha';
import useScrollToTop from '../../hooks/useScrollToTop';
import LoginForm from './LoginForm/LoginForm';
import LoginTwoFA from './LoginTwoFA/LoginTwoFA';
import SearchParamError from '../../enums/SearchParamError';
import ChangePassword from './ChangePassword/ChangePassword';
import config from '../../config';
import Disclaimer from '../../components/Disclaimer/Disclaimer';

export interface LoginSubmitValues {
	apple_id_oauth_code?: string;
	apple_id_state?: string;
	password: string;
	provider?: ProviderType;
	token?: string;
	twoFaCode?: string;
	twoFaToken: string;
	twoFaType: TwoFAType;
	twoFaExpirationDate: string;
	txId: string;
	username: string;
	email?: string;
	phoneNumber?: string;
	call_enabled?: boolean;
}

export interface UserInfo {
	password: string;
	username: string;
}

const Login = () => {
	const { push } = useHistory();
	const [twoFARequired, setTwoFARequired] = useState(false);
	const [changePasswordRequired, setChangePasswordRequired] = useState(false);
	const [changePasswordEnforcement, setChangePasswordEnforcement] = useState('');
	const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
	const [submitValues, setSubmitValues] = useState<LoginSubmitValues | null>(null);
	useScrollToTop([twoFARequired, changePasswordRequired]);

	const { executeAsync, isLoading, reloadCaptcha } = useRecaptcha({});

	const handleTwoFA = (isVisible: boolean, values: LoginSubmitValues | null) => {
		setTwoFARequired(isVisible);
		setSubmitValues(values);
	};

	const handleTwoFACancel = (errorCode: SearchParamError) => {
		setTwoFARequired(false);
		setSubmitValues(null);
		push({
			pathname: `${config.BASE_URL_LOGIN}`,
			search: `?error=${errorCode}`,
		});
	};

	return (
		<>
			{changePasswordRequired && userInfo ? (
				<ChangePassword
					executeRecaptchaAsync={executeAsync}
					isRecaptchaLoading={isLoading}
					userInfo={userInfo}
					enforcement={changePasswordEnforcement}
				/>
			) : twoFARequired && submitValues ? (
				<LoginTwoFA
					disable={isLoading}
					executeRecaptchaAsync={executeAsync}
					reloadCaptcha={reloadCaptcha}
					submitValues={submitValues}
					onCancel={handleTwoFACancel}
					setChangePasswordRequired={setChangePasswordRequired}
					setChangePasswordEnforcement={setChangePasswordEnforcement}
				/>
			) : (
				<LoginForm
					disable={isLoading}
					executeRecaptchaAsync={executeAsync}
					reloadCaptcha={reloadCaptcha}
					handleTwoFA={(isVisible: boolean, values: LoginSubmitValues) => {
						handleTwoFA(isVisible, values);
					}}
					setUserInfo={setUserInfo}
					setChangePasswordRequired={setChangePasswordRequired}
					setChangePasswordEnforcement={setChangePasswordEnforcement}
				/>
			)}
			<Disclaimer />
			<div id="recaptcha" />
		</>
	);
};

export default Login;
