import React, { useContext } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import classNames from 'classnames';
import config from '../../config';
import { Context } from '../../store/Provider';
import SocialButton from '../../components/SocialButton/SocialButton';
import socialButtonStyles from '../../components/SocialButton/SocialButton.module.scss';
import googleIcon from '../../images/google.svg';

interface GoogleLoginProps {
	className?: string;
	disabled?: boolean;
	buttonMessage: MessageDescriptor;
}

const GoogleLogin = ({ className, disabled, buttonMessage }: GoogleLoginProps) => {
	const { formatMessage } = useIntl();
	const { state } = useContext(Context);

	const getTokenAndLogin = () => {
		if (disabled) {
			return;
		}

		const redirectUri = state.isMobileClient
			? `${window.location.origin}${config.BASE_URL_LOGIN}?client=mobile&provider_type=GOOGLE`
			: `${window.location.origin}${config.BASE_URL_LOGIN}?provider_type=GOOGLE`;

		window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
			config.GOOGLE_CLIENT_ID
		}&response_type=token&scope=email&redirect_uri=${encodeURIComponent(redirectUri)}`;
	};

	return (
		<SocialButton
			className={classNames(socialButtonStyles.google, className)}
			icon={googleIcon}
			onClick={getTokenAndLogin}
			text={formatMessage(buttonMessage, { provider: 'Google' })}
		/>
	);
};

export default GoogleLogin;
