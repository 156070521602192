import { useEffect, useContext, useRef, useState } from 'react';
import { Context } from '../store/Provider';
import { loadScript, waitForElement } from '../helpers/functionHelpers';
import config from '../config';
import CaptchaType from '../enums/CaptchaType';

const RECAPTCHA_SDK_URL =
	'https://www.google.com/recaptcha/api.js?onload=recaptchaLoadCallback&render=explicit';
const TURNSTILE_SDK_URL =
	'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=turnstileLoadCallback&render=explicit';
const HCAPTCHA_SDK_URL =
	'https://js.hcaptcha.com/1/api.js?onload=hcaptchaLoadCallback&render=explicit';

const IS_CAPTCHA_IFRAME_LOADED_INTERVAL_MS = 500;

const errorCheck = (domElementId: string) => {
	const recaptchaDomElements = document.querySelectorAll(`#${domElementId}`);
	if (!recaptchaDomElements[0]) {
		throw new Error(`No element with id "${domElementId}" found to render recaptcha in.`);
	}
	if (recaptchaDomElements.length > 1) {
		throw new Error(
			`Found ${recaptchaDomElements.length} elements with id "${domElementId}". Specify unique ids if you need to render more than one recaptcha at once.`
		);
	}
};

declare global {
	interface Window {
		grecaptcha: any;
		recaptchaLoadCallback: any;
		turnstile: any;
		turnstileLoadCallback: any;
		hcaptcha: any;
		hcaptchaLoadCallback: any;
	}
}

interface UseRecaptchaOptions {
	overrideCaptchaType?: CaptchaType;
	domElementIdValue?: string;
	siteKeyValue?: string;
	size?: 'invisible' | 'normal';
	onError?: (response: string) => void;
	isRendered?: boolean;
}

const useRecaptcha = ({
	overrideCaptchaType,
	domElementIdValue,
	siteKeyValue,
	size = 'invisible',
	onError,
	isRendered = true,
}: UseRecaptchaOptions) => {
	const { captchaType: captchaTypeValue } = useContext(Context).state;
	const captchaType = overrideCaptchaType || captchaTypeValue;
	const [isLoading, setIsLoading] = useState(true);
	const [captchaResponse, setCaptchaResponse] = useState<string>();
	const widgetId = useRef<number | null>(null);
	const isDisabled = !isRendered;
	const intervalRef = useRef<number>();
	const mutationObserverRef = useRef<MutationObserver | null>();
	const executeResolveRef = useRef<any>();
	const executeRejectRef = useRef<any>();
	const timeoutRef = useRef<number>();
	const domElementId =
		domElementIdValue ||
		captchaType === CaptchaType.RECAPTCHA ||
		captchaType === CaptchaType.HCAPTCHA
			? 'recaptcha'
			: 'cf-captcha';
	const siteKey =
		siteKeyValue ||
		(captchaType === CaptchaType.RECAPTCHA
			? config.RECAPTCHA_SITEKEY
			: captchaType === CaptchaType.CLOUDFLARE
			? config.TURNSTILE_SITEKEY
			: config.HCAPTCHA_SITEKEY);

	const expiredCallback = () => {
		if (widgetId.current) {
			if (captchaType === CaptchaType.RECAPTCHA) {
				window.grecaptcha.reset(widgetId.current);
			} else if (captchaType === CaptchaType.CLOUDFLARE) {
				window.turnstile.reset(widgetId.current);
			} else if (captchaType === CaptchaType.HCAPTCHA) {
				window.hcaptcha.reset(widgetId.current);
			}
		}
	};

	const executeAsync: (onClose?: () => void, throwOnClose?: boolean) => Promise<any> = (
		onClose,
		throwOnClose = false
	) => {
		return new Promise((resolve, reject) => {
			executeResolveRef.current = resolve;
			executeRejectRef.current = reject;

			if (typeof onClose === 'function' || throwOnClose) {
				const handleCloseChallengeWindow = () => {
					if (
						(captchaType === CaptchaType.RECAPTCHA &&
							!window.grecaptcha.getResponse()) ||
						(captchaType === CaptchaType.CLOUDFLARE &&
							!window.turnstile.getResponse()) ||
						(captchaType === CaptchaType.HCAPTCHA && !window.hcaptcha.getResponse())
					) {
						onClose?.();
					}
					mutationObserverRef.current?.disconnect();
					if (throwOnClose) {
						reject(new Error('Captcha challenge window was closed'));
					}
				};

				intervalRef.current = window.setInterval(() => {
					const iframeWindow = Array.from(document.getElementsByTagName('iframe')).find(
						(element) =>
							element.src.includes(
								captchaType === CaptchaType.RECAPTCHA
									? 'google.com/recaptcha/api2/bframe'
									: captchaType === CaptchaType.CLOUDFLARE
									? 'challenges.cloudflare.com/turnstile'
									: 'hcaptcha.com'
							)
					)?.parentNode?.parentNode as HTMLDivElement;

					if (iframeWindow) {
						mutationObserverRef.current = new MutationObserver(
							() => iframeWindow.style.opacity === '0' && handleCloseChallengeWindow()
						);
						mutationObserverRef.current.observe(iframeWindow, {
							attributes: true,
							attributeFilter: ['style'],
						});
						clearInterval(intervalRef.current);
					}
				}, IS_CAPTCHA_IFRAME_LOADED_INTERVAL_MS);
			}

			const tryToExecute = () => {
				if (
					(captchaType === CaptchaType.RECAPTCHA &&
						typeof window.grecaptcha?.execute !== 'function') ||
					(captchaType === CaptchaType.CLOUDFLARE &&
						typeof window.turnstile?.execute !== 'function') ||
					(captchaType === CaptchaType.HCAPTCHA &&
						typeof window.hcaptcha?.execute !== 'function')
				) {
					timeoutRef.current = window.setTimeout(() => {
						tryToExecute();
					}, 200);
				} else if (captchaType === CaptchaType.RECAPTCHA) {
					window.grecaptcha.execute(widgetId.current);
				} else if (captchaType === CaptchaType.CLOUDFLARE) {
					window.turnstile.reset(widgetId.current);
					window.turnstile.execute(widgetId.current);
				} else if (captchaType === CaptchaType.HCAPTCHA) {
					window.hcaptcha.execute(widgetId.current);
				}
			};

			tryToExecute();
		});
	};

	const loadRecaptcha = () => {
		errorCheck(domElementId);

		window.recaptchaLoadCallback = () => {
			if (widgetId.current !== null || !document.getElementById(domElementId)) {
				return;
			}
			setIsLoading(false);

			widgetId.current = window.grecaptcha.render(domElementId, {
				sitekey: siteKey || '',
				size,
				callback: (token: string) => {
					setCaptchaResponse(token);
					executeResolveRef.current(token);
					window.grecaptcha.reset(widgetId.current);
				},
				'error-callback': () => {
					if (typeof executeRejectRef.current === 'function') {
						executeRejectRef.current();
						window.grecaptcha.reset(widgetId.current);
					}
				},
				'expired-callback': expiredCallback,
				badge: 'bottomleft',
				grecaptcha: window.grecaptcha,
			});
		};

		if (window.grecaptcha) {
			window.recaptchaLoadCallback();
		} else {
			loadScript(RECAPTCHA_SDK_URL);
		}
	};

	const loadTurnstile = () => {
		window.turnstileLoadCallback = () => {
			if (widgetId.current !== null) {
				return;
			}
			setIsLoading(false);

			waitForElement(domElementId, (element) => {
				widgetId.current = window.turnstile.render(element, {
					sitekey: siteKey || '',
					appearance: 'interaction-only',
					execution: 'execute',
					callback: (token: string) => {
						setCaptchaResponse(token);
						if (typeof executeResolveRef.current === 'function') {
							executeResolveRef.current(token);
						}
					},
					'error-callback': () => {
						if (typeof executeRejectRef.current === 'function') {
							executeRejectRef.current();
							window.turnstile.reset(widgetId.current);
						}
					},
					'expired-callback': expiredCallback,
				});
			});
		};

		if (window.turnstile) {
			window.turnstileLoadCallback();
		} else {
			loadScript(TURNSTILE_SDK_URL);
		}
	};

	const loadHcaptcha = () => {
		errorCheck(domElementId);

		window.hcaptchaLoadCallback = () => {
			if (widgetId.current !== null) {
				return;
			}
			setIsLoading(false);

			widgetId.current = window.hcaptcha.render(domElementId, {
				sitekey: siteKey || '',
				size,
				callback: (token: string) => {
					setCaptchaResponse(token);
					executeResolveRef.current(token);
					window.hcaptcha.reset(widgetId.current);
				},
				'error-callback': () => {
					if (typeof executeRejectRef.current === 'function') {
						executeRejectRef.current();
						window.hcaptcha.reset(widgetId.current);
					}
				},
				'expired-callback': expiredCallback,
			});
		};

		if (window.hcaptcha) {
			window.hcaptchaLoadCallback();
		} else {
			loadScript(HCAPTCHA_SDK_URL);
		}
	};

	const reloadCaptcha = () => {
		if (captchaType === CaptchaType.CLOUDFLARE) {
			setTimeout(() => {
				window.turnstile.remove(widgetId.current);
				widgetId.current = null;
				loadTurnstile();
			}, 200);
		}
	};

	useEffect(() => {
		if (isDisabled || !siteKey || !captchaType) {
			return;
		}

		if (captchaType === CaptchaType.RECAPTCHA) {
			loadRecaptcha();
		} else if (captchaType === CaptchaType.CLOUDFLARE) {
			loadTurnstile();
		} else if (captchaType === CaptchaType.HCAPTCHA) {
			loadHcaptcha();
		}
	}, [domElementId, onError, isDisabled, siteKey, captchaType]);

	useEffect(
		() => () => {
			if (widgetId.current !== null) {
				if (captchaType === CaptchaType.RECAPTCHA) {
					window.grecaptcha.reset(widgetId.current);
				} else if (captchaType === CaptchaType.CLOUDFLARE) {
					window.turnstile.remove(widgetId.current);
				} else if (captchaType === CaptchaType.HCAPTCHA) {
					window.hcaptcha.reset(widgetId.current);
				}
			}
			window.clearTimeout(timeoutRef.current);
		},
		[captchaType]
	);

	if (isDisabled) {
		return {
			recaptchaResponse: captchaResponse,
			executeAsync,
			isLoading: false,
			reloadCaptcha,
		};
	}

	return { recaptchaResponse: captchaResponse, executeAsync, isLoading, reloadCaptcha };
};

export default useRecaptcha;
