/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { QRCodeSVG } from 'qrcode.react';
import queryString from 'query-string';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClientConfig } from '../../helpers/themeHelpers';
import useAxios from '../../hooks/useAxios';
import UserAccessContainer from '../../components/UserAccessContainer/UserAccessContainer';
import Button, { ButtonStyle } from '../../components/Button/Button';
import endpoints from '../../endpoints';
import config from '../../config';
import styles from './GoogleAuthenticator.module.scss';

const messages = defineMessages({
	title: {
		id: 'googleAuthenticator.title',
		defaultMessage: 'Google Authenticator registration',
	},
	instructions: {
		id: 'googleAuthenticator.instructions',
		defaultMessage:
			'<li>Download Google Auth App to your <a>Android</a> or <i>iOS</i> device.</li><li>Scan the QR code below or enter the key to the Google Auth App.</li>',
	},
	goToLogin: {
		id: 'confirmationRequired.goToLogin',
		defaultMessage: 'Go to login',
	},
});

const GoogleAuthenticator = () => {
	const history = useHistory();
	const axios = useAxios();
	const { formatMessage } = useIntl();
	const { token } = queryString.parse(window.location.search);
	const { title: clientTitle } = useClientConfig();
	const [googleSecret, setGoogleSecret] = useState('');
	const [isRequestPending, setIsRequestPending] = useState(true);

	useEffect(() => {
		if (!token) {
			history.push(config.BASE_URL_LOGIN);
			return;
		}

		setIsRequestPending(true);
		void axios
			.get(endpoints.getGoogleAuthInfo(token as string))
			.then(({ data }) => {
				setGoogleSecret(data.googleAuthSecret);
			})
			.catch(() => {
				history.push(config.BASE_URL_LOGIN);
			})
			.then(() => {
				setIsRequestPending(false);
			});
	}, [axios, history, token]);

	return (
		<UserAccessContainer title={messages.title} hasImageColumn={false}>
			<>
				{isRequestPending ? (
					<div className={styles.loadingIcon}>
						<FontAwesomeIcon icon={faSpinner} className={styles.animation} />
					</div>
				) : (
					<div className={styles.qrContainer}>
						<div className={styles.qr}>
							<div className={styles.indicator} />
							<QRCodeSVG
								size={150}
								level="M"
								value={`otpauth://totp/${encodeURIComponent(
									clientTitle
								)}?secret=${encodeURIComponent(googleSecret)}`}
							/>
						</div>
						<span className={styles.secret}>{googleSecret}</span>
					</div>
				)}
				<div className={styles.info}>
					<ul>
						{formatMessage(messages.instructions, {
							a: (x) => (
								<a
									href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
									rel="noopener noreferrer"
									target="_blank"
								>
									{x}
								</a>
							),
							i: (x) => (
								<a
									href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
									rel="noopener noreferrer"
									target="_blank"
								>
									{x}
								</a>
							),
							li: (x) => (
								<li>
									<span>{x}</span>
								</li>
							),
						})}
					</ul>
				</div>
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.LINK}
					text={formatMessage(messages.goToLogin)}
					onClick={() => {
						history.push(config.BASE_URL_LOGIN);
					}}
				/>
			</>
		</UserAccessContainer>
	);
};

export default GoogleAuthenticator;
