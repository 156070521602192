/* eslint-disable no-template-curly-in-string */
import React, { useState, FormEvent, useEffect, useContext } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import useAxios from '../../../hooks/useAxios';
import useRecaptcha from '../../../hooks/useRecaptcha';
import useAuthErrorMessage from '../../../hooks/useAuthErrorMessage';
import Response from '../../../interfaces/Response';
import Error from '../../../interfaces/Error';
import Button, { ButtonStyle, ButtonType } from '../../../components/Button/Button';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import FormMessage, { FormMessageType } from '../../../components/FormMessage/FormMessage';
import endpoints from '../../../endpoints';
import styles from './Confirm.module.scss';
import { useClientConfig } from '../../../helpers/themeHelpers';
import config from '../../../config';
import { Context } from '../../../store/Provider';

const messages = defineMessages({
	heading: {
		id: 'recoverPasswordConfirm.heading',
		defaultMessage: 'Recover your password',
	},
	body: {
		id: 'recoverPasswordConfirm.body',
		defaultMessage:
			'Recover account password confirmation. The password will be sent to your e-mail.',
	},
	receiveNewPassword: {
		id: 'recoverPasswordConfirm.receiveNewPassword',
		defaultMessage: 'Receive new password',
	},
	confirmationToken: {
		id: 'base.token',
		defaultMessage: 'Confirmation token:',
	},
	success: {
		id: 'recoverPasswordConfirm.successMessage',
		defaultMessage: 'Recovery email sent.',
	},
});

const ConfirmationRequired = () => {
	const axios = useAxios();
	const { token } = queryString.parse(window.location.search);
	const { formatMessage } = useIntl();
	const history = useHistory();
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [formError, setFormError] = useState<Response<Error> | string | null>();
	const { emailConfirmationImage } = useClientConfig();
	const { isRecaptchaEnabled } = useContext(Context).state;

	const { executeAsync: executeRecaptchaAsync, isLoading: isRecaptchaLoading } = useRecaptcha({});
	const errorMessage = useAuthErrorMessage(formError);

	const handleSubmit = async () => {
		if (isRequestPending) {
			return;
		}

		const recaptcha = isRecaptchaEnabled ? await executeRecaptchaAsync() : null;
		setIsRequestPending(true);
		axios
			.post(
				endpoints.passwordResetConfirm(),
				{ token },
				{
					headers: {
						recaptcha,
					},
				}
			)
			.then(() => {
				history.push(`${config.BASE_URL_LOGIN}/recover/success`);
			})
			.catch((response: Response<Error>) => {
				const { errorCode, messageParameters } = response.data;
				const messageKey = messageParameters ? messageParameters[0].key : null;
				if (errorCode === 'A_21' && messageKey === 'token') {
					history.push(`${config.BASE_URL_LOGIN}/recover/link-expired`);
				} else {
					setFormError(response);
				}
			})
			.finally(() => {
				setIsRequestPending(true);
			});
	};

	useEffect(() => {
		if (!token) {
			history.push({
				pathname: `${config.BASE_URL_LOGIN}`,
			});
		}
		setIsRequestPending(true);
		axios
			.post(endpoints.passwordResetTokenCheck(), { token })
			.catch(() => {
				history.push(`${config.BASE_URL_LOGIN}/recover/link-expired`);
			})
			.finally(() => {
				setIsRequestPending(false);
			});
	}, [axios, history, token]);

	return (
		<>
			<UserAccessContainer title={messages.heading} formImage={emailConfirmationImage}>
				<>
					{errorMessage && (
						<FormMessage className={styles.message} type={FormMessageType.ERROR}>
							{errorMessage}
						</FormMessage>
					)}
					<div className={styles.paragraphContainer}>
						<p className={styles.paragraph}>
							<FormattedMessage {...messages.body} />
						</p>
						<p>
							<FormattedMessage {...messages.confirmationToken} />
							<br />
							<b>{token}</b>
						</p>
					</div>
					<form
						onSubmit={(event: FormEvent<HTMLFormElement>) => {
							event.preventDefault();
							void handleSubmit();
						}}
					>
						<div className={styles.buttonContainer}>
							<Button
								withCaptcha
								disabled={isRecaptchaLoading || !!errorMessage || isRequestPending}
								isLoading={isRequestPending}
								className={styles.button}
								buttonStyle={ButtonStyle.PRIMARY}
								type={ButtonType.SUBMIT}
								text={formatMessage(messages.receiveNewPassword)}
							/>
						</div>
					</form>
				</>
			</UserAccessContainer>
			<div id="recaptcha" />
		</>
	);
};

export default ConfirmationRequired;
