/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-template-curly-in-string */
import React, { useContext } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import styles from './ConfirmationRequired.module.scss';
import Button, { ButtonStyle } from '../../../components/Button/Button';
import { Context } from '../../../store/Provider';
import { isMobileView } from '../../../helpers/mobileHelper';
import { useClientConfig } from '../../../helpers/themeHelpers';
import config from '../../../config';

const messages = defineMessages({
	heading: {
		id: 'RecoverPasswordConfirmationRequired.heading',
		defaultMessage: 'Check your inbox',
	},
	body: {
		id: 'RecoverPasswordConfirmationRequired.body',
		defaultMessage:
			'We have sent you an email with the password reset instructions. Follow these instructions to create a new password.',
	},
	haveNotReceivedEmail: {
		id: 'confirmationRequired.haveNotReceivedEmail',
		defaultMessage: 'I have not received the email',
	},
	goToLogin: {
		id: 'confirmationRequired.goToLogin',
		defaultMessage: 'Go to login',
	},
});

const ConfirmationRequired = () => {
	const { formatMessage } = useIntl();
	const history = useHistory();
	const { isMobileClient } = useContext(Context).state;
	const { emailConfirmationImage } = useClientConfig();
	const { email } = queryString.parse(window.location.search);

	return (
		<UserAccessContainer title={messages.heading} formImage={emailConfirmationImage}>
			<div className={styles.paragraphContainer}>
				<p className={styles.paragraph}>
					<FormattedMessage {...messages.body} />
				</p>
			</div>
			<Button
				className={styles.button}
				buttonStyle={ButtonStyle.SECONDARY}
				text={formatMessage(messages.haveNotReceivedEmail)}
				onClick={() => {
					history.push(
						`${
							config.BASE_URL_SIGNUP
						}/confirmation-not-received?email=${encodeURIComponent(
							(email as string) || ''
						)}`
					);
				}}
			/>
			<>
				{isMobileClient || isMobileView ? (
					<Button
						className={styles.button}
						buttonStyle={ButtonStyle.LINK}
						text={formatMessage(messages.goToLogin)}
						onClick={() => {
							history.push(config.BASE_URL_LOGIN);
						}}
					/>
				) : null}
			</>
		</UserAccessContainer>
	);
};

export default ConfirmationRequired;
