/* eslint-disable import/no-cycle */
import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import Cookies from 'js-cookie';
import StorageKey from '../../enums/StorageKey';
import {
	getGoogleAnalyticsScript,
	isLiveEnvironment,
	loadNecessaryCookieScripts,
	loadPreferenceCookieScripts,
	loadStatisticalCookieScripts,
	loadMarketingCookieScripts,
} from '../../helpers/globalScriptHelper';
import useAxios from '../../hooks/useAxios';
import CookieSettings from './CookieSettings/CookieSettings';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import styles from './CookieBanner.module.scss';
import { useClientConfig } from '../../helpers/themeHelpers';
import config from '../../config';

const messages = defineMessages({
	title: {
		id: 'cookie.title',
		defaultMessage: 'We value your privacy',
	},
	acceptButton: {
		id: 'cookie.accept',
		defaultMessage: 'Agree',
	},
	showPurposesButton: {
		id: 'cookie.settings',
		defaultMessage: 'Cookie settings',
	},
	cookiePolicy: {
		id: 'cookie.cookiePolicy',
		defaultMessage: 'Cookie Policy',
	},
	body: {
		id: 'cookie.body',
		defaultMessage: `We and our partners use technology such as cookies to ensure that the website functions properly and safely, as well as to analyse our traffic. Click on “Agree" if you consent to the use of this technology on our site. Please note that if you choose other options from "Cookie Settings" or decide to provide your consent later on, only the cookies necessary for the website operation will be implemented. Disabling cookies may slow down your browsing, restrict the functionality of certain features or block your access to the website. For more information on this topic, please visit our {policyLink}.`,
	},
});

interface CookieBannerProps {
	facebookPixelId: string | null;
	googleAnalyticsId: string | null;
	hotjarId: string | null;
	includeGA: boolean;
	includeFBPixel: boolean;
	isHotjarTrackingEnabled: boolean;
	livechatKey: string | null;
	siftScienceKey: string | null;
	twitterPixelId: string | null;
	userEmail?: string;
	userName?: string;
	userReferralCode?: string;
	userSurname?: string;
	utmTracking?: string;
	isMobileClient: boolean;
}

export interface AllowedCookiesMap {
	[key: string]: boolean;
}

interface CookiePreference extends AllowedCookiesMap {
	marketingCookies: boolean;
	statisticalCookies: boolean;
	preferenceCookies: boolean;
}

export enum CookieConsentStatus {
	ACCEPTED = 'ACCEPTED',
	REVOKED = 'REVOKED',
}

const cookiesActiveByDefault: CookiePreference = {
	marketingCookies: false,
	statisticalCookies: true,
	preferenceCookies: true,
};

const CookieBanner = ({
	facebookPixelId,
	googleAnalyticsId,
	hotjarId,
	includeGA,
	includeFBPixel,
	isHotjarTrackingEnabled,
	livechatKey,
	siftScienceKey,
	twitterPixelId,
	userEmail = '',
	userName = '',
	userReferralCode = '',
	userSurname = '',
	utmTracking = '',
	isMobileClient,
}: CookieBannerProps) => {
	const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(false);
	const [isCookieSettingsVisible, setIsCookieSettingsVisible] = useState(false);
	const location = typeof window !== 'undefined' ? window.location.pathname : '';
	const savedCookiePreferences = Cookies.get(StorageKey.ARE_COOKIES_ALLOWED);
	const axios = useAxios();
	const { locale } = useIntl();
	const { domainName } = useClientConfig();

	const { preferenceCookies, statisticalCookies, marketingCookies } = savedCookiePreferences
		? JSON.parse(savedCookiePreferences)
		: cookiesActiveByDefault;

	const handleRevokeCookiesButtonClick = useCallback(() => {
		Cookies.remove(StorageKey.ARE_COOKIES_ALLOWED);
		setIsCookieBannerVisible(true);
		void axios.post(config.COOKIE_CONSENT_URL);
	}, [axios]);

	const showCookieSettings = useCallback(() => {
		setIsCookieSettingsVisible(true);
	}, []);

	const hideCookieSettings = useCallback(() => {
		setIsCookieSettingsVisible(false);
	}, []);

	useEffect(() => {
		const revokeCookiesButton = document.querySelector('.cookie-revoke-button');
		if (revokeCookiesButton) {
			revokeCookiesButton.addEventListener('click', handleRevokeCookiesButtonClick);
		}

		return () => {
			if (revokeCookiesButton) {
				revokeCookiesButton.removeEventListener('click', handleRevokeCookiesButtonClick);
			}
		};
	}, [handleRevokeCookiesButtonClick, location]);

	useEffect(() => {
		setIsCookieBannerVisible(!savedCookiePreferences);
	}, [savedCookiePreferences]);

	useEffect(() => {
		if (siftScienceKey) loadNecessaryCookieScripts(userEmail, siftScienceKey);
	}, [siftScienceKey, userEmail]);

	useEffect(() => {
		if (preferenceCookies && livechatKey) {
			loadPreferenceCookieScripts(
				userEmail,
				userName,
				userSurname,
				livechatKey,
				isMobileClient
			);
		}
	}, [preferenceCookies, userEmail, userName, userSurname, livechatKey, isMobileClient]);

	useEffect(() => {
		if (statisticalCookies && hotjarId) {
			loadStatisticalCookieScripts(isHotjarTrackingEnabled, hotjarId);
		}
	}, [statisticalCookies, isHotjarTrackingEnabled, hotjarId]);

	useEffect(() => {
		if (marketingCookies && facebookPixelId && twitterPixelId) {
			loadMarketingCookieScripts(includeGA, includeFBPixel, facebookPixelId, twitterPixelId);
		}
	}, [marketingCookies, includeGA, includeFBPixel, facebookPixelId, twitterPixelId]);

	useEffect(() => {
		if (window.debugMode) {
			if (marketingCookies) {
				console.log('GA enabled | anonymizeIp: false | allowAdFeatures: true');
			} else if (statisticalCookies) {
				console.log('GA enabled | anonymizeIp: true | allowAdFeatures: false');
			} else {
				console.log('GA disabled');
			}
		}
		if (!isLiveEnvironment()) return;
		if (googleAnalyticsId) {
			getGoogleAnalyticsScript(userReferralCode, utmTracking, googleAnalyticsId, () => {
				if (typeof window === 'undefined' || typeof window.ga !== 'function') return;
				if (marketingCookies) {
					// @ts-ignore
					window[`ga-disable-${googleAnalyticsId}`] = false;
					window.ga('create', googleAnalyticsId, domainName, {
						userId:
							userReferralCode || (utmTracking === '' ? NaN : Cookies.get('uaid')),
					});
					window.ga('require', 'displayfeatures');
					window.ga('require', 'linkid', 'linkid.js');
					window.ga('set', 'anonymizeIp', false);
					window.ga('send', 'pageview', window.location.pathname);
				} else if (statisticalCookies) {
					// @ts-ignore
					window[`ga-disable-${googleAnalyticsId}`] = false;
					window.ga('create', googleAnalyticsId, domainName, {
						userId:
							userReferralCode || (utmTracking === '' ? NaN : Cookies.get('uaid')),
					});
					window.ga('require', 'linkid', 'linkid.js');
					window.ga('set', 'allowAdFeatures', false);
					window.ga('set', 'anonymizeIp', true);
					window.ga('send', 'pageview', window.location.pathname);
				} else {
					// @ts-ignore
					window[`ga-disable-${googleAnalyticsId}`] = true;
				}
			});
		}
	}, [marketingCookies, statisticalCookies, googleAnalyticsId, userReferralCode, utmTracking]);

	const handleButtonClick = (
		status: CookieConsentStatus,
		allowedCookies: AllowedCookiesMap = cookiesActiveByDefault
	) => {
		let cookieExpirationDate: number | Date = 1; // Give 1 day if endpoint fails.
		void axios
			.post(config.COOKIE_CONSENT_URL, {
				status,
				categories: {
					...cookiesActiveByDefault,
					...allowedCookies,
				},
			})
			.then((response) => {
				const { expiryDate } = response.data;
				cookieExpirationDate = new Date(expiryDate);
				Cookies.set(
					StorageKey.ARE_COOKIES_ALLOWED,
					JSON.stringify({
						...cookiesActiveByDefault,
						...allowedCookies,
					}),
					{ expires: cookieExpirationDate, domain: domainName }
				);
			})
			.catch(() => {
				setIsCookieBannerVisible(false);
			})
			.then(() => {
				if (!allowedCookies.marketingCookies && typeof window.fbq === 'function') {
					// User can open this banner and disable marketing cookies anytime
					window.fbq('consent', 'revoke');
					/* eslint-disable-next-line */
					if (window.debugMode) console.log('FB pixel: disabled');
				}

				setIsCookieBannerVisible(false);
				hideCookieSettings();
			});
	};

	const handleAcceptButtonClick = (
		allowedCookies: AllowedCookiesMap = cookiesActiveByDefault
	) => {
		handleButtonClick(CookieConsentStatus.ACCEPTED, allowedCookies);
	};

	const handleRejectButtonClick = () => {
		handleButtonClick(CookieConsentStatus.REVOKED, {
			marketingCookies: false,
			statisticalCookies: false,
			preferenceCookies: false,
		});
	};

	if (!isCookieBannerVisible) return null;
	return (
		<>
			<div data-cy="cookie-policy" className={styles.container}>
				<div className={styles.body}>
					<div className={styles.content}>
						<div className={styles.title}>
							<FormattedMessage {...messages.title} />
						</div>
						<div className={styles.text}>
							<FormattedMessage
								{...messages.body}
								values={{
									policyLink: (
										<a
											href={`https://${domainName}/${locale}/terms/cookiePolicy.html`} // TODO
										>
											<FormattedMessage {...messages.cookiePolicy} />
										</a>
									),
								}}
							/>
						</div>
					</div>
					<div className={styles.buttonGroup}>
						<Button
							data-cy="accept-btn"
							buttonStyle={ButtonStyle.PRIMARY}
							className={styles.button}
							onClick={() => handleAcceptButtonClick()}
							type={ButtonType.BUTTON}
						>
							<FormattedMessage {...messages.acceptButton} />
						</Button>
						<Button
							data-cy="purpose-btn"
							buttonStyle={ButtonStyle.LINK}
							className={styles.buttonLink}
							onClick={showCookieSettings}
							type={ButtonType.BUTTON}
						>
							<FormattedMessage {...messages.showPurposesButton} />
						</Button>
					</div>
				</div>
			</div>
			{isCookieSettingsVisible && (
				<CookieSettings
					hideCookieSettings={hideCookieSettings}
					onAcceptButtonClick={handleAcceptButtonClick}
					onRejectButtonClick={handleRejectButtonClick}
				/>
			)}
		</>
	);
};

export default CookieBanner;
