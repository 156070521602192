import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import styles from './ConfirmationRequired.module.scss';
import { useClientConfig } from '../../../helpers/themeHelpers';

const messages = defineMessages({
	heading: {
		id: 'confirmationInstructions.heading',
		defaultMessage: 'Check you inbox',
	},
	body: {
		id: 'confirmationInstructions.body',
		defaultMessage:
			'We have sent you an email that contains the further instructions for claiming a business account in {client}.',
	},
});

const ConfirmationRequired: React.FC = () => {
	const { emailConfirmationImage, title } = useClientConfig();
	return (
		<UserAccessContainer title={messages.heading} formImage={emailConfirmationImage}>
			<div className={styles.paragraphContainer}>
				<p>
					<FormattedMessage
						{...messages.body}
						values={{
							// eslint-disable-next-line react/jsx-no-useless-fragment
							client: <>{title}</>,
						}}
					/>
				</p>
			</div>
		</UserAccessContainer>
	);
};

export default ConfirmationRequired;
