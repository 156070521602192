/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-template-curly-in-string */
import queryString from 'query-string';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button, { ButtonStyle } from '../../../components/Button/Button';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import config from '../../../config';
import { makeDummyRequest } from '../../../helpers/functionHelpers';
import { useClientConfig } from '../../../helpers/themeHelpers';
import useAxios from '../../../hooks/useAxios';
import styles from './ConfirmationNotReceived.module.scss';

const messages = defineMessages({
	heading: {
		id: 'confirmationNotReceived.heading',
		defaultMessage: 'I have not received the email',
	},
	body: {
		id: 'confirmationNotReceived.body',
		defaultMessage: `<p>We have sent an email to {email}. Check if this email address is spelled correctly. If not, re-enter your email address using the button below.</p>
         <p>If your email has still not arrived:</p>
         <p>1. Wait 10 more minutes. It may take some time for the email to reach your inbox, depending on the service provider.</p>
         <p>3. Check your Spam and Promotions folders.</p>
         <p>4. If none of that works, contact us at {supportEmail} from your registered email address and we will solve the issue.</p>`,
	},
	signUp: {
		id: 'base.signup',
		defaultMessage: 'Sign up',
	},
	logIn: {
		id: 'base.login',
		defaultMessage: 'Log in',
	},
});

const ConfirmationNotReceived = () => {
	const { supportEmail, signupEnabled, businessSignupEnabled, emailConfirmationImage } =
		useClientConfig();
	const history = useHistory();
	const axios = useAxios();
	const { formatMessage } = useIntl();
	const { email } = queryString.parse(window.location.search);

	const getSignupLink = () => {
		if ((businessSignupEnabled && signupEnabled) || signupEnabled) {
			return config.BASE_URL_SIGNUP;
		}
		if (businessSignupEnabled && !signupEnabled) {
			return '/open-business-account';
		}
		return config.BASE_URL_LOGIN;
	};

	return (
		<UserAccessContainer title={messages.heading} formImage={emailConfirmationImage}>
			<div className={styles.paragraphContainer}>
				<p className={styles.paragraph}>
					<FormattedMessage
						{...messages.body}
						values={{
							p: (...text: string[]) => <p>{text}</p>,
							email: <span className={styles.highlight}>{email}</span>,
							supportEmail: (
								<a className={styles.link} href={`mailto: ${supportEmail}`}>
									{supportEmail}
								</a>
							),
						}}
					/>
				</p>
			</div>
			<Button
				className={styles.button}
				buttonStyle={ButtonStyle.PRIMARY}
				text={formatMessage(
					!businessSignupEnabled && !signupEnabled ? messages.logIn : messages.signUp
				)}
				onClick={async () => {
					if (isMobile) await makeDummyRequest(axios);
					history.push(getSignupLink());
				}}
			/>
		</UserAccessContainer>
	);
};

export default ConfirmationNotReceived;
