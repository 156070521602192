/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-no-constructed-context-values */
import { defineMessages } from 'react-intl';
import React, { useState } from 'react';
import queryString from 'query-string';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import { useClientConfig } from '../../../helpers/themeHelpers';
import 'flag-icons/css/flag-icons.min.css';
import PhoneVerificationContext, {
	PhoneVerificationContextProps,
	PhoneVerificatonState,
} from './Context/PhoneVerificationContext';
import StartPhoneVerification from './StartPhoneVerification/StartPhoneVerification';
import CompletePhoneVerification from './CompletePhoneVerification/CompletePhoneVerification';

const messages = defineMessages({
	title: {
		id: 'verifyPhone.title',
		defaultMessage: 'Verify your phone number',
	},
	label: {
		id: 'base.phoneNumber',
		defaultMessage: 'Phone number',
	},
	confirmationToken: {
		id: 'base.token',
		defaultMessage: 'Confirmation token:',
	},
	activityId: {
		id: 'phoneVerification.activity',
	},
	verify: {
		id: 'base.verify',
		defaultMessage: 'Verify',
	},
});

export default function () {
	const { phoneVerificationImage } = useClientConfig();
	const { token } = queryString.parse(window.location.search);

	const [params, setParams] = useState<PhoneVerificationContextProps>({
		state: PhoneVerificatonState.Start,
		token: token as string,
	});

	return (
		<UserAccessContainer
			title={messages.title}
			formImage={phoneVerificationImage}
			hasImageColumn={false}
		>
			<PhoneVerificationContext.Provider value={{ params, setParams }}>
				{params.state === PhoneVerificatonState.Start && <StartPhoneVerification />}
				{params.state === PhoneVerificatonState.Complete && <CompletePhoneVerification />}
			</PhoneVerificationContext.Provider>
		</UserAccessContainer>
	);
}
