/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import queryString from 'query-string';
import useManualSessionCheck from '../../hooks/useManualSessionCheck';
import { Context } from '../../store/Provider';
import endpoints from '../../endpoints';
import useAxios from '../../hooks/useAxios';
import TwoFAType from '../../enums/TwoFAType';
import ProviderType from '../../enums/ProviderType';
import Response from '../../interfaces/Response';
import Error from '../../interfaces/Error';
// eslint-disable-next-line import/no-cycle
import { LoginSubmitValues } from '../Login/Login';
import AppleLogin from '../AppleLogin/AppleLogin';
import FacebookLogin from '../FacebookLogin/FacebookLogin';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import styles from './SocialLogin.module.scss';
import { useClientConfig } from '../../helpers/themeHelpers';
import errorMessages from '../../messages/errorMessages';
import useEnforcedRecaptcha from '../../hooks/useEnforcedRecaptcha';

interface SubmitValues {
	code?: string;
	password?: string;
	providerType: ProviderType;
	state?: string;
	token: string;
	username?: string;
}

interface SocialSignInInitialData {
	apple_id_oauth_code?: string;
	apple_id_state?: string;
	password?: string;
	provider?: string;
	token?: string;
	username?: string;
}

interface SocialLoginProps {
	disabled?: boolean;
	executeRecaptchaAsync: (onClose?: () => void) => any;
	handleTwoFA: (isVisible: boolean, submitValues: LoginSubmitValues) => void;
	onError: (error?: Response<Error> | string | null) => void;
	onRequestPending?: (isLoading: boolean) => void;
	buttonMessage: MessageDescriptor;
	setSelectCountryView?: React.Dispatch<React.SetStateAction<boolean>>;
	setSocialSignInInitialData?: React.Dispatch<React.SetStateAction<SocialSignInInitialData>>;
}

export interface LoggedInUserInfo {
	email: string;
}

const SocialLogin = ({
	disabled = false,
	executeRecaptchaAsync,
	handleTwoFA,
	onError,
	onRequestPending,
	buttonMessage,
	setSelectCountryView,
	setSocialSignInInitialData,
}: SocialLoginProps) => {
	const { formatMessage } = useIntl();
	const axios = useAxios();
	const history = useHistory();
	const location = useLocation();
	const {
		state: { lang },
	} = useContext(Context);
	const { check: manualSessionCheck } = useManualSessionCheck();
	const [isRequestPending, setIsRequestPending] = useState(false);

	const handleRecaptcha = useEnforcedRecaptcha(executeRecaptchaAsync);

	const handleSubmit = async (values: SubmitValues) => {
		if (isRequestPending) return;
		const { token, code, providerType, state } = values;
		// eslint-disable-next-line consistent-return
		if (!(await manualSessionCheck())) return '';
		// eslint-disable-next-line consistent-return
		if (!token) return onError(formatMessage(errorMessages.unexpectedError));
		// TODO after AUTH-1206
		// const refId = Cookie.get(StorageKey.REF_ID);
		// const utmTrackingId = Cookie.get(StorageKey.UTM_UUID);

		onRequestPending?.(true);
		const getInitialData = () => {
			if (providerType === ProviderType.FACEBOOK || providerType === ProviderType.GOOGLE) {
				return {
					lang,
					password: '',
					provider: providerType,
					token,
					username: '',
					// TODO after AUTH-1206
					// refId: refId || undefined,
					// utmTrackingId: utmTrackingId || undefined
				};
			}

			return {
				apple_id_oauth_code: code,
				apple_id_state: state,
				password: '',
				provider: ProviderType.APPLE_ID,
				token,
				username: '',
				// TODO after AUTH-1206
				// refId: refId || undefined,
				// utmTrackingId: utmTrackingId || undefined
			};
		};

		const initialData = getInitialData();
		setIsRequestPending(true);
		handleRecaptcha(
			(recaptcha) =>
				axios.post(endpoints.login(), queryString.stringify(initialData), {
					headers: {
						recaptcha,
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}),
			() => {
				history.push('/');
			}
		)
			.then(async (response) => {
				// TODO after AUTH-1206
				// // Remove ref and utm tracking cookies from all possible domains after we used them.
				// // We can not be sure on which parent domain it will be set (.bankera.com? .auth.bankera.com?),
				// // only that it will be set on some parent domain.
				// const potentialCookieDomains = getAllSubdomains(window.location.origin);
				// potentialCookieDomains.forEach((domain: string) => {
				// 	Cookie.remove(StorageKey.REF_ID, { domain });
				// 	Cookie.remove(StorageKey.REF_ID, { domain: `.${domain}` });
				// });
				// potentialCookieDomains.forEach((domain: string) => {
				// 	Cookie.remove(StorageKey.UTM_UUID, { domain });
				// 	Cookie.remove(StorageKey.UTM_UUID, { domain: `.${domain}` });
				// });
				setIsRequestPending(false);
				const redirectUrl = response.request.responseURL;
				window.location.href = redirectUrl;
			})
			.catch((response: Response<Error>) => {
				const { status, data } = response || {};
				setIsRequestPending(false);
				onRequestPending?.(false);
				onError(response);
				if (data && status) {
					if (status === 400 && data?.error === '2fa_required') {
						onError(null);
						handleTwoFA(true, {
							...initialData,
							txId: data.tx_id!,
							twoFaToken: data['2fa_token']!,
							twoFaType: data['2fa_type'] as TwoFAType,
							twoFaCode: data['2fa_code'],
							twoFaExpirationDate: data.expiration_date!,
							email: data.email,
							phoneNumber: data.phone_number,
							call_enabled: /true/.test(data.call_enabled || ''),
						});
					}
					if (status === 400 && data?.errorCode === 'A_54') {
						onError(null);
						// eslint-disable-next-line @typescript-eslint/no-unused-expressions
						setSocialSignInInitialData && setSocialSignInInitialData(initialData);
						// eslint-disable-next-line @typescript-eslint/no-unused-expressions
						setSelectCountryView && setSelectCountryView(true);
					}
				}
			});
	};

	useEffect(() => {
		const searchParams = queryString.parse(location.search);
		const hashParams = queryString.parse(location.hash);

		if (searchParams?.provider_type === ProviderType.APPLE_ID) {
			void handleSubmit({
				code: searchParams.apple_id_oauth_code as string,
				providerType: ProviderType.APPLE_ID,
				state: searchParams.apple_id_state as string,
				token: searchParams.id_token as string,
			});
			return;
		}

		if (searchParams?.provider_type === ProviderType.FACEBOOK) {
			void handleSubmit({
				token: hashParams?.access_token as string,
				providerType: ProviderType.FACEBOOK,
			});
			return;
		}

		if (searchParams?.provider_type === ProviderType.GOOGLE) {
			void handleSubmit({
				token: hashParams?.access_token as string,
				providerType: ProviderType.GOOGLE,
			});
			return;
		}

		onRequestPending?.(false);
	}, [location]);

	return (
		<List
			buttonMessage={buttonMessage}
			disabled={disabled || isRequestPending}
			onError={() => onError(formatMessage(errorMessages.unexpectedError))}
			onSuccess={(token: string, providerType: ProviderType) =>
				handleSubmit({ token, providerType })
			}
		/>
	);
};

interface ListProps {
	className?: string;
	disabled?: boolean;
	onError: () => void;
	onSuccess: (token: string, providerType: ProviderType) => void;
	buttonMessage: MessageDescriptor;
}

const List = ({ className, buttonMessage, ...props }: ListProps) => {
	const { socialLoginAppleEnabled, socialLoginFacebookEnabled, socialLoginGoogleEnabled } =
		useClientConfig();

	if (!socialLoginAppleEnabled && !socialLoginFacebookEnabled && !socialLoginGoogleEnabled) {
		return null;
	}
	return (
		<ul className={classNames(styles.list, className)}>
			{socialLoginAppleEnabled && (
				<li className={styles.listItem}>
					<FacebookLogin {...props} buttonMessage={buttonMessage} />
				</li>
			)}
			{socialLoginFacebookEnabled && (
				<li className={styles.listItem}>
					<GoogleLogin {...props} buttonMessage={buttonMessage} />
				</li>
			)}
			{socialLoginGoogleEnabled && (
				<li className={styles.listItem}>
					<AppleLogin {...props} buttonMessage={buttonMessage} />
				</li>
			)}
		</ul>
	);
};

export default SocialLogin;
