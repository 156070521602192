import { useCallback } from 'react';
import { ErrorMessageCodes } from '../messages/errorMessages';
import Error from '../interfaces/Error';
import Response from '../interfaces/Response';

const useEnforcedRecaptcha = (
	executeRecaptchaAsync: (onClose?: () => void, throwOnClose?: boolean) => Promise<string | null>
) => {
	const execute = useCallback(
		async (executeRequest: (recaptch: string | null) => Promise<any>, onClose?: () => void) =>
			executeRequest(null).catch(async (error: Response<Error>) => {
				if (error?.data.errorCode === ErrorMessageCodes.A_75) {
					const recaptcha = await executeRecaptchaAsync(onClose, true).catch(() => null);
					if (recaptcha) return executeRequest(recaptcha);
				}
				// eslint-disable-next-line @typescript-eslint/no-throw-literal
				throw error;
			}),
		[executeRecaptchaAsync]
	);

	return execute;
};

export default useEnforcedRecaptcha;
