/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-cycle */
import React, { useReducer, Dispatch } from 'react';
import reducer, { State, Action } from './reducer';

const initialState: State = {
	isMobileClient: false,
	isRecaptchaEnabled: true,
	captchaType: null,
	lang: 'en',
	overlay: {
		type: null,
		parameters: null,
	},
	client_config: {
		title: '',
		domainName: '',
		verificationsUrl: null,
		hasCookieBar: false,
		hasCountrySelectOnSignup: false,
		showCookieBarOnMobile: false,
		mobileSugestEnabled: false,
		hotjarClientID: null,
		siftScienceKey: null,
		twitterClientID: null,
		googleAnalyticsID: null,
		facebookPixelID: null,
		socialLoginEnabled: true,
		signupEnabled: true,
		businessSignupEnabled: true,
		passwordRecoveryEnabled: true,
		hasConnectedDevices: true,
		hasAppleStore: false,
		hasGooglePlayStore: false,
		showAppLinksOnLogin: false,
		businessAccountEnv: '',
		livechatKey: '',
		socialLoginAppleEnabled: true,
		socialLoginFacebookEnabled: true,
		socialLoginGoogleEnabled: true,
		has2FA: true,
		supportEmail: '',
		termsAndConditions: {
			en: '',
		},
		privacyPolicy: {
			en: '',
		},
		hasLanguagesSwitch: true,
		supportedLanguages: ['en'],
		clientHomeURL: '',
		faviconURL: '',
		logo: { src: '', alt: '', position: 'center' },
		emailConfirmationImage: { src: '', alt: '' },
		phoneVerificationImage: { src: '', alt: '' },
		doubleCheckImage: { src: '', alt: '' },
		passwordSuccessImage: { src: '', alt: '' },
		expiredImage: { src: '', alt: '' },
		rightColImage_1: {
			src: '',
			alt: '',
			isLottieAnimation: false,
			rightColImageRedirectURL: null,
		},
		rightColImage_2: {
			src: '',
			alt: '',
			isLottieAnimation: false,
			rightColImageRedirectURL: null,
		},
	},
};

export const Context = React.createContext<{ state: State; dispatch: Dispatch<Action> }>({
	state: initialState,
	dispatch: () => null,
});

interface ProviderProps {
	children: JSX.Element | JSX.Element[];
}

const Provider = ({ children }: ProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export default Provider;
