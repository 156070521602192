/* eslint-disable react/no-unstable-nested-components */
import React, { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import validator from 'validator';
import Response from '../../interfaces/Response';
import Error from '../../interfaces/Error';
import validation from '../../helpers/validationHelpers';
import useAuthErrorMessage from '../../hooks/useAuthErrorMessage';
import Button, { ButtonStyle, ButtonType } from '../../components/Button/Button';
import FormMessage, { FormMessageType } from '../../components/FormMessage/FormMessage';
import Input from '../../components/Input/Input';
import UserAccessContainer from '../../components/UserAccessContainer/UserAccessContainer';
import endpoints from '../../endpoints';
import useAxios from '../../hooks/useAxios';
import useRecaptcha from '../../hooks/useRecaptcha';
import styles from './RecoverPassword.module.scss';
import { useClientConfig } from '../../helpers/themeHelpers';
import config from '../../config';
import { Context } from '../../store/Provider';
import errorMessages from '../../messages/errorMessages';
import usePersistedSearch from '../../hooks/usePersistedSearch';
import SessionRequired from '../../components/SessionRequired/SessionRequired';
import Protected from '../../components/Protected/Protected';

const messages = defineMessages({
	recoverPassword: {
		id: 'recoverPassword.title',
		defaultMessage: 'Recover your password',
	},
	rememberYourPassword: {
		id: 'recoverPassword.rememberYourPassword',
		defaultMessage: `Remember your password? <link>Log in</link>`,
	},
	email: {
		id: 'base.email',
		defaultMessage: 'Email',
	},
	recoverPasswordButton: {
		id: 'recoverPassword.button',
		defaultMessage: 'Recover Password',
	},
});

interface FormState {
	email: string;
}

interface Errors {
	[key: string]: string;

	email: string;
}

const Content = () => {
	const persistedSearch = usePersistedSearch();

	const history = useHistory();
	const { formatMessage } = useIntl();
	const axios = useAxios();
	const { emailConfirmationImage } = useClientConfig();
	const { isRecaptchaEnabled, lang } = useContext(Context).state;
	const { executeAsync: executeRecaptchaAsync, isLoading: isRecaptchaLoading } = useRecaptcha({});
	const [formError, setFormError] = useState<Response<Error>>();
	const errorMessage = useAuthErrorMessage(formError);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [formState, setFormState] = useState<FormState>({
		email: '',
	});
	const [errors, setErrors] = useState<Errors>({
		email: '',
	});

	const handleValidation = () => {
		const { email } = formState;
		let isValid = true;

		const formErrors: Errors = {
			email: '',
		};

		if (validation.isEmpty(email)) {
			formErrors.email = formatMessage(errorMessages.requiredField);
			isValid = false;
		}

		if (!validator.isEmail(email)) {
			formErrors.email = formatMessage(errorMessages.invalidEmail);
			isValid = false;
		}

		setErrors(formErrors);
		return isValid;
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setFormState((prevState: FormState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	};

	const handleSubmitSuccess = (email: string) => {
		history.push({
			pathname: `${config.BASE_URL_LOGIN}/recover/confirmation-required`,
			search: `?email=${email}`,
		});
	};

	const handleSubmit = async ({ email }: FormState) => {
		if (!handleValidation() || isRequestPending) {
			return;
		}
		const recaptcha = isRecaptchaEnabled ? await executeRecaptchaAsync() : null;
		setIsRequestPending(true);
		void axios
			.post(
				endpoints.passwordResetInit(),
				{ username: email, language: lang },
				{
					headers: {
						recaptcha,
					},
				}
			)
			.then(() => {
				handleSubmitSuccess(email);
			})
			.catch((response: Response<Error>) => {
				setFormError(response);
			})
			.then(() => {
				setIsRequestPending(false);
			});
	};

	return (
		<UserAccessContainer title={messages.recoverPassword} formImage={emailConfirmationImage}>
			<form
				onSubmit={(event: FormEvent<HTMLFormElement>) => {
					event.preventDefault();
					void handleSubmit({
						email: formState.email,
					});
				}}
			>
				{errorMessage && (
					<FormMessage className={styles.message} type={FormMessageType.ERROR}>
						{errorMessage}
					</FormMessage>
				)}
				<Input
					className={styles.input}
					error={errors.email}
					id="email"
					label={formatMessage(messages.email)}
					name="email"
					onChange={handleChange}
					placeholder="email@email.com"
					type="text"
					value={formState.email}
					inputMode="email"
				/>
				<div id="recaptcha" />
				<Button
					withCaptcha
					buttonStyle={ButtonStyle.PRIMARY}
					className={styles.button}
					disabled={isRecaptchaLoading}
					isLoading={isRequestPending}
					text={formatMessage(messages.recoverPasswordButton)}
					type={ButtonType.SUBMIT}
				/>
				<p className={styles.paragraph}>
					<FormattedMessage
						{...messages.rememberYourPassword}
						values={{
							link: (text: string) => (
								<Link
									to={{
										pathname: config.BASE_URL_LOGIN,
										search: persistedSearch,
									}}
									className={styles.link}
								>
									{text}
								</Link>
							),
						}}
					/>
				</p>
			</form>
		</UserAccessContainer>
	);
};

const RecoverPassword = () => {
	return (
		<SessionRequired flow="recover">
			<Protected accessor="passwordRecoveryEnabled">
				<Content />
			</Protected>
		</SessionRequired>
	);
};

export default RecoverPassword;
