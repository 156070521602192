import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import config from '../../config';
import styles from './Disclaimer.module.scss';
import { Clients } from '../../enums/Clients';

const messages = defineMessages({
	disclaimerFinrax: {
		id: 'disclaimer.finrax',
		defaultMessage:
			'Financial service provider is UAB "Pervesk" (legal entity code 304186270, registered address Gedimino ave 5-3, Vilnius, Lithuania) which is an electronic money institution authorised by the Bank of Lithuania (Licence No. 17) for the issuing of electronic money and providing payment services.',
	},
});

const Disclaimer = () => {
	const { formatMessage } = useIntl();

	const getClientDisclaimer = () => {
		switch (config.CLIENT_NAME) {
			case Clients.FINRAX:
			case Clients.TEROXX:
				return formatMessage(messages.disclaimerFinrax);
			case Clients.PION:
			case Clients.SPECTROCOIN:
			case Clients.BANKERA:
			default:
				return null;
		}
	};

	if (!getClientDisclaimer()) return null;
	return (
		<div className={styles.disclaimer}>
			<p>{getClientDisclaimer()}</p>
		</div>
	);
};

export default Disclaimer;
