/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-template-curly-in-string */
import React, { useState, FormEvent, ChangeEvent, useEffect, useContext } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import useAxios from '../../../hooks/useAxios';
import useRecaptcha from '../../../hooks/useRecaptcha';
import useAuthErrorMessage from '../../../hooks/useAuthErrorMessage';
import Response from '../../../interfaces/Response';
import Error from '../../../interfaces/Error';
import Button, { ButtonStyle } from '../../../components/Button/Button';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import FormMessage, { FormMessageType } from '../../../components/FormMessage/FormMessage';
import endpoints from '../../../endpoints';
import styles from './CreatePassword.module.scss';
import { useClientConfig } from '../../../helpers/themeHelpers';
import config from '../../../config';
import PasswordInput from '../../../components/PasswordInput/PasswordInput';
import { Context } from '../../../store/Provider';

const messages = defineMessages({
	heading: {
		id: 'createPassword.heading',
		defaultMessage: 'Create your password',
	},
	body: {
		id: 'createPassword.body',
		defaultMessage:
			'Your password must contain at least:<ul><li>10 characters</li><li>1 uppercase letter</li><li>1 lowercase letter</li><li>1 digit</li><li>1 special character</li></ul>',
	},
	passwordInvalid: {
		id: 'createPassword.passwordInvalid',
		defaultMessage:
			'Password invalid. Check the guide above on how to create a strong password.',
	},
	passwordsDoNotMatch: {
		id: 'createPassword.passwordsDoNotMatch',
		defaultMessage: 'Passwords do not match',
	},
	password: {
		id: 'base.password',
		defaultMessage: 'Password',
	},
	confirmPassword: {
		id: 'base.confirmPassword',
		defaultMessage: 'Repeat your password',
	},
	confirm: {
		id: 'base.confirm',
		defaultMessage: 'Confirm',
	},
	notificationBody: {
		id: 'createPassword.notificationBody',
		defaultMessage:
			'Your email has been confirmed. Please create a password to open your account.',
	},
});

interface Errors {
	[key: string]: string;

	password: string;
	confirmPassword: string;
}

interface FormState {
	password: string;
	confirmPassword: string;
}

const ConfirmationRequired = () => {
	const axios = useAxios();
	const { token } = queryString.parse(window.location.search);
	const { isRecaptchaEnabled } = useContext(Context).state;
	const { formatMessage } = useIntl();
	const history = useHistory();
	const [isTokenValidationPending, setIsTokenValidationPending] = useState(false);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [formError, setFormError] = useState<Response<Error> | string | null>();
	const [formState, setFormState] = useState<FormState>({ password: '', confirmPassword: '' });
	const [errors, setErrors] = useState<Errors>({
		password: '',
		confirmPassword: '',
	});
	const { emailConfirmationImage } = useClientConfig();

	const { executeAsync: executeRecaptchaAsync, isLoading: isRecaptchaLoading } = useRecaptcha({});
	const errorMessage = useAuthErrorMessage(formError);

	const handleValidation = () => {
		const { password, confirmPassword } = formState;
		const formErrors: Errors = {
			password: '',
			confirmPassword: '',
		};
		let isValid = true;

		if (
			!(
				password.length >= 8 &&
				/[a-z]/g.test(password) &&
				/[A-Z]+/.test(password) &&
				/[0-9]+/.test(password)
			)
		) {
			formErrors.password = formatMessage(messages.passwordInvalid);
			isValid = false;
		}

		if (password !== confirmPassword) {
			formErrors.confirmPassword = formatMessage(messages.passwordsDoNotMatch);
			isValid = false;
		}

		setErrors(formErrors);
		return isValid;
	};

	const handleSubmit = async ({ password }: FormState) => {
		if (!handleValidation() || isRecaptchaLoading || isRequestPending) return;
		setFormError(null);
		const recaptcha = isRecaptchaEnabled ? await executeRecaptchaAsync() : null;

		setIsRequestPending(true);
		void axios
			.post(
				endpoints.signupConfirm(token as string),
				{ password },
				{
					headers: {
						recaptcha,
					},
				}
			)
			.then(() => {
				Cookies.remove(config.REFERRAL_COOKIE_NAME, { domain: '.spectrocoin.com' });
				history.push(`${config.BASE_URL_SIGNUP}/almost-there`);
			})
			.catch((response: Response<Error>) => {
				setFormError(response);
			})
			.then(() => {
				setIsRequestPending(false);
			});
	};

	function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		event.persist();
		setFormState((prevState: FormState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	}

	useEffect(() => {
		if (!token) {
			history.push({
				pathname: `login`,
			});
			return;
		}

		setIsTokenValidationPending(true);
		void axios
			.get(endpoints.signupConfirmTokenCheck(token as string))
			.catch(() => {
				history.push(`${config.BASE_URL_SIGNUP}/confirmation-link-expired`);
			})
			.then(() => {
				setIsTokenValidationPending(false);
			});
	}, [axios, history, token]);

	return (
		<>
			<UserAccessContainer title={messages.heading}>
				<div className={styles.notificationContainer}>
					<img
						className={styles.notificationImage}
						src={emailConfirmationImage.src}
						alt={emailConfirmationImage.alt}
					/>
					<div className={styles.notificationTextContainer}>
						<p>
							<FormattedMessage {...messages.notificationBody} />
						</p>
					</div>
				</div>
				<div className={styles.paragraphContainer}>
					<p className={styles.paragraph}>
						<FormattedMessage
							{...messages.body}
							values={{
								ul: (chunks: any) => <ul>{chunks}</ul>,
								li: (chunks: any) => <li>{chunks}</li>,
							}}
						/>
					</p>
				</div>
				<form
					onSubmit={(event: FormEvent<HTMLFormElement>) => {
						event.preventDefault();
						setFormError(null);
						void handleSubmit({
							password: formState.password,
							confirmPassword: formState.confirmPassword,
						});
					}}
				>
					{errorMessage && (
						<FormMessage className={styles.message} type={FormMessageType.ERROR}>
							{errorMessage}
						</FormMessage>
					)}
					<PasswordInput
						className={styles.input}
						error={errors.password}
						id="password"
						label={formatMessage(messages.password)}
						name="password"
						onChange={handleInputChange}
						placeholder={formatMessage(messages.password)}
						value={formState.password}
					/>
					<PasswordInput
						className={styles.input}
						error={errors.confirmPassword}
						id="confirmPassword"
						label={formatMessage(messages.confirmPassword)}
						name="confirmPassword"
						onChange={handleInputChange}
						placeholder={formatMessage(messages.confirmPassword)}
						value={formState.confirmPassword}
					/>
					<Button
						withCaptcha
						isLoading={isRequestPending}
						disabled={
							isRecaptchaLoading ||
							!formState.password ||
							!formState.confirmPassword ||
							isTokenValidationPending
						}
						className={styles.button}
						buttonStyle={ButtonStyle.PRIMARY}
						text={formatMessage(messages.confirm)}
					/>
				</form>
			</UserAccessContainer>
			<div id="recaptcha" />
		</>
	);
};

export default ConfirmationRequired;
