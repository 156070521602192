/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styles from './FailedPhoneVerification.module.scss';
import UserAccessContainer from '../../../../components/UserAccessContainer/UserAccessContainer';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import { useClientConfig } from '../../../../helpers/themeHelpers';
import errorImg from './error.svg';
import errorMessages, { ErrorMessageCodes } from '../../../../messages/errorMessages';

const messages = defineMessages({
	body: {
		id: 'verifyPhone.failure.body',
		defaultMessage: `Error occured`,
	},
	support: {
		id: 'verifyPhone.contactSupport',
		defaultMessage: `Contact support`,
	},
	close: {
		id: 'base.close',
		defaultMessage: 'Close',
	},
});

const FailedPhoneVerification = () => {
	const { formatMessage } = useIntl();
	const { supportEmail, clientHomeURL } = useClientConfig();
	const { code } = useParams() as { code?: keyof typeof ErrorMessageCodes };

	const errorMessage = code ? errorMessages[code] : null;

	return (
		<UserAccessContainer
			formImage={{ src: errorImg, alt: 'Error' }}
			formImageClassName={styles.image}
			hasImageColumn={false}
		>
			<>
				<div className={styles.paragraphContainer}>
					<p className={styles.paragraph}>
						{errorMessage ? (
							formatMessage(errorMessage)
						) : (
							<FormattedMessage {...messages.body} />
						)}
					</p>
				</div>
				{clientHomeURL && (
					<Button
						type={ButtonType.ANCHOR_LINK}
						className={styles.button}
						buttonStyle={ButtonStyle.PRIMARY}
						text={formatMessage(messages.close)}
						link={clientHomeURL}
					/>
				)}
				<Button
					type={ButtonType.ANCHOR_LINK}
					className={styles.button}
					buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
					text={formatMessage(messages.support)}
					link={`mailto: ${supportEmail}`}
				/>
			</>
		</UserAccessContainer>
	);
};

export default FailedPhoneVerification;
