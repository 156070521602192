import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const usePersistedSearch = () => {
	const { search = '' } = useLocation();
	const { client } = queryString.parse(search);
	const persistedParams = queryString.stringify({ client });
	return persistedParams ? `?${persistedParams}` : '';
};

export default usePersistedSearch;
