import { useIntl } from 'react-intl';
import errorMessages from '../messages/errorMessages';

const useUnexpectedErrorMessage = () => {
	const { formatMessage } = useIntl();

	const getUnexpectedErrorMessage = (traceId?: string | null | undefined) => {
		const traceIdString = traceId ? formatMessage(errorMessages.traceId, { traceId }) : null;
		return formatMessage(errorMessages.unexpectedError, { traceId: traceIdString });
	};

	return { getUnexpectedErrorMessage };
};

export default useUnexpectedErrorMessage;
