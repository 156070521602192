/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */

import { AxiosInstance } from 'axios';
import config from '../config';

export function loadScript(src: string, callback: any = null, async = true, defer = true) {
	const loadedScript = document.querySelector(`script[src="${src}"]`);
	if (loadedScript) {
		if (typeof callback === 'function') {
			callback();
		}
		return loadedScript;
	}
	const script = document.createElement('script');
	script.src = src;
	script.async = async;
	script.defer = defer;
	if (typeof callback === 'function') {
		script.onload = callback;
	}
	document.head.appendChild(script);
	return script;
}

export const zeroPad = (digit: number, places = 2) => {
	const zerosCount = places - digit.toString().length + 1;
	const leadingZeros = new Array(+(zerosCount > 0 && zerosCount)).join('0');
	return `${leadingZeros}${digit}`;
};

export const getAllSubdomains = (origin: string) => {
	const domain = origin.split('//')[1].replace('www.', '');
	const segments = domain.split('.');
	const subdomains = segments.map((segment, segmentIndex) => {
		const remainingSegments = segments.slice(segmentIndex);
		return remainingSegments.reduce((acc, curr, i) => {
			/* eslint-disable-next-line no-param-reassign */
			acc += i === 0 ? curr : `.${curr}`;
			return acc;
		}, '');
	});
	// Last subdomain will be .com etc., remove it and return
	return subdomains.slice(0, subdomains.length - 1);
};

export const capitalizeFirstLetter = (s: string) => {
	return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const postForm = (path: string, params: any = null) => {
	/*
	 * Generates a form with hidden fields from params and sends
	 * POST request to specific path
	 */
	const form = document.createElement('form');
	form.setAttribute('method', 'POST');
	form.setAttribute('action', path);
	if (params != null) {
		Object.entries(params).forEach(([key, value]) => {
			const hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', value as string);

			form.appendChild(hiddenField);
		});
	}

	document.body.appendChild(form);
	form.submit();
};

export const postFormWithFetch = (
	path: string,
	params: any | null,
	axios: AxiosInstance,
	headers?: Record<string, string>
) => {
	const formData = new FormData();

	if (params != null) {
		Object.entries(params).forEach(([key, value]) => {
			formData.append(key, value as string);
		});
	}

	return axios(path, {
		method: 'POST',
		data: formData,
		headers,
	});
};

export const makeDummyRequest = (axios: AxiosInstance) => {
	// this is required to make sure that the deeplink catches the request
	// and redirects to the app
	if (!config.DEEPLINK_REQUEST_ENABLED) return;
	return axios.get(config.DEEPLINK_REQUEST_URL);
};

export const waitForElement = (id: string, callback: (element: HTMLElement) => void) => {
	const checkElement = () => {
		const element = document.getElementById(id);
		if (element) {
			callback(element);
			return true;
		}
		return false;
	};

	if (!checkElement()) {
		const targetNode = document.body;
		const observerOptions = {
			childList: true,
			subtree: true,
		};

		const observer = new MutationObserver((_, o) => {
			if (checkElement()) {
				o.disconnect();
			}
		});

		observer.observe(targetNode, observerOptions);
	}
};
