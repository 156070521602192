/* eslint-disable import/no-named-as-default */
import classNames from 'classnames';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Country } from 'react-phone-number-input';
import axios, { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import styles from './StartPhoneVerification.module.scss';
import LabeledField from '../../../../components/LabeledField/LabeledField';
import { RemoteData, RemoteError, RemoteStatus } from '../../../../interfaces/Remote';
import PhoneVerificationContext, {
	CompletePhoneVerificationProps,
	PhoneVerificatonState,
	StartPhoneVerificationProps,
} from '../Context/PhoneVerificationContext';
import endpoints from '../../../../endpoints';
import Spinner from '../../../../components/Spinner/Spinner';
import Button from '../../../../components/Button/Button';
import FormMessage, { FormMessageType } from '../../../../components/FormMessage/FormMessage';
import useAuthErrorMessage from '../../../../hooks/useAuthErrorMessage';
import { getMappedLanguage } from '../../../../helpers/languageMapHelper';
import useRecaptcha from '../../../../hooks/useRecaptcha';
import config from '../../../../config';
import { Context } from '../../../../store/Provider';

const messages = defineMessages({
	label: {
		id: 'base.phoneNumber',
		defaultMessage: 'Phone number',
	},
	confirmationToken: {
		id: 'base.token',
		defaultMessage: 'Confirmation token:',
	},
	verify: {
		id: 'base.verify',
		defaultMessage: 'Verify',
	},
});

const Flag: FC<{ country: Country; className?: string }> = ({ country, className }) => {
	return (
		<span
			className={classNames(styles.flag, `fi fis fi-${country.toLowerCase()}`, className)}
		/>
	);
};

type Verification = {
	maskedPhoneNumber: string;
	phoneNumberCountry: string;
};

export default function () {
	const { push } = useHistory();
	const { params, setParams } = useContext(PhoneVerificationContext);
	const { formatMessage, locale } = useIntl();
	const { isRecaptchaEnabled } = useContext(Context).state;
	const { executeAsync: executeRecaptchaAsync, isLoading: isRecaptchaLoading } = useRecaptcha({});
	const [tokenInfo, setTokenInfo] = useState<RemoteData<Verification>>({
		status: RemoteStatus.None,
	});
	const [action, setAction] = useState<
		RemoteData<Omit<CompletePhoneVerificationProps, 'state' | 'token'>>
	>({
		status: RemoteStatus.None,
	});
	const { state, token } = params as StartPhoneVerificationProps;
	const loadError = useAuthErrorMessage({ data: tokenInfo.error } as any)!;
	const actionError = useAuthErrorMessage({ data: action.error } as any)!;

	useEffect(() => {
		if (!token) return;
		setTokenInfo({ status: RemoteStatus.InProgress });
		axios
			.get<Verification>(endpoints.getPhoneVerification(token))
			.then(({ data }) => {
				setTokenInfo({ status: RemoteStatus.Done, data });
			})
			.catch((e: AxiosError<RemoteError>) => {
				setTokenInfo({ status: RemoteStatus.Error, error: e.response?.data });
				if (e.response?.status === 404) push(`${config.BASE_URL_VERIFY}/phone/error`);
			});
	}, [token]);

	const start = useCallback(async () => {
		const recaptcha = isRecaptchaEnabled ? await executeRecaptchaAsync() : null;

		setAction({ status: RemoteStatus.InProgress });
		axios
			.post<{ txId: string; expirationDate: string }>(
				endpoints.startPhoneVerification(),
				{
					confirmationCode: token,
					language: getMappedLanguage(locale),
				},
				{
					headers: {
						recaptcha,
					},
				}
			)
			.then(({ data }) => {
				setAction({ status: RemoteStatus.Done, data });
				setParams({
					state: PhoneVerificatonState.Complete,
					token,
					txId: data.txId,
					expirationDate: /z/i.test(data.expirationDate)
						? data.expirationDate
						: `${data.expirationDate}Z`,
				});
			})
			.catch((e: AxiosError<RemoteError>) => {
				setAction({ status: RemoteStatus.Error, error: e.response?.data });
			});
	}, [locale]);

	if (state !== PhoneVerificatonState.Start) return null;

	return (
		<>
			{tokenInfo.status === RemoteStatus.InProgress && <Spinner />}
			{tokenInfo.status === RemoteStatus.Error && (
				<FormMessage className={styles.message} type={FormMessageType.ERROR}>
					{loadError}
				</FormMessage>
			)}
			{tokenInfo.status === RemoteStatus.Done && (
				<div className={styles.container}>
					<LabeledField
						label={formatMessage(messages.label)}
						contentClassName={styles.phone}
					>
						<Flag country={tokenInfo.data!.phoneNumberCountry as Country} />
						<span dir="ltr">{tokenInfo.data!.maskedPhoneNumber}</span>
					</LabeledField>
					<LabeledField label={formatMessage(messages.confirmationToken)}>
						<div className={styles.token} dir="ltr">
							{token}
						</div>
					</LabeledField>
					{action.status === RemoteStatus.Error && (
						<FormMessage className={styles.message} type={FormMessageType.ERROR}>
							{actionError}
						</FormMessage>
					)}
					<Button
						withCaptcha
						className={styles.button}
						onClick={start}
						disabled={action.status === RemoteStatus.InProgress || isRecaptchaLoading}
					>
						{formatMessage(messages.verify)}
					</Button>
				</div>
			)}
			<div id="recaptcha" />
		</>
	);
}
