import config from './config';

const baseURI = window.location.origin;

export const login = () => config.AUTH_SERVER_LOGIN_ENDPOINT;
export const openBusinessAccountSendEmail = () => config.OPEN_BUSINESS_ACCOUNT_SEND_EMAIL_URL;
export const signup = () => `${baseURI}/api/signup`;
export const signupConfirm = (token: string) => `${baseURI}/api/signup/${token}/confirm`;
export const signupConfirmTokenCheck = (token: string) => `${baseURI}/api/signup/${token}/check`;
export const signupOTPSInit = (confirmationToken: string) =>
	`${baseURI}/api/signup-otps/${confirmationToken}/init`;
export const signupOTPSCheck = (otpCode: string) => `${baseURI}/api/signup-otps/${otpCode}/check`;
export const sessionCheck = () => `${baseURI}/api/session`;
export const meSSO = () => `${baseURI}/api/session/me`;
export const passwordSSO = (password: string) =>
	`${baseURI}/oauth/authorize?password=${encodeURIComponent(password)}`;
export const twoFASSOInit = (notificationType?: string) =>
	`${baseURI}/oauth/authorize?actionType=INIT${
		notificationType ? `&phone_notification_type=${notificationType}` : ''
	}`;
export const twoFASSOVerify = (txId: string, code: string) =>
	`${baseURI}/oauth/authorize?actionType=VERIFY&twoFaCode=${code}&txId=${txId}`;
export const restrictedCountries = () => `${baseURI}/api/restricted-countries/details`;
export const restrictedCountryWaitingList = () => `${baseURI}/api/restricted-country-waiting-list`;
export const getSignUpContracts = (country: string) =>
	`${config.SC_BASE_URL}/api/fe/signup-contracts/${country}`;
export const passwordResetInit = () => `${baseURI}/api/users/password/reset/init`;
export const passwordResetTokenCheck = () => `${baseURI}/api/users/password/reset/check`;
export const passwordResetConfirm = () => `${baseURI}/api/users/password/reset/confirm`;
export const getAppleIdState = () => `${baseURI}/api/social-login/apple-id/state`;
export const getTwoFASettings = () => `${baseURI}/user/getTwofaSettings.json`;
export const getEmailFromSocialLoginToken = () => '/api/social-login';
export const getClientConfig = () => `${baseURI}/api/client/login-page`;
export const getPhoneVerification = (confirmationCode: string) =>
	`${baseURI}/api/phone-verifications/${confirmationCode}`;
export const startPhoneVerification = () => `${baseURI}/api/phone-verifications/start`;
export const completePhoneVerification = () => `${baseURI}/api/phone-verifications/verify`;
export const cancelTwoFa = () => `${baseURI}/api/login/2fa/cancel`;
export const getGoogleAuthInfo = (token: string) =>
	`${baseURI}/api/2fa/p/google-auth/info?token=${token}`;

export default {
	getAppleIdState,
	getTwoFASettings,
	login,
	passwordResetInit,
	getSignUpContracts,
	restrictedCountries,
	restrictedCountryWaitingList,
	sessionCheck,
	openBusinessAccountSendEmail,
	signup,
	signupConfirm,
	signupConfirmTokenCheck,
	passwordResetTokenCheck,
	passwordResetConfirm,
	signupOTPSInit,
	signupOTPSCheck,
	meSSO,
	passwordSSO,
	twoFASSOInit,
	twoFASSOVerify,
	getEmailFromSocialLoginToken,
	getClientConfig,
	getPhoneVerification,
	startPhoneVerification,
	completePhoneVerification,
	cancelTwoFa,
	getGoogleAuthInfo,
};
