/* eslint-disable @typescript-eslint/no-var-requires */

import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

interface LottieAnimationProps {
	className?: string;
	animationURL: string;
}

const LottieAnimation = ({ className, animationURL }: LottieAnimationProps) => {
	const element = useRef<HTMLDivElement>(null);

	useEffect(() => {
		lottie.loadAnimation({
			renderer: 'svg',
			loop: true,
			autoplay: true,
			path: animationURL,
			container: element.current!,
		});
	}, [element]);

	return <div ref={element} className={className} />;
};

export default LottieAnimation;
