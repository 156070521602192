import axios from 'axios';
import { useEffect, useRef } from 'react';

export default () => {
	const tokenRef = useRef(axios.CancelToken.source());

	const axiosRef = useRef(
		(() => {
			const axiosInstance = axios.create({
				cancelToken: tokenRef.current.token,
				withCredentials: true,
			});

			axiosInstance.interceptors.response.use(
				(response) => response,
				(error) => {
					return Promise.reject(error.response);
				}
			);

			return axiosInstance;
		})()
	);

	useEffect(() => tokenRef.current.cancel, []);

	return axiosRef.current;
};
