/* eslint-disable import/no-named-as-default */
/* eslint-disable no-template-curly-in-string */
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import endpoints from '../../../endpoints';
import useRecaptcha from '../../../hooks/useRecaptcha';
import useAxios from '../../../hooks/useAxios';
import useAuthErrorMessage from '../../../hooks/useAuthErrorMessage';
import useUnexpectedErrorMessage from '../../../hooks/useUnexpectedErrorMessage';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { getMappedLanguage } from '../../../helpers/languageMapHelper';
import Response from '../../../interfaces/Response';
import Error from '../../../interfaces/Error';
import Button, { ButtonStyle, ButtonType } from '../../../components/Button/Button';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import FormMessage, { FormMessageType } from '../../../components/FormMessage/FormMessage';
import PhoneInput from '../../../components/PhoneInput/PhoneInput';
import styles from './VerifyPhone.module.scss';
import config from '../../../config';
import { ErrorCode } from '../../../enums/ErrorCode';
import useCountdown from '../../../hooks/useCountdown';
import convertToLocalTime from '../../../helpers/dateHelper';
import errorMessages from '../../../messages/errorMessages';
import { Context } from '../../../store/Provider';

const messages = defineMessages({
	title: {
		id: 'verifyPhone.title',
		defaultMessage: 'Verify your phone number',
	},
	label: {
		id: 'base.phoneNumber',
		defaultMessage: 'Phone number',
	},
	validationError: {
		id: 'verifyPhone.validation',
		defaultMessage: 'Please enter a valid phone number',
	},
	next: {
		id: 'base.next',
		defaultMessage: 'Next',
	},
});

const VerifyPhone = () => {
	useScrollToTop();
	const { formatMessage, locale } = useIntl();
	const { isRecaptchaEnabled } = useContext(Context).state;
	const axios = useAxios();
	const history = useHistory();
	const intl = useIntl();
	const confirmationToken = (queryString.parse(window.location.search)?.token as string) || '';
	const [isTokenValidationPending, setIsTokenValidationPending] = useState(false);
	const [formError, setFormError] = useState<Response<Error> | string | null>();
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [expirationDate, setExpirationDate] = useState('');
	const { isExpired, formattedTimeLeft } = useCountdown(expirationDate);

	const errorMessage = useAuthErrorMessage(formError);
	const { getUnexpectedErrorMessage } = useUnexpectedErrorMessage();
	const phoneNumberInputInstance = useRef<intlTelInput.Plugin | null>(null);

	const { executeAsync: executeRecaptchaAsync, isLoading: isRecaptchaLoading } = useRecaptcha({});
	const [phoneNumber, setPhoneNumber] = useState('');
	const [phoneNumberError, setPhoneNumberError] = useState('');

	const handleValidation = () => {
		const isValid = phoneNumberInputInstance.current?.isValidNumber();
		if (isValid) {
			setPhoneNumberError('');
		} else {
			setPhoneNumberError(intl.formatMessage(messages.validationError));
		}
		return isValid;
	};

	const handleSubmit = async (values: { phoneNumber: string }) => {
		if (!handleValidation() || isRequestPending) return;
		setFormError(null);
		const recaptcha = isRecaptchaEnabled ? await executeRecaptchaAsync() : null;

		setIsRequestPending(true);
		void axios
			.post(
				endpoints.signupOTPSInit(confirmationToken),
				{
					phoneNumber: values.phoneNumber,
					notificationType: 'SMS',
					language: getMappedLanguage(locale),
				},
				{
					headers: {
						recaptcha,
					},
				}
			)
			.then(({ data }) => {
				const { txId } = data;
				history.push(
					`${config.BASE_URL_SIGNUP}/verify-phone/confirm?phone=${encodeURIComponent(
						phoneNumber
					)}&token=${encodeURIComponent(confirmationToken)}&txId=${encodeURIComponent(
						txId
					)}`
				);
			})
			.catch((response: Response<Error>) => {
				setFormError(response);
				if (response.data.errorCode === ErrorCode.GEN_8) {
					history.push(`${config.BASE_URL_SIGNUP}/confirmation-link-expired`);
				}

				if (response.data.errorCode === ErrorCode.MAX_OTP_REACHED) {
					setFormError(formatMessage(errorMessages.maxOTPReached));
				}
				if (response.data.errorCode === ErrorCode.ACTIVE_OTP_EXISTS) {
					setExpirationDate(
						convertToLocalTime(
							response.data.errorParameters!.find((error) => error.key === 'expires')!
								.value,
							'yyyy-MM-dd HH:mm:ss'
						)
					);
					setFormError(formatMessage(errorMessages.activeOTPExists));
				}
			})
			.then(() => {
				setIsRequestPending(false);
			});
	};

	useEffect(() => {
		if (!confirmationToken) {
			setFormError('Confirmation token not found');
		}
	}, [confirmationToken]);

	useEffect(() => {
		if (!confirmationToken) {
			history.push({
				pathname: `login`,
			});
			return;
		}

		setIsTokenValidationPending(true);
		void axios
			.get(endpoints.signupConfirmTokenCheck(confirmationToken as string))
			.catch((error: Response<Error>) => {
				if (error?.data?.key === 'entity_not_found') {
					history.push(`${config.BASE_URL_SIGNUP}/confirmation-link-expired`);
				} else {
					const traceId = error.data?.traceId;
					setFormError(getUnexpectedErrorMessage(traceId));
				}
			})
			.then(() => {
				setIsTokenValidationPending(false);
			});
	}, [confirmationToken]);

	return (
		<>
			{(() => {
				return (
					<UserAccessContainer title={messages.title}>
						<form
							onSubmit={(event: FormEvent<HTMLFormElement>) => {
								event.preventDefault();
								void handleSubmit({
									phoneNumber,
								});
							}}
						>
							{errorMessage && (
								<FormMessage
									className={styles.message}
									type={FormMessageType.ERROR}
								>
									{errorMessage}
								</FormMessage>
							)}
							<PhoneInput
								setInstance={(instance) => {
									phoneNumberInputInstance.current = instance;
								}}
								onChange={setPhoneNumber}
								label={intl.formatMessage(messages.label)}
								error={phoneNumberError}
								className={styles.input}
							/>
							<Button
								withCaptcha
								className={styles.button}
								isLoading={isRequestPending}
								disabled={
									!phoneNumber ||
									isRecaptchaLoading ||
									isTokenValidationPending ||
									!isExpired
								}
								type={ButtonType.SUBMIT}
								buttonStyle={ButtonStyle.PRIMARY}
								text={`${formatMessage(messages.next)} ${
									!isExpired ? formattedTimeLeft : ''
								}`}
							/>
						</form>
					</UserAccessContainer>
				);
			})()}
			<div id="recaptcha" />
		</>
	);
};

export default VerifyPhone;
