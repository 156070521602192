/* eslint-disable no-template-curly-in-string */
import React, { useContext } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Button, { ButtonStyle } from '../../../components/Button/Button';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import appStoreImage from '../../../images/appStore.svg';
import playStoreImage from '../../../images/playStore.svg';
import config from '../../../config';
import styles from './AlmostThere.module.scss';
import { useClientConfig } from '../../../helpers/themeHelpers';
import { Context } from '../../../store/Provider';
import { makeDummyRequest } from '../../../helpers/functionHelpers';
import useAxios from '../../../hooks/useAxios';

const messages = defineMessages({
	heading: {
		id: 'almostThere.heading',
		defaultMessage: 'You are almost there',
	},
	bodyWithNoApps: {
		id: 'almostThereWithNoApps.body',
		defaultMessage: 'Login and start using your account.',
	},
	bodyWithApps: {
		id: 'almostThereWithApps.body',
		defaultMessage:
			'Download and link {client} application to get extra security and notifications about your account.',
	},
	orJustGoTo: {
		id: 'almostThere.orJustGoTo',
		defaultMessage: 'Or just go to',
	},
	login: {
		id: 'base.login',
		defaultMessage: 'Log in',
	},
	proceedToApp: {
		id: 'base.proceedToApp',
		defaultMessage: 'Proceed to App',
	},
});

const ConfirmationRequired = () => {
	const { hasAppleStore, hasGooglePlayStore, title } = useClientConfig();
	const { isMobileClient } = useContext(Context).state;
	const { formatMessage } = useIntl();
	const history = useHistory();
	const axios = useAxios();

	const confirmationText =
		hasGooglePlayStore || hasAppleStore ? messages.bodyWithApps : messages.bodyWithNoApps;

	return (
		<UserAccessContainer title={messages.heading}>
			<>
				{!isMobileClient && (
					<div className={styles.bodyTextContainer}>
						<p className={styles.paragraph}>
							<FormattedMessage
								{...confirmationText}
								values={{
									// eslint-disable-next-line react/jsx-no-useless-fragment
									client: <>{title}</>,
								}}
							/>
						</p>
					</div>
				)}

				{!isMobileClient && (hasGooglePlayStore || hasAppleStore) && (
					<div className={styles.appStoreLinkContainer}>
						{hasAppleStore && (
							<a href={config.APP_STORE_URI} className={styles.appStoreLink}>
								<img
									className={styles.appStoreImage}
									src={appStoreImage}
									alt={`${title} on App store`}
								/>
							</a>
						)}
						{hasGooglePlayStore && (
							<a href={config.PLAY_STORE_URI} className={styles.appStoreLink}>
								<img
									className={styles.appStoreImage}
									src={playStoreImage}
									alt={`${title} on Play store store`}
								/>
							</a>
						)}
					</div>
				)}

				{!isMobileClient && (hasGooglePlayStore || hasAppleStore) && (
					<div className={styles.justGoToContainer}>
						<p className={styles.paragraph}>
							<FormattedMessage {...messages.orJustGoTo} />
						</p>
					</div>
				)}
			</>
			<Button
				className={styles.button}
				buttonStyle={ButtonStyle.PRIMARY}
				text={formatMessage(isMobileClient ? messages.proceedToApp : messages.login)}
				onClick={async () => {
					if (isMobileClient) {
						window.location.href = config.MOBILE_SIGNUP_ALMOST_THERE_DEEP_LINK;
					} else {
						if (isMobile) await makeDummyRequest(axios);
						history.push(config.BASE_URL_LOGIN);
					}
				}}
			/>
		</UserAccessContainer>
	);
};

export default ConfirmationRequired;
