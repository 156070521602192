/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { AllowedCookiesMap } from '../CookieBanner';
import Toggle from '../../Toggle/Toggle';
import Button, { ButtonStyle, ButtonType } from '../../Button/Button';
import styles from './CookieSettings.module.scss';
import { useClientConfig } from '../../../helpers/themeHelpers';

const messages = defineMessages({
	close: {
		id: 'base.close',
		defaultMessage: 'Close',
	},
	title: {
		id: 'cookie.title',
		defaultMessage: 'We value your privacy',
	},
	agreeAndSaveButton: {
		id: 'cookie.agree_and_save_button',
		defaultMessage: 'Agree and Save',
	},
	rejectAllButton: {
		id: 'cookie.reject_all_button',
		defaultMessage: 'Reject All',
	},
	body: {
		id: 'cookie.body',
		defaultMessage: `We and our partners use technology such as cookies to ensure that the website functions properly and safely, as well as to analyse our traffic. Click on “Agree" if you consent to the use of this technology on our site. Please note that if you choose other options from "Cookie Settings" or decide to provide your consent later on, only the cookies necessary for the website operation will be implemented. Disabling cookies may slow down your browsing, restrict the functionality of certain features or block your access to the website. For more information on this topic, please visit our {policyLink}.`,
	},
	necessaryCookieBody: {
		id: 'cookie.necessaryCookiesBody',
		defaultMessage: `<b>Necessary cookies</b> are essential for the website’s navigation,
		performance, security and functionality. These cookies are required in order to
		deliver information and services to you.`,
	},
	preferenceCookieBody: {
		id: 'cookie.preferenceCookiesBody',
		defaultMessage: `<b>Preference cookies</b> enable our website to remember information that alters
		the way it looks or functions, allowing you to customise your experience on our
		website.`,
	},
	statisticalCookieBody: {
		id: 'cookie.statisticalCookiesBody',
		defaultMessage: `<b>Statistical cookies</b> help us understand how you use and interact with the
		website and allow us to make it better. These cookies are anonymous and provide
		us generalised data.`,
	},
	marketingCookieBody: {
		id: 'cookie.marketingCookieBody',
		defaultMessage: `<b>Marketing cookies</b> are used to deliver targeted content based on
		behavioural traits. Only anonymised information is gathered and used, with the
		purpose of providing your relevant content.`,
	},
	cookiePolicy: {
		id: 'cookie.cookiePolicy',
		defaultMessage: 'Cookie Policy',
	},
});

interface Props {
	hideCookieSettings: () => void;
	onAcceptButtonClick: (allowedCookies?: AllowedCookiesMap) => void;
	onRejectButtonClick: () => void;
}

const CookieSettings = ({
	hideCookieSettings,
	onAcceptButtonClick,
	onRejectButtonClick,
}: Props) => {
	const { locale } = useIntl();
	const [allowedCookies, setAllowedCookies] = useState({
		preferenceCookies: true,
		statisticalCookies: true,
		marketingCookies: false,
	} as AllowedCookiesMap);

	const { domainName } = useClientConfig();

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);

	const handleCheckboxChange = useCallback(
		({ target: { id: checkboxId } }: React.BaseSyntheticEvent) => {
			setAllowedCookies((prevstate: AllowedCookiesMap) => ({
				...prevstate,
				[checkboxId]: !prevstate[checkboxId],
			}));
		},
		[]
	);

	return (
		<div>
			<div className={styles.body}>
				<Button
					data-cy="close-btn"
					className={styles.closeButton}
					onClick={hideCookieSettings}
					type={ButtonType.BUTTON}
					buttonStyle={ButtonStyle.LINK}
				>
					<FormattedMessage id="cookie.close" defaultMessage="Close" />
				</Button>
				<div className={styles.title}>
					<FormattedMessage {...messages.title} />
				</div>
				<div className={classNames(styles.text, styles.main)}>
					<FormattedMessage
						{...messages.body}
						values={{
							policyLink: (
								<a href={`https://${domainName}/${locale}/terms/cookiePolicy.html`}>
									<FormattedMessage {...messages.cookiePolicy} />
								</a>
							),
						}}
					/>
				</div>
				<Toggle
					checked
					disabled
					id="necessaryCookies"
					onChange={handleCheckboxChange}
					className={styles.toggle}
					labelClassName={styles.toggleLabel}
					isTextualSlider
				>
					<FormattedMessage
						{...messages.necessaryCookieBody}
						values={{
							b: (text: string) => <b>{text}</b>,
						}}
					/>
				</Toggle>
				<Toggle
					checked={allowedCookies.preferenceCookies}
					id="preferenceCookies"
					onChange={handleCheckboxChange}
					className={styles.toggle}
					labelClassName={styles.toggleLabel}
				>
					<FormattedMessage
						{...messages.preferenceCookieBody}
						values={{
							b: (text: string) => <b>{text}</b>,
						}}
					/>
				</Toggle>
				<Toggle
					checked={allowedCookies.statisticalCookies}
					id="statisticalCookies"
					onChange={handleCheckboxChange}
					className={styles.toggle}
					labelClassName={styles.toggleLabel}
				>
					<FormattedMessage
						{...messages.statisticalCookieBody}
						values={{
							b: (text: string) => <b>{text}</b>,
						}}
					/>
				</Toggle>
				<Toggle
					checked={allowedCookies.marketingCookies}
					id="marketingCookies"
					onChange={handleCheckboxChange}
					className={styles.toggle}
					labelClassName={styles.toggleLabel}
				>
					<FormattedMessage
						{...messages.marketingCookieBody}
						values={{
							b: (text: string) => <b>{text}</b>,
						}}
					/>
				</Toggle>
				<Button
					data-cy="save-btn"
					className={styles.agreeButton}
					onClick={() => onAcceptButtonClick(allowedCookies)}
					type={ButtonType.BUTTON}
					buttonStyle={ButtonStyle.PRIMARY}
				>
					<FormattedMessage
						{...messages.agreeAndSaveButton}
						values={{
							b: (text: string) => <b>{text}</b>,
						}}
					/>
				</Button>
				<Button
					data-cy="reject-btn"
					className={styles.agreeButton}
					onClick={() => onRejectButtonClick()}
					type={ButtonType.BUTTON}
					buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
				>
					<FormattedMessage
						{...messages.rejectAllButton}
						values={{
							b: (text: string) => <b>{text}</b>,
						}}
					/>
				</Button>
			</div>
			<div className={styles.backdrop} />
		</div>
	);
};

export default CookieSettings;
