import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import ReactSelect, { ValueType } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import SelectOption from '../../interfaces/SelectOption';
import FieldError from '../FieldError/FieldError';
import styles from './Select.module.scss';

const selectStyles = {
	control: (provided: CSSProperties) => ({
		...provided,
		border: '1px solid #e6e8f0',
		borderRadius: '4px',
		height: '40px',
		fontWeight: 400,
		fontSize: '14px',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	option: (provided: CSSProperties) => ({
		...provided,
		fontSize: '14px',
	}),
};

const selectThemeColors = {
	primary: '#4972f4',
	primary25: '#f5f8ff',
	primary50: '#f5f8ff',
	primary75: '#4972f4',
};

export interface SelectChangeEvent {
	target: {
		name: string;
		value: string;
	};
}

interface SelectProps {
	className?: string;
	components?: Partial<SelectComponents<SelectOption, any>>;
	disabled?: boolean;
	error?: string;
	id?: string;
	isSearchable?: boolean;
	label?: string;
	name?: string;
	onChange?: (option: SelectOption) => void;
	onChangeEvent?: (event: SelectChangeEvent) => void;
	options: SelectOption[];
	value: string | null;
}

const Select = ({
	className,
	disabled,
	error,
	id,
	isSearchable = false,
	label,
	onChange,
	onChangeEvent,
	options,
	value,
	...props
}: SelectProps) => {
	const usedValue = options
		? options.find((option) => option.value === value)
		: { label: '', value: '' };

	return (
		<div className={classNames(styles.container, className)}>
			<label className={styles.label} htmlFor={id}>
				{label}
			</label>
			<FieldError error={error} />
			<ReactSelect
				{...props}
				inputId={id}
				isDisabled={disabled}
				isSearchable={isSearchable}
				options={options}
				styles={selectStyles}
				value={usedValue}
				onChange={(option: ValueType<SelectOption, any>) => {
					if (typeof onChange === 'function') {
						onChange(option as SelectOption);
					}
					if (typeof onChangeEvent === 'function') {
						const { value: selectedValue } = option as SelectOption;
						onChangeEvent({
							target: { name: props.name!, value: selectedValue },
						});
					}
				}}
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						...selectThemeColors,
					},
				})}
			/>
		</div>
	);
};

export default Select;
