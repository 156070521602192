import React, { useContext, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import useAxios from '../../../hooks/useAxios';
import endpoints from '../../../endpoints';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import Button, { ButtonStyle, ButtonType } from '../../../components/Button/Button';
import mapIcon from '../../../images/restricted_country_map.svg';
import listIcon from '../../../images/restricted_country_list.svg';
import clockIcon from '../../../images/restricted_country_clock.svg';
import styles from './WaitingListDialog.module.scss';
import { Context } from '../../../store/Provider';
import config from '../../../config';

const messages = defineMessages({
	servicesComingSoon: {
		id: 'waitingList.servicesComingSoon',
		defaultMessage: 'Services for your country are coming soon',
	},
	joinWaitingList: {
		id: 'waitingList.joinWaitingList',
		defaultMessage: 'Join the waiting list!',
	},
	close: {
		id: 'base.close',
		defaultMessage: 'Close',
	},
	join: {
		id: 'base.join',
		defaultMessage: 'Join',
	},
	cancel: {
		id: 'base.cancel',
		defaultMessage: 'Cancel',
	},
	youHaveBeedAdded: {
		id: 'waitingList.youHaveBeenAdded',
		defaultMessage: 'You have been added to the waiting list!',
	},
	weWillLetYouKnowOnceServiceIsAvailable: {
		id: 'waitingList.weWillLetYouKnowOnceServiceIsAvailable',
		defaultMessage: 'We will let you know once the service is available in your country.',
	},
	thankYou: {
		id: 'base.thankYou',
		defaultMessage: 'Thank you!',
	},
	pleaseComeBackAndCheckOurServices: {
		id: 'waitingList.pleaseComeBackAndCheckOurServices',
		defaultMessage: 'Please come back and check our services later.',
	},
});

enum Type {
	JOIN = 'JOIN',
	THANK_YOU = 'THANK_YOU',
	EMAIL_ADDED = 'EMAIL_ADDED',
}

interface KeyValuePair {
	key: string;
	value: string | number | boolean;
}

interface RequestData {
	country: string | null;
	email: string | null;
	language?: string;
	refId?: string | null;
	refTraceId?: string | null;
	utmTrackingId?: string | null;
	waitingListMetaData: KeyValuePair[];
}

interface WaitingListDialogProps {
	executeRecaptchaAsync: (onClose?: () => void) => any;
	closeWaitingListDialog?: () => void;
	requestData: RequestData;
}

const WaitingListDialog = ({
	executeRecaptchaAsync,
	requestData,
	closeWaitingListDialog,
}: WaitingListDialogProps) => {
	const { formatMessage } = useIntl();
	const axios = useAxios();
	const { isRecaptchaEnabled } = useContext(Context).state;
	const [dialogType, setDialogType] = useState(Type.JOIN);
	const [isRequestPending, setIsRequestPending] = useState(false);

	const handleJoinWaitingListButtonClick = async () => {
		if (isRequestPending) return;
		const recaptcha = isRecaptchaEnabled ? await executeRecaptchaAsync() : null;
		setIsRequestPending(true);
		void axios
			.post(endpoints.restrictedCountryWaitingList(), requestData, {
				headers: {
					recaptcha,
				},
			})
			.then(() => {
				setDialogType(Type.EMAIL_ADDED);
			})
			.then(() => {
				setIsRequestPending(false);
			});
	};

	const getContent = () => {
		if (dialogType === Type.JOIN) {
			return (
				<>
					<img className={styles.icon} src={mapIcon} alt="" />
					<h2 className={styles.heading}>
						<FormattedMessage {...messages.servicesComingSoon} />
					</h2>
					<p className={styles.paragraph}>
						<FormattedMessage {...messages.joinWaitingList} />
					</p>
					<Button
						withCaptcha
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.button}
						isLoading={isRequestPending}
						onClick={handleJoinWaitingListButtonClick}
						text={formatMessage(messages.join)}
						type={ButtonType.BUTTON}
					/>
					<Button
						buttonStyle={ButtonStyle.LINK}
						className={styles.link}
						onClick={() => setDialogType(Type.THANK_YOU)}
						text={formatMessage(messages.cancel)}
						type={ButtonType.BUTTON}
					/>
				</>
			);
		}
		if (dialogType === Type.EMAIL_ADDED) {
			return (
				<>
					<img className={styles.icon} src={listIcon} alt="" />
					<h2 className={styles.heading}>
						<FormattedMessage {...messages.youHaveBeedAdded} />
					</h2>
					<p className={styles.paragraph}>
						<FormattedMessage {...messages.weWillLetYouKnowOnceServiceIsAvailable} />
					</p>
					{closeWaitingListDialog ? (
						<Button
							buttonStyle={ButtonStyle.PRIMARY}
							className={styles.button}
							text={formatMessage(messages.close)}
							type={ButtonType.BUTTON}
							onClick={closeWaitingListDialog}
						/>
					) : (
						<Button
							buttonStyle={ButtonStyle.PRIMARY}
							className={styles.button}
							link={`${config.BASE_URL_LOGIN}`}
							text={formatMessage(messages.close)}
							type={ButtonType.LINK}
						/>
					)}
				</>
			);
		}
		return (
			<>
				<img className={styles.icon} src={clockIcon} alt="" />
				<h2 className={styles.heading}>
					<FormattedMessage {...messages.thankYou} />
				</h2>
				<p className={styles.paragraph}>
					<FormattedMessage {...messages.pleaseComeBackAndCheckOurServices} />
				</p>
				{closeWaitingListDialog ? (
					<Button
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.button}
						text={formatMessage(messages.close)}
						type={ButtonType.BUTTON}
						onClick={closeWaitingListDialog}
					/>
				) : (
					<Button
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.button}
						link={`${config.BASE_URL_LOGIN}`}
						text={formatMessage(messages.close)}
						type={ButtonType.LINK}
					/>
				)}
			</>
		);
	};

	return <UserAccessContainer hasImageColumn={false}>{getContent()}</UserAccessContainer>;
};

export default WaitingListDialog;
