export enum RemoteStatus {
	None,
	InProgress,
	Done,
	Error,
}

export type RemoteError = {
	'2fa_code'?: string;
	'2fa_token'?: string;
	'2fa_type'?: string;
	call_enabled?: 'true' | null;
	expiration_date?: string;
	error_description?: string;
	error?: string;
	category?: string;
	defaultMessage?: string;
	reason?: string;
	errorCode?: string;
	bannedUntil?: string;
	tx_id?: string;
	traceId?: string;
	spanId?: string;
	key?: string;
	errorParameters?: { [key: string]: string }[];
	messageParameters?: { [key: string]: string }[];
	email?: string;
	phone_number?: string;
	redirect_uri?: string;
};

export type RemoteData<T> = {
	data?: T;
	status: RemoteStatus;
	error?: RemoteError;
};
