/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-template-curly-in-string */
import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import useAxios from '../../../hooks/useAxios';
import useAuthErrorMessage from '../../../hooks/useAuthErrorMessage';
import Response from '../../../interfaces/Response';
import Error from '../../../interfaces/Error';
import Button, { ButtonStyle } from '../../../components/Button/Button';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import FormMessage, { FormMessageType } from '../../../components/FormMessage/FormMessage';
import endpoints from '../../../endpoints';
import styles from './WithPassword.module.scss';
import { useClientConfig } from '../../../helpers/themeHelpers';
import PasswordInput from '../../../components/PasswordInput/PasswordInput';

const messages = defineMessages({
	continueAs: {
		id: 'login.continueAs',
		defaultMessage: 'Continue as',
	},
	password: {
		id: 'base.password',
		defaultMessage: 'Password',
	},
	continue: {
		id: 'base.continue',
		defaultMessage: 'Continue',
	},
});

interface Errors {
	[key: string]: string;

	password: string;
}

interface FormState {
	password: string;
}

const WithPassword = () => {
	const axios = useAxios();
	const { token } = queryString.parse(window.location.search);
	const { formatMessage } = useIntl();
	const { passwordSuccessImage } = useClientConfig();
	const history = useHistory();
	const [username, setUsername] = useState('');
	const [isUserDataRequestPending, setIsUserDataRequestPending] = useState(false);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [formError, setFormError] = useState<Response<Error> | string | null>();
	const [formState, setFormState] = useState<FormState>({ password: '' });
	const [errors] = useState<Errors>({
		password: '',
	});
	const errorMessage = useAuthErrorMessage(formError);

	const handleSubmit = async ({ password }: FormState) => {
		if (isRequestPending) return;
		setIsRequestPending(true);
		setFormError(null);
		void axios
			.post(endpoints.passwordSSO(password), {})
			.then((response) => {
				// Remember, app at responseURL must include domain of login page in access-control-allow-origin response headers...
				window.location.href = response.request.responseURL;
			})
			.catch((error: any) => {
				setFormError(error);
			})
			.then(() => {
				setIsRequestPending(false);
			});
	};

	function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		event.persist();
		setFormState((prevState: FormState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	}

	useEffect(() => {
		setIsUserDataRequestPending(true);
		void axios
			.get(endpoints.meSSO())
			.then((response) => {
				setUsername(response.data.username);
			})
			.catch((error) => {
				setFormError(error);
			})
			.then(() => {
				setIsUserDataRequestPending(false);
			});
	}, [axios, history, token]);

	const isFormEnabled = formState.password && !isRequestPending && !isUserDataRequestPending;

	return (
		<>
			<UserAccessContainer hasImageColumn={false}>
				<div className={styles.imageContainer}>
					<img
						className={styles.image}
						src={passwordSuccessImage.src}
						alt={passwordSuccessImage.alt}
					/>
				</div>
				<form
					onSubmit={(event: FormEvent<HTMLFormElement>) => {
						event.preventDefault();
						if (isFormEnabled) {
							void handleSubmit({
								password: formState.password,
							});
						}
					}}
				>
					{errorMessage && (
						<FormMessage className={styles.message} type={FormMessageType.ERROR}>
							{errorMessage}
						</FormMessage>
					)}
					<div className={styles.continueAsContainer}>
						<FormattedMessage {...messages.continueAs} />
					</div>
					<div className={styles.usernameContainer}>{username}</div>
					<PasswordInput
						className={styles.input}
						error={errors.password}
						id="password"
						label={formatMessage(messages.password)}
						name="password"
						onChange={handleInputChange}
						placeholder={formatMessage(messages.password)}
						value={formState.password}
					/>
					<Button
						disabled={!isFormEnabled}
						isLoading={isRequestPending}
						className={styles.button}
						buttonStyle={ButtonStyle.PRIMARY}
						text={formatMessage(messages.continue)}
					/>
				</form>
			</UserAccessContainer>
			<div id="recaptcha" />
		</>
	);
};

export default WithPassword;
