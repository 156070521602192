/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-named-as-default */
import React, { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import queryString from 'query-string';
import { Context } from '../../../store/Provider';
import { LoginSubmitValues } from '../../Login/Login';
import endpoints from '../../../endpoints';
import TwoFAType from '../../../enums/TwoFAType';
import RetryType from '../../../enums/RetryType';
import Response from '../../../interfaces/Response';
import Error from '../../../interfaces/Error';
import useAxios from '../../../hooks/useAxios';
import useCountdown from '../../../hooks/useCountdown';
import useAuthErrorMessage from '../../../hooks/useAuthErrorMessage';
import validation from '../../../helpers/validationHelpers';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import TwoFAAttemptsUsed from './TwoFAAttemptsUsed/TwoFAAttemptsUsed';
import FormMessage, { FormMessageType } from '../../../components/FormMessage/FormMessage';
import Button, { ButtonStyle, ButtonType } from '../../../components/Button/Button';
import CountdownInput from '../../../components/CountdownInput/CountdownInput';
import styles from './WithTwoFA.module.scss';
import { useClientConfig } from '../../../helpers/themeHelpers';
import { useInputAttempts } from '../../../hooks/useInputAttempts';
import config from '../../../config';

const POLLING_INTERVAL_MS = 5000;
const DEFAULT_ALLOWED_RETRY_COUNT = 3;
const KEEP_POLLING_ERROR_KEYS = ['2fa_waiting', 'pushme_2fa_waiting'];

const messages = defineMessages({
	title: {
		id: 'twoFaForm.title',
		defaultMessage: 'Second factor authentication',
	},
	emailAuthentication: {
		id: 'base.emailAuthentication',
		defaultMessage: 'Email authentication',
	},
	smsAuthentication: {
		id: 'base.smsAuthentication',
		defaultMessage: 'SMS authentication',
	},
	googleAuthentication: {
		id: 'base.googleAuthentication',
		defaultMessage: 'Google authentication',
	},
	clientAuthentication: {
		id: 'base.clientAuthentication',
		defaultMessage: 'In-app authentication',
	},
	youWillReceiveAuthKey: {
		id: 'twoFaForm.youWillReceiveAuthKey',
		defaultMessage: 'You will receive an authentication key.',
	},
	enterEmailCode: {
		id: 'twoFaForm.enterEmailCode',
		defaultMessage: 'Enter the 6-digit code sent to {email}',
	},
	enterPhoneCode: {
		id: 'twoFaForm.enterPhoneCode',
		defaultMessage: 'Enter the 6-digit code sent to {phone}.',
	},
	enterGoogleCode: {
		id: 'twoFaForm.enterGoogleCode',
		defaultMessage: 'Enter the 6-digit code from the linked Google Authenticator app',
	},
	enterTelegramCode: {
		id: 'twoFaForm.enterTelegramCode',
		defaultMessage: 'Enter the 6-digit code sent to the Telegram app',
	},
	clientAppConfirm: {
		id: 'twoFaForm.clientAppConfirm',
		defaultMessage: 'Please confirm your operation in the {client} app',
	},
	enterAuthKey: {
		id: 'twoFaForm.enterAuthKey',
		defaultMessage: 'Enter the authentication key.',
	},
	authKeyTimeValid: {
		id: 'twoFaForm.authKeyTimeValid',
		defaultMessage:
			'Please note that the key is only valid for 5 minutes. If the message is not delivered on time, try again later.',
	},
	transactionId: {
		id: 'twoFaForm.transactionId',
		defaultMessage: 'Transaction ID',
	},
	key: {
		id: 'twoFaForm.key',
		defaultMessage: 'Key',
	},
	requiredField: {
		id: 'base.requiredField',
		defaultMessage: 'This field is required',
	},
	invalidKey: {
		id: 'error.invalidKey',
		defaultMessage: 'You must enter valid authentication key',
	},
	proceedToApp: {
		id: 'base.proceedToApp',
		defaultMessage: 'Proceed to App',
	},
	cancel: {
		id: 'base.cancel',
		defaultMessage: 'Cancel',
	},
	submit: {
		id: 'base.submit',
		defaultMessage: 'Submit',
	},
	back: {
		id: 'base.back',
		defaultMessage: 'Back',
	},
	retry: {
		id: 'base.retry',
		defaultMessage: 'Retry',
	},
	continueAs: {
		id: 'login.continueAs',
		defaultMessage: 'Continue as',
	},
	continue: {
		id: 'base.continue',
		defaultMessage: 'Continue',
	},
	requestCall: {
		id: 'login.requestCall',
		defaultMessage: 'Request a call',
	},
});

interface TwoFAFormProps {
	submitValues: LoginSubmitValues;
	onCancel: () => void;
}

interface TwoFAInfo {
	twoFaCode?: string;
	twoFaToken?: string;
	twoFaType?: TwoFAType;
	twoFaExpirationDate?: string;
	txId?: string;
	email?: string;
	phoneNumber?: string;
	call_enabled?: boolean;
}

// TODO this needs refactor already:
// - it's very unclear what buttons and texts will be shown on first glance, multiple conditions (expired, twoFaType dependent)
// - state has grown and should tidy it up probably using reducer

const LoginTwoFA = ({ onCancel }: TwoFAFormProps) => {
	const { formatMessage } = useIntl();
	const { isMobileClient } = useContext(Context).state;
	const { passwordSuccessImage, title } = useClientConfig();
	const [formError, setFormError] = useState<Response<Error> | null>();
	const [isProceedToAppButtonVisible, setIsProceedToAppButtonVisible] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState('');
	const errorMessage = useAuthErrorMessage(formError);
	const [twoFaCode, setTwoFaCode] = useState('');
	const [twoFaCodeFieldError, setTwoFaCodeFieldError] = useState('');
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [isResubmitRequired, setIsResubmitRequired] = useState(false);
	const axios = useAxios();
	const intervalRef = useRef<number>();
	const [retryCount] = useState(DEFAULT_ALLOWED_RETRY_COUNT);
	const [twoFAInfo, setTwoFAInfo] = useState<TwoFAInfo>({});
	const isLoading = !twoFAInfo.twoFaType;
	const [username, setUsername] = useState('');
	const [attempt, beginAttempt, fail, expire, endAttempt] = useInputAttempts();

	const isPushBasedAuthenticationType =
		twoFAInfo.twoFaType === TwoFAType.PUSH_ME || twoFAInfo.twoFaType === TwoFAType.DUO_PUSH;

	const { isExpired, formattedTimeLeft } = useCountdown(twoFAInfo.twoFaExpirationDate || null);

	const handleValidation = () => {
		let isValid = true;
		setTwoFaCodeFieldError('');

		if (validation.isEmpty(twoFaCode)) {
			setTwoFaCodeFieldError(formatMessage(messages.requiredField));
			isValid = false;
			fail();
		} else if (validation.isNotValid2FACodeFormat(twoFaCode)) {
			setTwoFaCodeFieldError(formatMessage(messages.invalidKey));
			isValid = false;
			fail();
		}
		return isValid;
	};

	const checkRetryCount = () => {
		if (attempt >= retryCount) {
			setIsResubmitRequired(true);
		}
	};

	const handleLoginFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
		if (isLoading) return;
		event.preventDefault();

		if (!handleValidation()) {
			checkRetryCount();
			return;
		}

		setIsRequestPending(true);
		axios
			.post(endpoints.twoFASSOVerify(twoFAInfo.txId as string, twoFaCode))
			.then(async (response) => {
				setFormError(null);
				setIsRequestPending(false);
				window.location.href = response.request.responseURL;
			})
			.catch((response: Response<Error>) => {
				setFormError(response);
				setIsRequestPending(false);
				checkRetryCount();
			});
	};

	const handleInit = async (retryType?: any) => {
		const phone_notification_type =
			retryType && Object.values(RetryType).includes(retryType) ? retryType : null;

		setIsRequestPending(true);
		await axios
			.get(endpoints.meSSO())
			.then((response) => {
				setUsername(response.data.username);
			})
			.catch((error) => {
				setFormError(error);
			});
		await axios
			.post(endpoints.twoFASSOInit(phone_notification_type))
			.then(async () => {
				setFormError(null);
				setIsRequestPending(false);
			})
			.catch((response: Response<Error>) => {
				const { data } = response;
				setTwoFAInfo((prevState: any) => ({
					...prevState,
					txId: data.tx_id!,
					twoFaToken: data['2fa_token'],
					twoFaType: data['2fa_type'],
					twoFaCode: data['2fa_code'],
					twoFaExpirationDate: data.expiration_date!,
					phoneNumber: data.phone_number,
					email: data.email,
					call_enabled: /true/.test(data.call_enabled || ''),
				}));
				setIsRequestPending(false);
				beginAttempt();
			});
	};

	const handleRetry = (retryType?: any) => {
		setTwoFaCode('');
		setTwoFaCodeFieldError('');
		setFormError(null);
		void handleInit(retryType);
	};

	const handleTwoFaCancel = () => {
		axios
			.post(endpoints.cancelTwoFa(), queryString.stringify({ txId: twoFAInfo.txId }))
			.then(() => {
				clearInterval(intervalRef.current);
				if (typeof onCancel === 'function') {
					onCancel();
				}
				window.location.href = config.BASE_URL_LOGIN;
			})
			.catch((error) => {
				setFormError(error);
			});
	};

	const handleTwoFAPolling = () => {
		axios
			.post(endpoints.twoFASSOVerify(twoFAInfo.txId as string, twoFAInfo.twoFaCode as string))
			.then((response) => {
				clearInterval(intervalRef.current);
				const responseUrl = response.request.responseURL;
				if (isMobileClient) {
					setRedirectUrl(responseUrl);
					setIsProceedToAppButtonVisible(true);
				} else {
					window.location.href = responseUrl;
				}
			})
			.catch((error: Response<Error>) => {
				const { status, data } = error || {};
				if (data && status) {
					if (data.error === '2fa_incorrect') {
						handleTwoFaCancel();
						return;
					}

					if (status !== 200 && !KEEP_POLLING_ERROR_KEYS.includes(data.error!)) {
						setFormError(error);
						clearInterval(intervalRef.current);
					}
				}
			});
	};

	useEffect(() => {
		if (isPushBasedAuthenticationType) {
			const id = window.setInterval(() => {
				handleTwoFAPolling();
			}, POLLING_INTERVAL_MS);

			intervalRef.current = id;
		}

		return () => {
			clearInterval(intervalRef.current);
		};
	}, [twoFAInfo.txId]);

	useEffect(() => {
		if (isExpired) {
			expire();
			endAttempt();
		}
	}, [isExpired, expire, endAttempt]);

	useEffect(() => {
		if (isExpired) {
			checkRetryCount();
			clearInterval(intervalRef.current);
		}
		/* eslint-disable-next-line */
	}, [isExpired, attempt]);

	useEffect(() => {
		void handleInit();
		beginAttempt();
	}, []);

	const getInstructions = () => {
		switch (twoFAInfo.twoFaType) {
			case TwoFAType.EMAIL:
				return formatMessage(messages.enterEmailCode, { email: twoFAInfo.email });
			case TwoFAType.SMS:
				return formatMessage(messages.enterPhoneCode, { phone: twoFAInfo.phoneNumber });
			case TwoFAType.GOOGLE:
				return formatMessage(messages.enterGoogleCode);
			case TwoFAType.PUSH_ME:
			case TwoFAType.DUO_PUSH:
				return formatMessage(messages.clientAppConfirm, { title });
			case TwoFAType.TELEGRAM:
				return formatMessage(messages.enterTelegramCode);
			default:
				return formatMessage(messages.enterAuthKey);
		}
	};

	if (isResubmitRequired) {
		return <TwoFAAttemptsUsed backLink={config.BASE_URL_LOGIN} />;
	}

	return (
		<UserAccessContainer hasImageColumn={false}>
			<div className={styles.container}>
				<div className={styles.imageContainer}>
					<img
						className={styles.image}
						src={passwordSuccessImage.src}
						alt={passwordSuccessImage.alt}
					/>
				</div>
				{(errorMessage || twoFaCodeFieldError) && (
					<FormMessage className={styles.message} type={FormMessageType.ERROR}>
						{errorMessage || twoFaCodeFieldError}
					</FormMessage>
				)}
				<div className={styles.continueAsContainer}>
					<FormattedMessage {...messages.continueAs} />
				</div>
				<div className={styles.usernameContainer}>{username}</div>
				<p className={styles.instructions}>{getInstructions()}</p>
				<form className={styles.form} onSubmit={handleLoginFormSubmit} noValidate>
					<div>
						<div className={styles.label}>
							<FormattedMessage {...messages.transactionId} />
							<div className={styles.transactionId}>{twoFAInfo.txId}</div>
						</div>
					</div>
					{/* TODO: refactor when 2fa is ported from sc login page */}
					{!isLoading &&
						(!isPushBasedAuthenticationType ? (
							<>
								<div>
									<div className={styles.label}>
										<FormattedMessage {...messages.key} />
									</div>
									<CountdownInput
										className={styles.input}
										isCountdownHidden={false}
										isExpired={isExpired}
										pattern="\d*"
										formattedTimeLeft={formattedTimeLeft}
										type="text"
										inputMode="numeric"
										value={twoFaCode}
										onChange={(event: ChangeEvent<HTMLInputElement>) =>
											setTwoFaCode(event.target.value?.trim())
										}
										handleRetryButtonClick={handleRetry}
									/>
								</div>
								{isExpired &&
									twoFAInfo.twoFaType === TwoFAType.SMS &&
									twoFAInfo.call_enabled && (
										<Button
											disabled={isRequestPending}
											buttonStyle={ButtonStyle.LINK}
											className={styles.button}
											onClick={() => handleRetry(RetryType.CALL)}
											text={formatMessage(messages.requestCall)}
											type={ButtonType.BUTTON}
										/>
									)}
								<Button
									buttonStyle={ButtonStyle.PRIMARY}
									className={styles.button}
									disabled={!twoFaCode || isRequestPending || isExpired}
									isLoading={isRequestPending}
									text={formatMessage(messages.continue)}
									type={ButtonType.SUBMIT}
								/>
							</>
						) : (
							<>
								{isProceedToAppButtonVisible && (
									<Button
										buttonStyle={ButtonStyle.PRIMARY}
										className={styles.button}
										link={redirectUrl}
										text={formatMessage(messages.proceedToApp)}
										type={ButtonType.ANCHOR_LINK}
									/>
								)}
								{!isProceedToAppButtonVisible && (
									<div>
										{isExpired ? (
											<>
												<Button
													buttonStyle={ButtonStyle.PRIMARY}
													className={styles.button}
													onClick={handleRetry}
													text={formatMessage(messages.retry)}
													type={ButtonType.BUTTON}
												/>
												<Button
													buttonStyle={ButtonStyle.LINK}
													className={styles.button}
													onClick={onCancel}
													text={formatMessage(messages.cancel)}
													type={ButtonType.BUTTON}
												/>
											</>
										) : (
											<>
												<div className={styles.timeLeft}>
													{formattedTimeLeft}
												</div>
												<Button
													buttonStyle={ButtonStyle.PRIMARY}
													className={styles.button}
													onClick={handleTwoFaCancel}
													text={formatMessage(messages.cancel)}
													type={ButtonType.BUTTON}
												/>
											</>
										)}
									</div>
								)}
							</>
						))}
				</form>
				{/* 2fa mock controls, let's keep them until we have stable version */}
				{/* <button
					onClick={() => {
						axios.post('/push_me-control?approve=true', {});
					}}
				>
					Approve
				</button>
				<button
					onClick={() => {
						axios.post('/push_me-control?cancel=true', {});
					}}
				>
					Cancel
				</button> */}
			</div>
		</UserAccessContainer>
	);
};

export default LoginTwoFA;
