import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Switch as RouterSwitch, Route, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { Context } from './store/Provider';
import StorageKey from './enums/StorageKey';
import {
	SET_CLIENT_CONFIG,
	SET_IS_MOBILE_CLIENT,
	SET_IS_RECAPTCHA_ENABLED,
	SET_CAPTCHA_TYPE,
	SET_LANG,
} from './store/actionTypes';
import useScrollToTop from './hooks/useScrollToTop';
import Login from './containers/Login/Login';
import SignUp from './containers/SignUp/SignUp';
import { getLivechatScript } from './helpers/globalScriptHelper';
import ConfirmationRequired from './containers/SignUp/ConfirmationRequired/ConfirmationRequired';
import ConfirmationNotReceived from './containers/SignUp/ConfirmationNotReceived/ConfirmationNotReceived';
import VerifyPhone from './containers/SignUp/VerifyPhone/VerifyPhone';
import VerifyPhoneConfirm from './containers/SignUp/VerifyPhone/Confirm/Confirm';
import AlmostThere from './containers/SignUp/AlmostThere/AlmostThere';
import LinkExpired from './containers/SignUp/LinkExpired/LinkExpired';
import CreatePassword from './containers/SignUp/CreatePassword/CreatePassword';
import RecoverPassword from './containers/RecoverPassword/RecoverPassword';
import RecoverPasswordConfirm from './containers/RecoverPassword/Confirm/Confirm';
import RecoverPasswordLinkExpired from './containers/RecoverPassword/LinkExpired/LinkExpired';
import RecoverPasswordSuccess from './containers/RecoverPassword/Success/Success';
import RecoverPasswordConfirmationRequired from './containers/RecoverPassword/ConfirmationRequired/ConfirmationRequired';
import RecoverPasswordConfirmationNotReceived from './containers/RecoverPassword/ConfirmationNotReceived/ConfirmationNotReceived';
import PasswordChangeSuccess from './containers/PasswordChangeSuccess/PasswordChangeSuccess';
import WithPassword from './containers/LoginSSO/WithPassword/WithPassword';
import WithTwoFA from './containers/LoginSSO/WithTwoFA/WithTwoFA';
import SessionExpired from './containers/SessionExpired/SessionExpired';
import PleaseRetry from './containers/PleaseRetry/PleaseRetry';
import GoogleAuthenticator from './containers/GoogleAuthenticator/GoogleAuthenticator';
import 'normalize.css';
import './stylesheets/styles.scss';
import OpenBusinessAccount from './containers/OpenBusinessAccount/OpenBusinessAccount';
import { getExternalThemeConfig, useClientConfig, useTheme } from './helpers/themeHelpers';
import Spinner from './components/Spinner/Spinner';
import useReferral from './hooks/useReferral';
import endpoints from './endpoints';
import useAxios from './hooks/useAxios';
import config from './config';
import HCaptchaBadge from './components/HCaptchaBadge/HCaptchaBadge';
import CookieBanner from './components/CookieBanner/CookieBanner';
import { RTLLanguages } from './components/LanguageSwitcher/LanguageSwitcher';
import LoginResume from './containers/Login/LoginResume/LoginResume';
import Verify from './containers/Verify/Verify';
import CaptchaType from './enums/CaptchaType';

const DISABLED_ROUTES_FOR_SESSION_CHECK = ['/login-resume'];

function App() {
	const { state, dispatch } = useContext(Context);
	const {
		title,
		businessSignupEnabled,
		hasCookieBar,
		showCookieBarOnMobile,
		hotjarClientID,
		siftScienceKey,
		twitterClientID,
		googleAnalyticsID,
		facebookPixelID,
		livechatKey,
	} = useClientConfig();
	const [messages, setMessages] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const initialScreenSize = window.innerHeight;
	const isMobileView = window.innerWidth <= 767;
	const isMobileClient = queryString.parse(window.location.search)?.client === 'mobile';
	const { referralID } = useReferral();
	const axios = useAxios();

	useScrollToTop();

	useEffect(() => {
		const ua = window.navigator.userAgent;
		const isIE = /MSIE|Trident/.test(ua);

		if (isIE) {
			window.location.href = `${config.BASE_URL_LOGIN}/unsupported-browser`;
		}
	}, []);

	useEffect(() => {
		if (RTLLanguages.includes(state.lang.toLowerCase())) {
			document.body.setAttribute('dir', 'rtl');
		} else {
			document.body.setAttribute('dir', 'ltr');
		}
	}, [state.lang]);

	useEffect(() => {
		const savedLang = Cookies.get(StorageKey.USER_LANG);
		const { lang: searchParamLang } = queryString.parse(window.location.search);
		if (searchParamLang || savedLang) {
			dispatch({
				type: SET_LANG,
				payload: searchParamLang || savedLang,
			});
		}
		dispatch({
			type: SET_IS_MOBILE_CLIENT,
			payload: queryString.parse(window.location.search)?.client === 'mobile',
		});

		if (!DISABLED_ROUTES_FOR_SESSION_CHECK.includes(window.location.pathname)) {
			void axios
				.get(endpoints.sessionCheck())
				.then(({ data }) => {
					const { clientId, captchaType } = data;
					dispatch({
						type: SET_CAPTCHA_TYPE,
						payload: captchaType,
					});
					const recaptchaDisabledClients = config.RECAPTCHA_DISABLED_CLIENT_LIST.replace(
						/\s+/g,
						''
					).split(',');
					const isRecaptchaEnabledOnWebOrMobile = isMobileClient
						? config.MOBILE_RECAPTCHA_ENABLED
						: config.LOGIN_RECAPTCHA_ENABLED;
					const isRecaptchaEnabledOnClient = !recaptchaDisabledClients.includes(clientId);
					const isRecaptchaEnabled =
						isRecaptchaEnabledOnWebOrMobile && isRecaptchaEnabledOnClient;
					dispatch({
						type: SET_IS_RECAPTCHA_ENABLED,
						payload: isRecaptchaEnabled,
					});
				})
				.catch(() => null);
		}
	}, [dispatch]);

	useEffect(() => {
		const filename = process.env.NODE_ENV === 'development' ? 'defaultLanguage' : state.lang;
		void import(`./translations/${filename}.json`).then((importedMessages) =>
			setMessages(importedMessages)
		);
	}, [state.lang]);

	useEffect(() => {
		if (livechatKey) {
			getLivechatScript('', '', '', livechatKey);
		}
		/* eslint-disable-next-line */
	}, []);

	useEffect(() => {
		if (isMobileView)
			window.onresize = () => {
				if (initialScreenSize > window.innerHeight) {
					const recaptchaElement =
						document.querySelector<HTMLElement>('.grecaptcha-badge');
					if (recaptchaElement) recaptchaElement.classList.add('alterRecaptchaElement');
					const liveChatElement =
						document.querySelector<HTMLElement>('#chat-widget-container');
					if (liveChatElement && livechatKey)
						liveChatElement.classList.add('alterLiveChatElementElement');
				} else {
					const recaptchaElement =
						document.querySelector<HTMLElement>('.grecaptcha-badge');
					if (recaptchaElement)
						recaptchaElement.classList.remove('alterRecaptchaElement');
					const liveChatElement =
						document.querySelector<HTMLElement>('#chat-widget-container');
					if (liveChatElement && livechatKey)
						liveChatElement.classList.remove('alterLiveChatElementElement');
				}
			};
		/* eslint-disable-next-line */
	}, []);

	const theme = useRef({});
	useEffect(() => {
		const loadConfig = async () => {
			const { externalClientConfig, externalThemeConfig } = await getExternalThemeConfig();
			theme.current = externalThemeConfig;

			await axios
				.get(endpoints.getClientConfig())
				.then(({ data }) => {
					dispatch({
						type: SET_CLIENT_CONFIG,
						payload: { ...externalClientConfig, ...data },
					});
				})
				.catch(() => {
					dispatch({
						type: SET_CLIENT_CONFIG,
						payload: externalClientConfig,
					});
				})
				.then(() => {
					setIsLoading(false);
				});
		};
		void loadConfig();
	}, []);
	// load theme variables
	useTheme(theme.current);

	const showCookieBanner =
		!isMobileClient && !isMobileView && hasCookieBar ? true : showCookieBarOnMobile;

	if (isLoading) {
		return <Spinner isFullScreen />;
	}
	return (
		<IntlProvider defaultLocale="en" locale={state.lang} messages={messages}>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<>
				<RouterSwitch>
					<Route exact path="/" component={Login} />
					<Route exact path={`${config.BASE_URL_LOGIN}`} component={Login} />
					<Route exact path="/login-resume" component={LoginResume} />
					{businessSignupEnabled && (
						<Route
							exact
							path="/open-business-account"
							component={OpenBusinessAccount}
						/>
					)}
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/password-changed`}
						component={PasswordChangeSuccess}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/sso-password`}
						component={WithPassword}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/sso-two-fa`}
						component={WithTwoFA}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/session-expired`}
						component={SessionExpired}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/please-retry`}
						component={PleaseRetry}
					/>
					<Route path={`${config.BASE_URL_VERIFY}`} component={Verify} />
					<Route exact path={`${config.BASE_URL_SIGNUP}`} component={SignUp} />
					<Route
						exact
						path={`${config.BASE_URL_SIGNUP}/confirmation-required`}
						component={ConfirmationRequired}
					/>
					<Route
						exact
						path={`${config.BASE_URL_SIGNUP}/confirmation-not-received`}
						component={ConfirmationNotReceived}
					/>
					<Route
						exact
						path={`${config.BASE_URL_SIGNUP}/confirmation-link-expired`}
						component={LinkExpired}
					/>
					<Route
						exact
						path={`${config.BASE_URL_SIGNUP}/confirm`}
						component={CreatePassword}
					/>
					<Route
						exact
						path={`${config.BASE_URL_SIGNUP}/verify-phone`}
						component={VerifyPhone}
					/>
					<Route
						exact
						path={`${config.BASE_URL_SIGNUP}/verify-phone/confirm`}
						component={VerifyPhoneConfirm}
					/>
					<Route
						exact
						path={`${config.BASE_URL_SIGNUP}/almost-there`}
						component={AlmostThere}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/recover`}
						component={RecoverPassword}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/forgot`}
						component={RecoverPassword}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/forgot/confirm`}
						component={RecoverPasswordConfirm}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/recover/confirm`}
						component={RecoverPasswordConfirm}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/recover/confirmation-required`}
						component={RecoverPasswordConfirmationRequired}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/recover/confirmation-not-received`}
						component={RecoverPasswordConfirmationNotReceived}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/recover/link-expired`}
						component={RecoverPasswordLinkExpired}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/recover/success`}
						component={RecoverPasswordSuccess}
					/>
					<Route
						exact
						path={`${config.BASE_URL_LOGIN}/google-auth`}
						component={GoogleAuthenticator}
					/>
					{/* redirect everything not found to login page */}
					<Redirect path="*" to={`${config.BASE_URL_LOGIN}`} />
				</RouterSwitch>
				{state.captchaType === CaptchaType.HCAPTCHA && <HCaptchaBadge />}
				{showCookieBanner && (
					<CookieBanner
						isMobileClient={isMobileClient}
						facebookPixelId={config.FACEBOOK_APP_ID}
						googleAnalyticsId={config.GOOGLE_CLIENT_ID}
						hotjarId={hotjarClientID}
						includeGA={!!googleAnalyticsID}
						includeFBPixel={!!facebookPixelID}
						isHotjarTrackingEnabled={!!hotjarClientID}
						livechatKey={livechatKey}
						siftScienceKey={siftScienceKey}
						twitterPixelId={twitterClientID}
						userEmail=""
						userName=""
						userReferralCode={referralID || ''}
						userSurname=""
						utmTracking={localStorage.getItem(StorageKey.UTM_UUID) || undefined}
					/>
				)}
			</>
		</IntlProvider>
	);
}

export default Sentry.withProfiler(App);
