/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import { SET_CAPTCHA_TYPE } from '../../../store/actionTypes';
import CountrySelect from '../../../components/CountrySelect/CountrySelect';
import MobileAppSuggestion from '../../../components/MobileAppSuggestion/MobileAppSuggestion';
import Spinner from '../../../components/Spinner/Spinner';
import config from '../../../config';
import endpoints from '../../../endpoints';
import { Clients } from '../../../enums/Clients';
import PasswordChangeEnforcement from '../../../enums/PasswordChangeEnforcement';
import TwoFAType from '../../../enums/TwoFAType';
import { postFormWithFetch } from '../../../helpers/functionHelpers';
import { useClientConfig } from '../../../helpers/themeHelpers';
import validation from '../../../helpers/validationHelpers';
import useAuthErrorMessage from '../../../hooks/useAuthErrorMessage';
import useAxios from '../../../hooks/useAxios';
import useManualSessionCheck from '../../../hooks/useManualSessionCheck';
import useReferral from '../../../hooks/useReferral';
import Error from '../../../interfaces/Error';
import Response from '../../../interfaces/Response';
import errorMessages, { ErrorMessageCodes } from '../../../messages/errorMessages';
import { Context } from '../../../store/Provider';
import WaitingListDialog from '../../SignUp/WaitingListDialog/WaitingListDialog';
import DefaultLoginForm from '../../ClientComponents/Default/LoginForm/DefaultLoginForm';
import SCLoginForm from '../../ClientComponents/Spectrocoin/LoginForm/SCLoginForm';
import { LoginSubmitValues, UserInfo } from '../Login';
import SessionRequired from '../../../components/SessionRequired/SessionRequired';
import usePersistedSearch from '../../../hooks/usePersistedSearch';
import useEnforcedRecaptcha from '../../../hooks/useEnforcedRecaptcha';

interface Errors {
	[key: string]: string;

	username: string;
	password: string;
}

interface FormState {
	username: string;
	password: string;
}

interface SocialSignInInitialData {
	apple_id_oauth_code?: string;
	apple_id_state?: string;
	password?: string;
	provider?: string;
	token?: string;
	username?: string;
}

interface LoginFormProps {
	disable?: boolean;
	handleTwoFA: (isVisible: boolean, values: LoginSubmitValues) => void;
	executeRecaptchaAsync: (onClose?: () => void) => any;
	reloadCaptcha: () => void;
	setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
	setChangePasswordRequired: React.Dispatch<React.SetStateAction<boolean>>;
	setChangePasswordEnforcement: React.Dispatch<React.SetStateAction<string>>;
}

const Content = ({
	handleTwoFA,
	executeRecaptchaAsync,
	reloadCaptcha,
	disable = false,
	setUserInfo,
	setChangePasswordRequired,
	setChangePasswordEnforcement,
}: LoginFormProps) => {
	const axios = useAxios();
	const history = useHistory();
	const { state: contextState, dispatch } = useContext(Context);
	const { lang } = contextState;
	const { formatMessage, locale } = useIntl();
	const { clientHomeURL } = useClientConfig();
	const { referralID, refTrackID } = useReferral();
	const {
		state,
		duo_code: duoCode,
		flow,
		'pass-required': passRequired,
		'two-fa-required': twoFARequired,
		error,
	} = queryString.parse(window.location.search);

	const isSSOWithPassword = !!passRequired;
	const isSSOWithTwoFA = !!twoFARequired;

	if (isSSOWithPassword) {
		history.push(`${config.BASE_URL_LOGIN}/sso-password`);
	}

	if (isSSOWithTwoFA) {
		history.push(`${config.BASE_URL_LOGIN}/sso-two-fa`);
	}

	const { check: manualSessionCheck } = useManualSessionCheck();

	const handleRecaptcha = useEnforcedRecaptcha(executeRecaptchaAsync);

	const [selectCountryView, setSelectCountryView] = useState<boolean>(false);
	const [waitingListView, setWaitingListView] = useState<boolean>(false);
	const [selectedCountry, setSelectedCountry] = useState<string>('');
	const [socialSignInInitialData, setSocialSignInInitialData] = useState<SocialSignInInitialData>(
		{}
	);
	const [acceptPrivacyPolicyAndTerms, setAcceptPrivacyPolicyAndTerms] = useState<boolean>(false);
	const [socialSignInEmail, setSocialSignInEmail] = useState<string>('');
	const [errors, setErrors] = useState<Errors>({
		username: '',
		password: '',
	});
	const [formError, setFormError] = useState<Response<Error> | string | null>();
	const [formState, setFormState] = useState<FormState>({
		username: '',
		password: '',
	});

	const errorMessage = useAuthErrorMessage(formError);
	const [isLoading, setIsLoading] = useState(false);
	const [isDUOLoading, setIsDUOLoading] = useState(false);
	const timer = useRef<number>();
	const persistedSearch = usePersistedSearch();

	useEffect(() => {
		timer.current = window.setInterval(() => {
			void axios
				.get(endpoints.sessionCheck())
				.then(({ data }) => {
					const { sessionExists, captchaType } = data;
					dispatch({
						type: SET_CAPTCHA_TYPE,
						payload: captchaType,
					});
					if (sessionExists === false) {
						window.location.assign(clientHomeURL);
					}
				})
				.catch(() => null);
		}, config.LOGIN_SESSION_CHECK_INTERVAL_MS);

		return () => clearInterval(timer.current);
	}, []);

	useEffect(() => {
		// We need to check against flow param, because we cannot redirect to signup page
		// after session authrization. We can have only 1 redirect url which is /login.
		if (flow === 'signup') {
			window.location.href = `${config.BASE_URL_SIGNUP}${persistedSearch}`;
		}
		if (flow === 'recover') {
			window.location.href = `${config.BASE_URL_LOGIN}/recover${persistedSearch}`;
		}
	}, [flow]);

	useEffect(() => {
		const handleDuoLogin = async () => {
			postFormWithFetch(endpoints.login(), { txId: state, twoFaCode: duoCode }, axios, {
				'Content-Type': 'multipart/form-data',
			})
				.then((response: AxiosResponse) => {
					setFormError(null);
					window.location.href = response.request.responseURL;
				})
				.catch(async (response: Response<Error>) => {
					const { data, status } = response || {};
					setIsDUOLoading(false);
					await axios.get(endpoints.meSSO()).then(({ data }) => {
						// once user comes back from DUO login, we need to fetch username from the API
						// and set it in the state so that it can be used in the password change form
						setUserInfo({ username: data.username, password: '' });
					});

					if (data && status) {
						if (status === 400 && data.errorCode === ErrorMessageCodes.A_74) {
							setChangePasswordEnforcement(PasswordChangeEnforcement.ENFORCE);
							setChangePasswordRequired(true);
							reloadCaptcha();
						} else if (status === 400 && data.errorCode === ErrorMessageCodes.A_73) {
							// DUO has to have password change enforced even on password_change_suggested error code
							setChangePasswordEnforcement(PasswordChangeEnforcement.ENFORCE);
							setChangePasswordRequired(true);
							reloadCaptcha();
						} else {
							history.push(`${config.BASE_URL_LOGIN}/session-expired`);
						}
					}
					setFormError(response);
				});
		};

		// DUO callback requires a post request to the login endpoint
		// with the txId and twoFaCode as parameters
		if (state && duoCode) {
			setIsDUOLoading(true);
			void handleDuoLogin();
		}
	}, [state, duoCode]);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setFormState((prevState: FormState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	};

	const handleValidation = () => {
		const { username, password } = formState;
		let isValid = true;
		const formErrors: Errors = {
			username: '',
			password: '',
		};

		if (validation.isEmpty(username)) {
			formErrors.username = formatMessage(errorMessages.requiredField);
			isValid = false;
		}

		if (!validator.isEmail(username)) {
			formErrors.username = formatMessage(errorMessages.invalidEmail);
			isValid = false;
		}

		if (validation.isEmpty(password)) {
			formErrors.password = formatMessage(errorMessages.requiredField);
			isValid = false;
		}

		setErrors(formErrors);
		return isValid;
	};

	const handleLoginFormSubmit = async (
		{ username, password }: FormState,
		isSocialSignIn = false
	) => {
		if (!handleValidation() && !isSocialSignIn) return;
		setIsLoading(true);
		if (!(await manualSessionCheck())) return;

		const requestData = isSocialSignIn
			? {
					...socialSignInInitialData,
					...(referralID ? { ref_id: referralID } : {}),
					...(refTrackID ? { ref_trace_id: refTrackID } : {}),
					country: selectedCountry,
					lang,
					signup_meta_data: JSON.stringify([
						{ key: 'contractCountry', value: selectedCountry },
					]),
			  }
			: { username, password, lang };
		handleRecaptcha((recaptcha) =>
			axios.post(endpoints.login(), queryString.stringify(requestData), {
				headers: {
					recaptcha,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
		)
			.then((response) => {
				window.location.href = response.request.responseURL;
			})
			.catch((response: Response<Error>) => {
				const { status, data } = response || {};
				setIsLoading(false);
				setUserInfo({ username, password });
				if (data && status) {
					if (status === 400 && data.errorCode === ErrorMessageCodes.A_74) {
						setChangePasswordEnforcement(PasswordChangeEnforcement.ENFORCE);
						setChangePasswordRequired(true);
						reloadCaptcha();
					}

					if (status === 400 && data.errorCode === ErrorMessageCodes.A_73) {
						setChangePasswordEnforcement(PasswordChangeEnforcement.SUGGEST);
						setChangePasswordRequired(true);
						reloadCaptcha();
					}

					if (
						status === 400 &&
						data.error === '2fa_required' &&
						data['2fa_type'] === TwoFAType.DUO_PUSH
					) {
						window.location.replace(data.redirect_uri!);
						return;
					}
					if (['SOPT_6', 'A_67'].includes(data?.errorCode!)) {
						setFormError(response);
						return;
					}
					if (status === 400 && data.error === '2fa_required') {
						setFormError(null);
						handleTwoFA(true, {
							username,
							password,
							txId: data.tx_id!,
							twoFaToken: data['2fa_token']!,
							twoFaType: data['2fa_type'] as TwoFAType,
							twoFaCode: data['2fa_code'],
							twoFaExpirationDate: data.expiration_date!,
							email: data.email,
							phoneNumber: data.phone_number,
							call_enabled: /true/.test(data.call_enabled || ''),
						});
					}
					if (status === 400 && data.errorCode === 'A_45') {
						axios
							.get(endpoints.getEmailFromSocialLoginToken(), {
								params: {
									token: socialSignInInitialData.token,
									providerType: socialSignInInitialData.provider,
								},
							})
							.then((response) => {
								setSocialSignInEmail(response.data.email);
								setFormError(null);
								setWaitingListView(true);
							})
							.catch((response: Response<Error>) => {
								setIsLoading(false);
								setFormError(response);
							});
					}
				}
				setFormError(response);
			});
	};

	const closeWaitingListDialog = () => {
		setWaitingListView(false);
		setSelectCountryView(false);
		history.replace({ search: persistedSearch });
	};

	if (isDUOLoading) return <Spinner isFullScreen />;
	if (waitingListView) {
		return (
			<WaitingListDialog
				executeRecaptchaAsync={executeRecaptchaAsync}
				closeWaitingListDialog={closeWaitingListDialog}
				requestData={{
					country: selectedCountry,
					language: locale,
					email: socialSignInEmail,
					waitingListMetaData: [
						{
							key: 'contractCountry',
							value: selectedCountry,
						},
						{
							key: 'acceptPrivacyPolicy',
							value: acceptPrivacyPolicyAndTerms,
						},
						{
							key: 'acceptTermsAndConditions',
							value: acceptPrivacyPolicyAndTerms,
						},
					],
				}}
			/>
		);
	}

	if (selectCountryView) {
		return (
			<CountrySelect
				selectedCountry={selectedCountry}
				setSelectedCountry={setSelectedCountry}
				acceptPrivacyPolicyAndTerms={acceptPrivacyPolicyAndTerms}
				setAcceptPrivacyPolicyAndTerms={setAcceptPrivacyPolicyAndTerms}
				handleLogin={handleLoginFormSubmit}
				setFormError={setFormError}
			/>
		);
	}

	const clientForm = () => {
		switch (config.CLIENT_NAME) {
			case Clients.SPECTROCOIN:
				return (
					<SCLoginForm
						handleTwoFA={handleTwoFA}
						executeRecaptchaAsync={executeRecaptchaAsync}
						disable={disable}
						handleLoginFormSubmit={handleLoginFormSubmit}
						formState={formState}
						errorMessage={errorMessage}
						error={error}
						handleChange={handleChange}
						errors={errors}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						setFormError={setFormError}
						setSelectCountryView={setSelectCountryView}
						setSocialSignInInitialData={setSocialSignInInitialData}
					/>
				);
			default:
				return (
					<DefaultLoginForm
						handleTwoFA={handleTwoFA}
						executeRecaptchaAsync={executeRecaptchaAsync}
						disable={disable}
						handleLoginFormSubmit={handleLoginFormSubmit}
						formState={formState}
						errorMessage={errorMessage}
						error={error}
						handleChange={handleChange}
						errors={errors}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						setFormError={setFormError}
						setSelectCountryView={setSelectCountryView}
						setSocialSignInInitialData={setSocialSignInInitialData}
					/>
				);
		}
	};

	return (
		<>
			{clientForm()}
			<MobileAppSuggestion />
		</>
	);
};

const LoginForm = (props: LoginFormProps) => {
	const {
		provider_type,
		'pass-required': passRequired,
		'two-fa-required': twoFARequired,
	} = queryString.parse(window.location.search);

	const isSSOWithPassword = !!passRequired;
	const isSSOWithTwoFA = !!twoFARequired;

	return (
		<SessionRequired
			flow="login"
			disabled={!!provider_type || isSSOWithPassword || isSSOWithTwoFA}
		>
			<Content {...props} />
		</SessionRequired>
	);
};

export default LoginForm;
