import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PhoneVerification from './PhoneVerification/PhoneVerification';
import SuccessPhoneVerification from './PhoneVerification/SuccessPhoneVerification/SuccessPhoneVerification';
import FailedPhoneVerification from './PhoneVerification/FailedPhoneVerification/FailedPhoneVerification';
import config from '../../config';

export default function () {
	return (
		<Switch>
			<Route exact path={`${config.BASE_URL_VERIFY}/phone`} component={PhoneVerification} />
			<Route
				exact
				path={`${config.BASE_URL_VERIFY}/phone/success`}
				component={SuccessPhoneVerification}
			/>
			<Route
				exact
				path={`${config.BASE_URL_VERIFY}/phone/error/:code?`}
				component={FailedPhoneVerification}
			/>
		</Switch>
	);
}
