/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';
import { Context } from '../../../store/Provider';
import { LoginSubmitValues } from '../Login';
import endpoints from '../../../endpoints';
import TwoFAType from '../../../enums/TwoFAType';
import ProviderType from '../../../enums/ProviderType';
import Response from '../../../interfaces/Response';
import Error from '../../../interfaces/Error';
import useAxios from '../../../hooks/useAxios';
import useCountdown from '../../../hooks/useCountdown';
import useAuthErrorMessage from '../../../hooks/useAuthErrorMessage';
import validation from '../../../helpers/validationHelpers';
import UserAccessContainer from '../../../components/UserAccessContainer/UserAccessContainer';
import TwoFAAttemptsUsed from '../../TwoFAAttemptsUsed/TwoFAAttemptsUsed';
import FormMessage, { FormMessageType } from '../../../components/FormMessage/FormMessage';
import Button, { ButtonStyle, ButtonType } from '../../../components/Button/Button';
import CountdownInput from '../../../components/CountdownInput/CountdownInput';
import styles from './LoginTwoFA.module.scss';
import { useInputAttempts } from '../../../hooks/useInputAttempts';
import PasswordChangeEnforcement from '../../../enums/PasswordChangeEnforcement';
import SearchParamError from '../../../enums/SearchParamError';
import config from '../../../config';
import { useClientConfig } from '../../../helpers/themeHelpers';
import { ErrorMessageCodes } from '../../../messages/errorMessages';
import useEnforcedRecaptcha from '../../../hooks/useEnforcedRecaptcha';

const POLLING_INTERVAL_MS = 5000;
const MIN_TIME_TILL_EXPIRATION_MS = POLLING_INTERVAL_MS - 500;
const DEFAULT_ALLOWED_RETRY_COUNT = 3;
const DEFAULT_ALLOWED_RETRY_COUNT_WITH_EXPIRED_TIME = 2;
const KEEP_POLLING_ERROR_KEYS = ['2fa_waiting', 'pushme_2fa_waiting'];

const messages = defineMessages({
	title: {
		id: 'twoFaForm.title',
		defaultMessage: 'Second factor authentication',
	},
	emailAuthentication: {
		id: 'base.emailAuthentication',
		defaultMessage: 'Email authentication',
	},
	smsAuthentication: {
		id: 'base.smsAuthentication',
		defaultMessage: 'SMS authentication',
	},
	googleAuthentication: {
		id: 'base.googleAuthentication',
		defaultMessage: 'Google authentication',
	},
	clientAuthentication: {
		id: 'base.clientAuthentication',
		defaultMessage: 'In-app authentication',
	},
	telegramAuthentication: {
		id: 'base.telegramAuthentication',
		defaultMessage: 'Telegram authentication',
	},
	youWillReceiveAuthKey: {
		id: 'twoFaForm.youWillReceiveAuthKey',
		defaultMessage: 'You will receive an authentication key.',
	},
	enterAuthKey: {
		id: 'twoFaForm.enterAuthKey',
		defaultMessage: 'Enter the authentication key.',
	},
	authKeyTimeValid: {
		id: 'twoFaForm.authKeyTimeValid',
		defaultMessage:
			'Please note that the key is only valid for 5 minutes. If the message is not delivered on time, try again later.',
	},
	transactionId: {
		id: 'twoFaForm.transactionId',
		defaultMessage: 'Transaction ID',
	},
	key: {
		id: 'twoFaForm.key',
		defaultMessage: 'Key',
	},
	enterEmailCode: {
		id: 'twoFaForm.enterEmailCode',
		defaultMessage: 'Enter the 6-digit code sent to {email}',
	},
	enterPhoneCode: {
		id: 'twoFaForm.enterPhoneCode',
		defaultMessage: 'Enter the 6-digit code sent to {phone}.',
	},
	enterGoogleCode: {
		id: 'twoFaForm.enterGoogleCode',
		defaultMessage: 'Enter the 6-digit code from the linked Google Authenticator app',
	},
	enterTelegramCode: {
		id: 'twoFaForm.enterTelegramCode',
		defaultMessage: 'Enter the 6-digit code sent to the Telegram app',
	},
	clientAppConfirm: {
		id: 'twoFaForm.clientAppConfirm',
		defaultMessage: 'Please confirm your operation in the {client} app',
	},
	requiredField: {
		id: 'base.requiredField',
		defaultMessage: 'This field is required',
	},
	invalidKey: {
		id: 'error.invalidKey',
		defaultMessage: 'You must enter valid authentication key',
	},
	proceedToApp: {
		id: 'base.proceedToApp',
		defaultMessage: 'Proceed to App',
	},
	cancel: {
		id: 'base.cancel',
		defaultMessage: 'Cancel',
	},
	requestSMS: {
		id: 'login.requestSMS',
		defaultMessage: 'Request an SMS code',
	},
	requestCall: {
		id: 'login.requestCall',
		defaultMessage: 'Request a call',
	},
	submit: {
		id: 'base.submit',
		defaultMessage: 'Submit',
	},
	back: {
		id: 'base.back',
		defaultMessage: 'Back',
	},
	retry: {
		id: 'base.retry',
		defaultMessage: 'Retry',
	},
	haveNotReceivedSMS: {
		id: 'verifyPhoneConfirm.haveNotReceivedSMS',
		defaultMessage: 'Have not received an SMS?',
	},
	askForCall: {
		id: 'verifyPhoneConfirm.askForCall',
		defaultMessage: 'Ask for a call',
	},
});

interface TwoFAFormProps {
	disable?: boolean;
	submitValues: LoginSubmitValues;
	onCancel: (errorCode: SearchParamError) => void;
	executeRecaptchaAsync: (onClose?: () => void) => any;
	reloadCaptcha: () => void;
	setChangePasswordRequired?: React.Dispatch<React.SetStateAction<boolean>>;
	setChangePasswordEnforcement?: React.Dispatch<React.SetStateAction<string>>;
}

const LoginTwoFA = ({
	onCancel,
	submitValues,
	disable = false,
	executeRecaptchaAsync,
	reloadCaptcha,
	setChangePasswordRequired,
	setChangePasswordEnforcement,
}: TwoFAFormProps) => {
	const { formatMessage } = useIntl();
	const { isMobileClient } = useContext(Context).state;
	const { title } = useClientConfig();
	const [formError, setFormError] = useState<any>();
	const [isProceedToAppButtonVisible, setIsProceedToAppButtonVisible] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState('');
	const errorMessage = useAuthErrorMessage(formError);
	const [twoFaCode, setTwoFaCode] = useState('');
	const [twoFaCodeFieldError, setTwoFaCodeFieldError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isResubmitRequired, setIsResubmitRequired] = useState(false);
	const axios = useAxios();
	const intervalRef = useRef<number>();
	const [retryCount] = useState(DEFAULT_ALLOWED_RETRY_COUNT);
	const [retryCountWithExpiredTime] = useState(DEFAULT_ALLOWED_RETRY_COUNT_WITH_EXPIRED_TIME);
	const history = useHistory();
	const [attempt, beginAttempt, fail, expire, endAttempt] = useInputAttempts();

	const [initialTwoFaType] = useState(submitValues.twoFaType);

	const [formState, setFormState] = useState<LoginSubmitValues>({
		username: submitValues.username,
		password: submitValues.password,
		twoFaExpirationDate: submitValues.twoFaExpirationDate,
		txId: submitValues.txId,
		twoFaToken: submitValues.twoFaToken,
		twoFaType: submitValues.twoFaType,
		twoFaCode: submitValues.twoFaCode,
		apple_id_oauth_code: submitValues.apple_id_oauth_code,
		apple_id_state: submitValues.apple_id_state,
		provider: submitValues.provider,
		token: submitValues.token,
		email: submitValues.email,
		phoneNumber: submitValues.phoneNumber,
		call_enabled: submitValues.call_enabled,
	});

	const isPushBasedAuthenticationType = formState.twoFaType === TwoFAType.PUSH_ME;

	const { isExpired, formattedTimeLeft, timeLeft } = useCountdown(formState.twoFaExpirationDate);
	const isExpiredRef = useRef(isExpired);
	const timeLeftRef = useRef(timeLeft);
	isExpiredRef.current = isExpired;
	timeLeftRef.current = timeLeft;

	const handleValidation = () => {
		let isValid = true;
		setTwoFaCodeFieldError('');

		if (validation.isEmpty(twoFaCode)) {
			setTwoFaCodeFieldError(formatMessage(messages.requiredField));
			isValid = false;
			fail();
		} else if (validation.isNotValid2FACodeFormat(twoFaCode)) {
			setTwoFaCodeFieldError(formatMessage(messages.invalidKey));
			isValid = false;
			fail();
		}
		return isValid;
	};

	const checkRetryCount = () => {
		if (attempt > retryCount) {
			setIsResubmitRequired(true);
		} else if (attempt === retryCountWithExpiredTime && isExpired) {
			setIsResubmitRequired(true);
		}
	};

	const handleRecaptcha = useEnforcedRecaptcha(executeRecaptchaAsync);

	const handleLoginFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
		/* Used for 2FA with code to submit the code */
		event.preventDefault();
		if (!handleValidation()) return;

		setIsLoading(true);
		handleRecaptcha((recaptcha) =>
			axios.post(
				endpoints.login(),
				queryString.stringify({
					txId: formState.txId,
					twoFaCode: twoFaCode || formState.twoFaCode,
				}),
				{
					headers: {
						recaptcha,
						'Content-Type': 'application/x-www-form-urlencoded',
						withCredentials: true,
					},
				}
			)
		)
			.then(async (response) => {
				setFormError(null);
				setIsLoading(false);

				const responseUrl = response.request.responseURL;
				if (isMobileClient) {
					setRedirectUrl(responseUrl);
					setIsProceedToAppButtonVisible(true);
					return;
				}
				window.location.href = responseUrl;
			})
			.catch((response: Response<Error>) => {
				if (response.status === 400 && response.data.errorCode === ErrorMessageCodes.A_74) {
					setChangePasswordEnforcement &&
						setChangePasswordEnforcement(PasswordChangeEnforcement.ENFORCE);
					setChangePasswordRequired && setChangePasswordRequired(true);
					setChangePasswordRequired && reloadCaptcha();
				} else if (
					response.status === 400 &&
					response.data.errorCode === ErrorMessageCodes.A_73
				) {
					setChangePasswordEnforcement &&
						setChangePasswordEnforcement(PasswordChangeEnforcement.SUGGEST);
					setChangePasswordRequired && setChangePasswordRequired(true);
					setChangePasswordRequired && reloadCaptcha();
				} else if (response?.data?.error === '2fa_incorrect') {
					setFormError(response);
					checkRetryCount();
					fail();
				} else {
					history.push(`${config.BASE_URL_LOGIN}/session-expired`);
				}
				setIsLoading(false);
			});
	};

	const reinitiateTwoFa = async (body: Record<string, any> = {}) => {
		const getRequestBody = () => {
			if (
				formState.provider === ProviderType.FACEBOOK ||
				formState.provider === ProviderType.GOOGLE
			) {
				return {
					password: '',
					provider: formState.provider,
					token: formState.token,
					username: '',
				};
			}

			if (formState.provider === ProviderType.APPLE_ID) {
				return {
					apple_id_oauth_code: formState.apple_id_oauth_code,
					apple_id_state: formState.apple_id_state,
					provider: formState.provider,
					password: '',
					token: formState.token,
					username: '',
				};
			}

			return {
				username: formState.username,
				password: formState.password,
			};
		};
		return handleRecaptcha((recaptcha) =>
			axios.post(endpoints.login(), queryString.stringify({ ...body, ...getRequestBody() }), {
				headers: {
					recaptcha,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
		);
	};

	const onTwoFaInitResolve = (response: AxiosResponse<unknown>) => {
		setFormError(null);
		setIsLoading(false);

		const responseUrl = response.request.responseURL;
		if (isMobileClient) {
			setRedirectUrl(responseUrl);
			setIsProceedToAppButtonVisible(true);
			return;
		}
		window.location.href = responseUrl;
	};

	const onTwoFaInitReject = (response: Response<Error>) => {
		const { data } = response;

		if (['SOPT_6', 'A_67'].includes(data?.errorCode!)) {
			setFormError(response);
			setIsLoading(false);
			return;
		}
		setFormState((prevState: any) => ({
			...prevState,
			txId: data.tx_id!,
			twoFaToken: data['2fa_token'],
			twoFaType: data['2fa_type'],
			twoFaCode: data['2fa_code'],
			twoFaExpirationDate: data.expiration_date!,
			phoneNumber: data.phone_number,
			email: data.email,
			call_enabled: /true/.test(data.call_enabled || ''),
		}));
		setIsLoading(false);
		// eslint-disable-next-line @typescript-eslint/no-throw-literal
		throw data;
	};

	const handleRetryButtonClick = async () => {
		/* Used for 2FA with code to get new code */
		setTwoFaCodeFieldError('');
		setFormError(null);
		setIsLoading(true);
		reinitiateTwoFa().then(onTwoFaInitResolve).catch(onTwoFaInitReject).catch(beginAttempt);
	};

	const onRequestFallback = async (retry: boolean, type: 'sms' | 'call') => {
		setTwoFaCodeFieldError('');
		setFormError(null);
		setIsLoading(true);

		const txId$ = retry
			? reinitiateTwoFa().catch((response: Response<Error>) => {
					const txId = response.data?.tx_id;
					// eslint-disable-next-line @typescript-eslint/no-throw-literal
					if (!txId) throw response;
					return { txId, type: response.data['2fa_type'] };
			  })
			: Promise.resolve({ txId: formState.txId, type: formState.twoFaType });

		void txId$.then(({ txId, type: currentType }) =>
			reinitiateTwoFa({
				txId,
				twoFaFallback: currentType === TwoFAType.PUSH_ME,
				phone_notification_type: type,
			})
				.then(onTwoFaInitResolve)
				.catch(onTwoFaInitReject)
				.catch(() => {
					if (retry) beginAttempt();
				})
		);
	};

	const handleTwoFAPolling = () => {
		/* Used for 2FA PUSH */
		if (isExpiredRef.current || timeLeftRef.current < MIN_TIME_TILL_EXPIRATION_MS) return;
		axios
			.post(
				endpoints.login(),
				queryString.stringify({
					txId: formState.txId,
					twoFaCode: twoFaCode || formState.twoFaCode,
				})
			)
			.then((response) => {
				clearInterval(intervalRef.current);
				const responseUrl = response.request.responseURL;
				if (isMobileClient) {
					setRedirectUrl(responseUrl);
					setIsProceedToAppButtonVisible(true);
				} else {
					window.location.href = responseUrl;
				}
			})
			.catch((error: Response<Error>) => {
				const { status, data } = error || {};
				if (data && status) {
					if (status === 400 && data.errorCode === ErrorMessageCodes.A_74) {
						setChangePasswordEnforcement &&
							setChangePasswordEnforcement(PasswordChangeEnforcement.ENFORCE);
						setChangePasswordRequired && setChangePasswordRequired(true);
						setChangePasswordRequired && reloadCaptcha();
					}

					if (status === 400 && data.errorCode === ErrorMessageCodes.A_73) {
						setChangePasswordEnforcement &&
							setChangePasswordEnforcement(PasswordChangeEnforcement.SUGGEST);
						setChangePasswordRequired && setChangePasswordRequired(true);
						setChangePasswordRequired && reloadCaptcha();
					}

					if (data.error === '2fa_required') {
						return;
					}

					if (status === 401 && data?.errorCode === 'A_4') {
						handleTwoFaCancel(SearchParamError.loginCancelled);
						return;
					}
					if (status === 400 && data?.reason === '2fa_rejected') {
						onCancel(SearchParamError.authenticationCancelled);
						return;
					}
					if (data.error === '2fa_incorrect') {
						handleTwoFaCancel();
						return;
					}
					if (
						!isExpiredRef.current &&
						status !== 200 &&
						!KEEP_POLLING_ERROR_KEYS.includes(data.error!)
					) {
						setFormError(error);
						clearInterval(intervalRef.current);
					}
				}
			});
	};

	const handleTwoFaCancel = (
		errorCode: SearchParamError = SearchParamError.authenticationCancelled
	) => {
		axios
			.post(endpoints.cancelTwoFa(), queryString.stringify({ txId: formState.txId }))
			.then(() => {
				clearInterval(intervalRef.current);
				onCancel(errorCode);
			})
			.catch((error) => {
				setFormError(error);
			});
	};

	useEffect(() => {
		if (isExpired || timeLeft < MIN_TIME_TILL_EXPIRATION_MS) {
			clearInterval(intervalRef.current);
		}
	}, [isExpired, timeLeft]);

	useEffect(() => {
		if (isExpired) {
			expire();
			endAttempt();
		}
	}, [isExpired, expire, endAttempt]);

	useEffect(() => {
		if (isPushBasedAuthenticationType) {
			const id = window.setInterval(() => {
				handleTwoFAPolling();
			}, POLLING_INTERVAL_MS);

			intervalRef.current = id;
		}

		return () => {
			clearInterval(intervalRef.current);
		};
	}, [formState.txId]);

	useEffect(() => {
		checkRetryCount();
	}, [isExpired, attempt]);

	useEffect(() => {
		reloadCaptcha();
	}, [isExpired]);

	useEffect(() => {
		beginAttempt();
	}, []);

	const getTitle = () => {
		switch (formState.twoFaType) {
			case TwoFAType.EMAIL:
				return messages.emailAuthentication;
			case TwoFAType.SMS:
				return messages.smsAuthentication;
			case TwoFAType.GOOGLE:
				return messages.googleAuthentication;
			case TwoFAType.PUSH_ME:
				return messages.clientAuthentication;
			case TwoFAType.TELEGRAM:
				return messages.telegramAuthentication;
			default:
				return messages.title;
		}
	};

	const getInstructions = () => {
		switch (formState.twoFaType) {
			case TwoFAType.EMAIL:
				return formatMessage(messages.enterEmailCode, { email: formState.email });
			case TwoFAType.SMS:
				return formatMessage(messages.enterPhoneCode, { phone: formState.phoneNumber });
			case TwoFAType.GOOGLE:
				return formatMessage(messages.enterGoogleCode);
			case TwoFAType.PUSH_ME:
				return formatMessage(messages.clientAppConfirm, { client: title });
			case TwoFAType.TELEGRAM:
				return formatMessage(messages.enterTelegramCode);
			default:
				return formatMessage(messages.enterAuthKey);
		}
	};

	if (isResubmitRequired) {
		return <TwoFAAttemptsUsed backLink={config.BASE_URL_LOGIN} />;
	}

	return (
		<UserAccessContainer title={getTitle()} hasImageColumn={false}>
			<div className={styles.container}>
				{(errorMessage || twoFaCodeFieldError) && (
					<FormMessage className={styles.message} type={FormMessageType.ERROR}>
						{errorMessage || twoFaCodeFieldError}
					</FormMessage>
				)}
				<p className={styles.instructions}>{getInstructions()}</p>
				<form className={styles.form} onSubmit={handleLoginFormSubmit} noValidate>
					<div>
						<div className={styles.label}>
							<FormattedMessage {...messages.transactionId} />
						</div>
						<div className={styles.transactionId}>{formState.txId}</div>
					</div>
					{!isPushBasedAuthenticationType ? (
						<>
							{isProceedToAppButtonVisible && (
								<Button
									buttonStyle={ButtonStyle.PRIMARY}
									className={styles.button}
									link={redirectUrl}
									text={formatMessage(messages.proceedToApp)}
									disabled={disable}
									type={ButtonType.ANCHOR_LINK}
								/>
							)}
							{!isProceedToAppButtonVisible && (
								<>
									<div>
										<div className={styles.label}>
											<FormattedMessage {...messages.key} />
										</div>
										<CountdownInput
											className={styles.input}
											isCountdownHidden={false}
											isExpired={isExpired}
											pattern="\d*"
											formattedTimeLeft={formattedTimeLeft}
											type="text"
											inputMode="numeric"
											value={twoFaCode}
											onChange={(event: ChangeEvent<HTMLInputElement>) =>
												setTwoFaCode(event.target.value?.trim())
											}
											handleRetryButtonClick={() => {
												setTwoFaCode('');
												setFormError(null);
												void handleRetryButtonClick();
											}}
											isLoading={isLoading}
										/>
									</div>
									{isExpired &&
										formState.twoFaType === TwoFAType.SMS &&
										formState.call_enabled && (
											<Button
												disabled={isLoading || disable}
												buttonStyle={ButtonStyle.LINK}
												className={styles.button}
												onClick={() =>
													onRequestFallback(
														initialTwoFaType === TwoFAType.PUSH_ME,
														'call'
													)
												}
												text={formatMessage(messages.requestCall)}
												type={ButtonType.BUTTON}
											/>
										)}
									<Button
										withCaptcha
										buttonStyle={ButtonStyle.PRIMARY}
										className={styles.button}
										disabled={!twoFaCode || isLoading || isExpired || disable}
										isLoading={isLoading}
										text={formatMessage(messages.submit)}
										type={ButtonType.SUBMIT}
									/>
								</>
							)}
						</>
					) : (
						<>
							{isProceedToAppButtonVisible && (
								<Button
									buttonStyle={ButtonStyle.PRIMARY}
									className={styles.button}
									link={redirectUrl}
									text={formatMessage(messages.proceedToApp)}
									disabled={disable}
									type={ButtonType.ANCHOR_LINK}
								/>
							)}
							{!isProceedToAppButtonVisible && (
								<div>
									{isExpired ? (
										<>
											<Button
												withCaptcha
												disabled={isLoading || disable}
												isLoading={isLoading}
												buttonStyle={ButtonStyle.PRIMARY}
												className={styles.button}
												onClick={handleRetryButtonClick}
												text={formatMessage(messages.retry)}
												type={ButtonType.BUTTON}
											/>
											<Button
												disabled={isLoading || disable}
												buttonStyle={ButtonStyle.LINK}
												className={styles.button}
												onClick={() => onRequestFallback(true, 'sms')}
												text={formatMessage(messages.requestSMS)}
												type={ButtonType.BUTTON}
											/>
											<Button
												disabled={isLoading || disable}
												buttonStyle={ButtonStyle.LINK}
												className={styles.button}
												onClick={() =>
													onCancel(SearchParamError.loginCancelled)
												}
												text={formatMessage(messages.cancel)}
												type={ButtonType.BUTTON}
											/>
										</>
									) : (
										<>
											<div className={styles.timeLeft}>
												{formattedTimeLeft}
											</div>
											<Button
												withCaptcha
												disabled={isLoading || disable}
												buttonStyle={ButtonStyle.LINK}
												className={styles.button}
												onClick={() => onRequestFallback(false, 'sms')}
												text={formatMessage(messages.requestSMS)}
												type={ButtonType.BUTTON}
											/>
											<Button
												disabled={isLoading || disable}
												buttonStyle={ButtonStyle.LINK}
												className={styles.button}
												onClick={() => handleTwoFaCancel()}
												text={formatMessage(messages.cancel)}
												type={ButtonType.BUTTON}
											/>
										</>
									)}
								</div>
							)}
						</>
					)}
				</form>
			</div>
		</UserAccessContainer>
	);
};

export default LoginTwoFA;
