import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import styles from './MobileAppSuggestion.module.scss';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import { Clients } from '../../enums/Clients';
import config from '../../config';
import useOnClickOutside from '../../hooks/useClickOutside';
import useSwipe from '../../hooks/useSwipe';
import { useClientConfig } from '../../helpers/themeHelpers';
import { Context } from '../../store/Provider';

const messages = defineMessages({
	title: {
		id: 'appSuggest.seeMore',
		defaultMessage: 'See more on',
	},
	titleWithDots: {
		id: 'appSuggest.seeMoreWithDots',
		defaultMessage: 'See more on...',
	},
	app: {
		id: 'appSuggest.app',
		defaultMessage: 'App',
	},
	browser: {
		id: 'appSuggest.browser',
		defaultMessage: 'Browser',
	},
	open: {
		id: 'appSuggest.open',
		defaultMessage: 'Open',
	},
	continue: {
		id: 'appSuggest.continue',
		defaultMessage: 'Continue',
	},
});

const MobileAppSuggestion: FC = () => {
	const { formatMessage } = useIntl();
	const { isMobileClient } = useContext(Context).state;

	const [isVisible, setVisible] = useState(false);
	const [appIcon, setAppIcon] = useState<any>(null);
	const [browserIcon, setBrowserIcon] = useState<any>(null);

	const containerRef = useRef<HTMLDivElement | null>(null);
	useOnClickOutside(containerRef, () => setVisible(false));

	const { title, mobileSugestEnabled } = useClientConfig();

	useEffect(() => {
		if (isMobile && (isAndroid || isIOS)) {
			setVisible(true);
		}
	}, []);

	const loadIcons = async () => {
		switch (config.CLIENT_NAME) {
			case Clients.BANKERA:
				return {
					appIcon: await import('../../images/bankera/bankera_head_logo.svg').then(
						(img) => setAppIcon(img.default)
					),
					browserIcon: await import('../../images/bankera/globe.svg').then((img) =>
						setBrowserIcon(img.default)
					),
				};
			case Clients.SPECTROCOIN:
				return {
					appIcon: await import('../../images/spectrocoin/spectro_head.svg').then((img) =>
						setAppIcon(img.default)
					),
					browserIcon: await import('../../images/spectrocoin/spectro_globe.svg').then(
						(img) => setBrowserIcon(img.default)
					),
				};
			default:
				return {
					appIcon: null,
					browserIcon: null,
				};
		}
	};

	const getTitle = () => {
		switch (config.CLIENT_NAME) {
			case Clients.SPECTROCOIN:
				return formatMessage(messages.title);
			case Clients.BANKERA:
				return formatMessage(messages.titleWithDots);
			default:
				return formatMessage(messages.title);
		}
	};

	const onSwipeDown = () => {
		setVisible(false);
	};

	useSwipe(containerRef.current, { onSwipeDown });

	useEffect(() => {
		void loadIcons();
	}, []);

	if (!config.MOBILE_SUGGEST_ENABLED || !mobileSugestEnabled || !isVisible || isMobileClient)
		// isMobileClient is set only if user is coming from mobile device and ?client=mobile qp is set
		// so we don't want to show this component in that case
		return null;
	return (
		<div className={styles.container}>
			<div className={styles.content} ref={containerRef}>
				{config.CLIENT_NAME === Clients.SPECTROCOIN && (
					<div className={styles.close} onClick={() => setVisible(false)} />
				)}
				<div className={classNames(styles.title, styles[config.CLIENT_NAME])}>
					{getTitle()}
				</div>
				<div className={classNames(styles.row, styles[config.CLIENT_NAME])}>
					<div className={styles.icon}>
						{appIcon && <img src={appIcon} alt="app icon" />}
					</div>
					<div className={classNames(styles.name, styles[config.CLIENT_NAME])}>
						{title} {formatMessage(messages.app)}
					</div>
					<Button
						className={classNames(styles.button, styles[config.CLIENT_NAME])}
						buttonStyle={ButtonStyle.PRIMARY}
						type={ButtonType.BUTTON}
						onClick={() =>
							window.open(`${config.CLIENT_LOGIN_URL}?appSuggest=true`, '_blank')
						}
					>
						{formatMessage(messages.open)}
					</Button>
				</div>
				<div className={classNames(styles.row, styles[config.CLIENT_NAME])}>
					<div className={styles.icon}>
						{browserIcon && <img src={browserIcon} alt="browser icon" />}
					</div>
					<div className={classNames(styles.name, styles[config.CLIENT_NAME])}>
						{formatMessage(messages.browser)}
					</div>
					<Button
						className={classNames(styles.button, styles[config.CLIENT_NAME])}
						buttonStyle={
							config.CLIENT_NAME === Clients.SPECTROCOIN
								? ButtonStyle.SECONDARY_FILLED
								: ButtonStyle.SECONDARY
						}
						type={ButtonType.BUTTON}
						onClick={() => setVisible(false)}
					>
						{formatMessage(messages.continue)}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default MobileAppSuggestion;
